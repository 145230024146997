// import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Redirect, withRouter } from 'react-router-dom';
import AppLayout from '../layouts';
import { useSelector } from 'react-redux';
import { getCookie } from '../utils/cookie';
import { Switch, Route } from 'react-router-dom';
import NotFoundPage from '../routes/error/NotFoundPage';
import { routes } from '../routes';
import { Roles } from '../utils/constant';
import config from '../config';

const AppRoutes = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [route, setRoute] = useState([]);
  const authUser = useSelector((state) => state.auth.authUser);
  let accessToken = getCookie('token');

  useEffect(() => {
    function fetchRouteData() {
      try {
        setLoading(true);
        if (authUser?.roles === 'super' || authUser?.roles === 'admin') {
          setRoute(routes);
        } else {
          const filteredMenu = routes.filter((menuItem) => {
            return (
              authUser?.roles === menuItem.permission ||
              menuItem.permission === Roles.public
            );
          });

          setRoute(filteredMenu);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching menu data:', error);
        setLoading(false);
      }
    }
    fetchRouteData();
  }, [authUser]);

  const updateLocation = async () => {
    try {
      const position = await getCurrentPosition();
      // Gửi vị trí lên server (ví dụ: sử dụng fetch)
      if (position && authUser.driver && authUser.roles === Roles.driver) {
        const response = await fetch(
          `${config.API_URL}/location/driver/update`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              driver_id: authUser.driver.id,
              lat: position.coords.latitude,
              long: position.coords.longitude,
            }),
          }
        );

        if (response.ok) {
          console.log('Location updated successfully.');
        } else {
          console.error('Failed to update location.');
        }
      }
    } catch (error) {
      console.error('Error getting location:', error);
    }
  };

  useEffect(() => {
    updateLocation();
    const intervalId = setInterval(() => {
      updateLocation();
    }, 300000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error),
        { enableHighAccuracy: true }
      );
    });
  };

  if (!authUser || !accessToken) {
    return <Redirect to="/signup" />;
  }

  return (
    <React.Fragment>
      <div className="container">
        <Helmet>{/* <title>{title}</title> */}</Helmet>
        <AppLayout>
          {!loading && (
            <Switch>
              {route.map((route, index) => (
                <ProtectedRoute
                  key={index}
                  exact
                  authUser={authUser}
                  permission={route.permission}
                  path={`${match.url}/${route.path}`}
                  component={route.component}
                />
              ))}
              <Route component={NotFoundPage} />
            </Switch>
          )}
        </AppLayout>
      </div>
    </React.Fragment>
  );
};

const ProtectedRoute = ({
  authUser,
  permission,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    render={(props) => {
      if (authUser) {
        return <Component {...props} />;
      } else {
        return <Redirect to="/login" />;
      }
    }}
  />
);

export default withRouter(AppRoutes);
