import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Pagination,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import reportApi from '../../../api/report';
import billApi from '../../../api/bill';
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/vi_VN';
import Currency from '../../../components/Currency';
import { fee } from '../../../utils/constant';

const { Text } = Typography;

const DriverReport = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [billLoading, setBillLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [filter, setFilter] = useState({
    date: dayjs()?.toISOString(),
  });
  const [driver, setDriver] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [Bills, setBills] = useState([]);
  const [billTotalAmount, setBillTotalAmount] = useState(0);
  const [totalBill, setTotalBill] = useState(0);
  const [filterBill, setFilterBill] = useState({
    page: 1,
    limit: 5,
    keyword: '',
    order_by: 'id',
    order_dir: 'DESC',
  });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await reportApi.getDriver(filter);
        const billItems = await billApi.getAll(filterBill);
        if (res && Array.isArray(res?.driver)) {
          setDriver(res.driver[0]);
        }
        if (res && Array.isArray(res?.admin)) {
          setAdmin(res.admin[0]);
        }
        if (billItems && Array.isArray(billItems?.data)) {
          setBills(billItems?.data);
          setBillTotalAmount(billItems.total_amount);
          setTotalBill(billItems.total);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }

    fetchData();
  }, [filter, reload]);

  useEffect(() => {
    async function fetchData() {
      setBillLoading(true);
      await billApi
        .getAll(filterBill)
        .then((res) => {
          if (res && Array.isArray(res.data)) {
            setBills(res?.data);
            setBillTotalAmount(res.total_amount);
            setTotalBill(res.total);
          }
          setBillLoading(false);
        })
        .catch((error) => {
          setBillLoading(false);
          console.error(error);
        });
    }

    fetchData();
  }, [filterBill, reload]);

  const onFilter = () => {
    form.validateFields().then((value) => {
      const data = {
        ...filter,
        date: value?.date_from?.add(1, 'day')?.toISOString(),
      };
      setFilter(data);
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleChangePage = (page) => {
    setFilterBill((filter) => ({
      ...filter,
      page,
    }));
  };

  return (
    <Space direction="vertical" size={4} style={{ width: '100%' }}>
      <Row justify={'end'}>
        <Col>
          <Button type="primary" onClick={onReload}>
            Làm mới
          </Button>
        </Col>
      </Row>
      <Form form={form} onFinish={onFilter}>
        <Row gutter={8} justify={'start'}>
          <Col>
            <Form.Item name="date_from" initialValue={dayjs()}>
              <DatePicker
                disabledDate={disabledDate}
                locale={locale}
                picker="month"
              />
            </Form.Item>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary">
              <SearchOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
      {loading ? (
        <Spin style={{ width: '100%' }} />
      ) : (
        (driver || admin) && (
          <>
            {driver && (
              <>
                <Card>
                  <Space direction="vertical">
                    <Text style={{ fontSize: '20px', color: '#3e4095' }} strong>
                      Thống kê tháng {driver.month}:
                    </Text>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Text strong>Số cuốc:</Text>
                      </Col>
                      <Col span={12}>
                        <Text strong>{driver.count}</Text>
                      </Col>
                      <Col span={12}>
                        <Text strong>Tổng tiền thu:</Text>
                      </Col>
                      <Col span={12}>
                        <Currency
                          style={{ color: 'green' }}
                          value={driver.total}
                        />
                      </Col>
                      <Col span={12}>
                        <Text strong>Tiền trả về:</Text>
                      </Col>
                      <Col span={12}>
                        <Currency
                          style={{ color: 'red' }}
                          value={-(driver.total * fee.percent)}
                        />
                      </Col>
                      <Col span={12}>
                        <Text strong>Số tiền quỹ 10k:</Text>
                      </Col>
                      <Col span={12}>
                        <Currency
                          style={{ color: 'red' }}
                          value={-driver.total_extra}
                        />
                      </Col>
                    </Row>
                  </Space>
                </Card>
              </>
            )}
            {/* {admin && (
              <Card>
                <Space direction="vertical">
                  <Text style={{ fontSize: '20px', color: '#3e4095' }} strong>
                    Quỹ 10k D-Car tháng {admin.month}:
                  </Text>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Text strong>Quỹ 10k:</Text>
                    </Col>
                    <Col span={12}>
                      <Currency value={admin.count * fee.extra} />
                    </Col>
                    {admin.month !== '2024-01' && (
                      <>
                        <Col span={12}>
                          <Text strong>Phí nền tảng:</Text>
                        </Col>
                        <Col span={12}>
                          <Currency
                            style={{ color: 'red' }}
                            value={-(admin.count * fee.Platform)}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </Space>
              </Card>
            )} */}
          </>
        )
      )}
      {Bills?.length > 0 && (
        <Card>
          <Space direction="vertical">
            <Row>
              <Text style={{ fontSize: '20px', color: '#3e4095' }} strong>
                Quỹ 10k D-Car hiện tại:{' '}
                <Currency
                  style={
                    billTotalAmount > 0
                      ? { fontSize: '20px', color: 'green' }
                      : { fontSize: '20px', color: 'red' }
                  }
                  value={billTotalAmount}
                />
              </Text>
            </Row>
            <Row>
              <Text style={{ color: 'red' }}>
                Không bao gồm tháng {dayjs().format('YYYY-MM')}
              </Text>
            </Row>
            {billLoading ? (
              <Spin style={{ width: '100%' }} />
            ) : (
              <Row gutter={[16, 16]}>
                {Bills?.map((item, index) => {
                  return (
                    <Col key={index} span={24}>
                      <Card style={{ width: '100%' }}>
                        <Row gutter={[8, 8]}>
                          <Col span={8}>
                            <Text>Số tiền:</Text>
                          </Col>
                          <Col span={16}>
                            <Text strong>
                              {item?.transaction_type ? (
                                <Currency
                                  style={{ color: 'green' }}
                                  value={item?.amount}
                                />
                              ) : (
                                <Currency
                                  style={{ color: 'red' }}
                                  value={item?.amount}
                                />
                              )}
                            </Text>
                          </Col>
                          <Col span={8}>
                            <Text>Note:</Text>
                          </Col>
                          <Col span={16}>
                            <Text strong>{item?.note}</Text>
                          </Col>
                          <Col span={8}>
                            <Text>Ngày:</Text>
                          </Col>
                          <Col span={16}>
                            <Text strong>
                              {dayjs(item?.created_at).format(
                                'HH:mm DD-MM-YYYY'
                              )}
                            </Text>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )}
            <Row justify={'center'}>
              <Pagination
                style={{ display: 'flex', justifyContent: 'center' }}
                current={filterBill.page}
                pageSize={filterBill.limit}
                onChange={handleChangePage}
                total={totalBill}
              />
            </Row>
          </Space>
        </Card>
      )}
      {!(driver || admin || Bills.length > 0) && (
        <Empty description="Không có dữ liệu" />
      )}
    </Space>
  );
};

export default DriverReport;
