import { Button, Card, Col, Flex, Image, Row, Typography } from 'antd';
import React from 'react';
import PageTitle from '../../components/PageTitle';
import IntlMessage from '../../components/IntlMessage';
import { useHistory } from 'react-router-dom';

const QRTopup = () => {
  const history = useHistory();

  const handleOk = () => {
    history.goBack();
  };

  return (
    <Row gutter={[15, 15]}>
      <Col span={24}>
        <PageTitle title={<IntlMessage id="qr.title" />} />
      </Col>
      <Col span={24}>
        <Card bordered>
          <Flex style={{ width: '100%' }} justify="center">
            <Image
              style={{
                minWidth: '100px',
                padding: '15px',
                border: '1px #c6c2c2 solid',
                borderRadius: '5px',
              }}
              src={require('../../assets/img/qr-Dcar.jpg')}
              alt="mã qr"
              preview={false}
            />
          </Flex>
          <Flex vertical justify="center" align="center">
            <Typography.Text style={{ fontSize: '20px' }} strong>
              Ngân hàng quân đội (MB Bank)
            </Typography.Text>
            <Typography.Text style={{ fontSize: '20px' }} strong>
              Lê Đình Chiến
            </Typography.Text>
            <Typography.Text style={{ fontSize: '20px' }} strong>
              0977028110
            </Typography.Text>
          </Flex>
        </Card>
      </Col>
      <Col span={24}>
        <Button onClick={handleOk} style={{ width: '100%' }} type="primary">
          <IntlMessage id="global.ok" />
        </Button>
      </Col>
    </Row>
  );
};

export default QRTopup;
