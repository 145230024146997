import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import React, { memo, useEffect, useState } from 'react';
import {
  OrderStatus,
  OrderType,
  eventTime,
  surchargePrice,
} from '../../utils/constant';
import IntlMessage from '../../components/IntlMessage';
import dayjs from 'dayjs';
import orderApi from '../../api/order';
import customersApi from '../../api/customers';
import { convertToAnyTimezone, formatAsISOString } from '../../helpers/time';
import Bill from './Bill';
import flashingImage from '../../assets/img/flashing_color.gif';

const { Text } = Typography;

const MenuAction = (props) => {
  const {
    currentData,
    onOpenNightModal,
    onOpenPoints,
    onReload,
    handleUpdateOrderStatus,
  } = props;
  const [form] = Form.useForm();
  const [waitTime, setWaitTime] = useState(0);
  const [waiting, setWaiting] = useState(currentData?.waiting);
  const [timerIdWait, setTimerIdWait] = useState(null);
  const [timerIdNight, setTimerIdNight] = useState(null);
  const [reload, setReload] = useState(false);
  const [openBill, setOpenBill] = useState(false);
  const [disableNight, setDisableNight] = useState(
    Number(currentData?.distance) < 55
      ? !(
          dayjs().isAfter(
            dayjs()
              .hour(22)
              .minute(15)
              .second(0)
          ) || dayjs().hour() < 6
        )
      : dayjs().isBefore(dayjs().hour(20))
  );
  const [isCarNumber, setIsCarNumber] = useState(false);
  const [isSaveCarNumber, setIsSaveCarNumber] = useState(false);

  useEffect(() => {
    if (
      !(
        currentData?.customer?.car_number.includes('car_number') &&
        JSON.parse(currentData?.customer?.car_number).length > 0
      )
    ) {
      setIsCarNumber(true);
    }
  }, []);

  useEffect(() => {
    let total = parseInt(currentData?.waiting_duration) || 0;
    if (waiting) {
      setWaitTime(
        dayjs().diff(dayjs(currentData?.waiting_start), 'seconds') + total
      );
    } else {
      setWaitTime(total);
    }
  }, [reload, currentData?.waiting_start, currentData?.waiting_duration]);

  useEffect(() => {
    if (waiting) {
      onWaitTime();
    }
    return () => {
      clearInterval(timerIdWait);
    };
  }, [waiting]);

  useEffect(() => {
    const load = (event) => {
      if (document.visibilityState !== 'hidden') {
        onReload();
        setReload((e) => !e);
      }
    };
    window.addEventListener('visibilitychange', load);
    onIsNight();
    return () => {
      window.removeEventListener('visibilitychange', load);
      clearInterval(timerIdNight);
    };
  }, []);

  const onMessageSuccess = () => {
    message.success({
      content: 'Thành công!!',
    });
  };

  const onIsNight = () => {
    const timer = setInterval(() => {
      const targetTime = dayjs()
        .hour(22)
        .minute(15)
        .second(0);

      setDisableNight(
        Number(currentData?.distance) < 55
          ? !(dayjs().isAfter(targetTime) || dayjs().hour() < 6) ||
              waiting ||
              Number(currentData?.night_surcharge) > 0 ||
              Number(currentData?.night_surcharge) <
                Number(currentData?.waiting_surcharge) ||
              Number(currentData?.extra) > surchargePrice.night.suburb ||
              (convertToAnyTimezone(currentData?.date_picked).isBefore(
                targetTime
              ) &&
                dayjs().hour() > 6)
          : dayjs().isBefore(
              dayjs()
                .hour(20)
                .minute(0)
                .second(0)
            ) ||
              waiting ||
              Number(currentData?.night_surcharge) > 0 ||
              Number(currentData?.night_surcharge) <
                Number(currentData?.waiting_surcharge) ||
              Number(currentData?.extra) > surchargePrice.night.suburb ||
              convertToAnyTimezone(currentData?.date_picked).isBefore(
                dayjs()
                  .hour(20)
                  .minute(0)
                  .second(0)
              )
      );
    }, 1000);
    setTimerIdNight(timer);
  };

  const onWaitTime = () => {
    const timer = setInterval(() => {
      setWaitTime((time) => {
        return time + 1;
      });
    }, 1000);
    setTimerIdWait(timer);
  };

  const handleWaiting = async () => {
    if (waiting) {
      const payload = {
        waiting: false,
        dateTime: dayjs().toISOString(),
        waiting_duration: waitTime,
      };
      await orderApi
        .waitingSurcharge(currentData?.id, payload)
        .then(async (res) => {
          setWaiting(false);
          onMessageSuccess();
          clearInterval(timerIdWait);
          onReload();
        });
    } else {
      const payload = {
        waiting: true,
        dateTime: dayjs().toISOString(),
      };
      await orderApi.waitingSurcharge(currentData?.id, payload);
      setWaiting(true);
    }
  };

  const handlePicked = async () => {
    if (waiting) {
      handleWaiting();
    }
    handleUpdateOrderStatus(OrderStatus.picked.status);
  };

  const handleNightSurcharge = () => {
    if (!disableNight) {
      onOpenNightModal();
    } else {
      Modal.warning({
        title: (
          <Space direction="vertical" size={4}>
            <Text strong>Chỉ bấm sau 22h15p!!</Text>
            <Text strong>Tính tại thời điểm đón khách !!</Text>
          </Space>
        ),
      });
    }
  };

  const handleOvernight = async () => {
    const data = {
      amount: surchargePrice.block.night,
    };
    Modal.info({
      title: (
        <Space direction="vertical" wrap>
          <Text>- Phụ phí hậu cần: 300k (ngày thường), 500k (ngày lễ)</Text>
          <Text>- Lái xe tự thu với khách (nếu khách ko hỗ trợ)</Text>
          <Text>
            - Trung tâm sẽ ko tính phí hậu cần vào tổng thu chỉ tính phí phát
            sinh
          </Text>
        </Space>
      ),
    });
    // await orderApi.nightSurcharge(currentData?.id, data).then(() => {
    //   onMessageSuccess();
    //   onReload();
    // });
  };

  const handleLunarNewYear = async () => {
    const data = {
      amount: surchargePrice.lunar_new_year,
    };
    await orderApi
      .eventSurcharge(currentData?.id, data)
      .then(async (values) => {
        onMessageSuccess();
        onReload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFailed = (error) => {
    console.error(error);
  };

  const onFinish = () => {
    Modal.confirm({
      content: 'Bạn có chắc chắn muốn hoàn thành?',
      onOk: () => {
        form
          .validateFields()
          .then(async (value) => {
            const car_number = form.getFieldValue('car_number');
            if (isCarNumber && car_number) {
              const data = {
                car_number,
              };
              await customersApi.updateCarNumber(
                currentData?.customer?.id,
                data
              );
            }
            const payload = {
              date: formatAsISOString(dayjs()),
            };
            if (value?.driver_note) {
              payload.driver_note = value?.driver_note;
            }
            await orderApi.complete(currentData?.id, payload).then(() => {
              onReload();
              message.success('Hoàn Thành');
            });
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  };

  const onSuccess = async () => {
    try {
      if (waiting) {
        Modal.warning({
          title: 'Hãy ấn dừng chờ và thử lại!!!',
        });
        return;
      }
      if (isCarNumber) {
        form.validateFields();
        return;
      }
      setOpenBill(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = () => {
    setOpenBill(false);
  };

  const handleSaveCarNumber = async () => {
    try {
      setIsSaveCarNumber(true);
      const car_number = form.getFieldValue('car_number');
      if (!car_number) {
        return;
      }
      const data = {
        car_number,
      };
      await customersApi
        .updateCarNumber(currentData?.customer?.id, data)
        .then(() => {
          onMessageSuccess();
          form.setFieldValue('car_number', null);
          setIsSaveCarNumber(false);
          onReload();
        })
        .catch(() => {
          setIsSaveCarNumber(false);
        });
    } catch (error) {
      setIsSaveCarNumber(false);
      console.error(error);
    }
  };

  return (
    <>
      {(currentData?.order_status === OrderStatus.picked.status ||
        currentData?.order_status === OrderStatus.pickup_point.status) && (
        <Row>
          <Col span={24}>
            <Form onFinish={onFinish} onFinishFailed={onFailed} form={form}>
              <Form.Item>
                <Form.Item
                  name="car_number"
                  rules={[
                    {
                      required: isCarNumber,
                      message: 'Vui lòng nhập bks để hoàn thành cuốc!!',
                    },
                  ]}
                >
                  <Input placeholder="Nhập biển số xe" />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={isSaveCarNumber}
                    type="primary"
                    onClick={handleSaveCarNumber}
                  >
                    <IntlMessage id="global.save" />
                  </Button>
                </Form.Item>
              </Form.Item>

              <Form.Item
                name="driver_note"
                rules={[
                  {
                    required: false,
                    message: 'Vui lòng nhập bks để hoàn thành cuốc!!',
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Nhập tên xe và các thông tin liên quan . . ."
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
      {(currentData?.order_status === OrderStatus.assigned.status ||
        currentData?.order_status === OrderStatus.confirmed.status) && (
        <Row justify="space-around" gutter={[8, 8]}>
          <Col>
            <Button
              disabled={
                currentData?.order_status === OrderStatus.confirmed.status
              }
              onClick={() =>
                handleUpdateOrderStatus(OrderStatus.confirmed.status)
              }
              size="large"
              type="primary"
              danger
            >
              <IntlMessage id="follow.confirm" />
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() =>
                handleUpdateOrderStatus(OrderStatus.pickup_point.status)
              }
              disabled={
                currentData?.order_status !== OrderStatus.confirmed.status
              }
              danger
              size="large"
              type="primary"
            >
              <IntlMessage id="follow.pickup_point" />
            </Button>
          </Col>
        </Row>
      )}

      {(currentData?.order_status === OrderStatus.picked.status ||
        currentData?.order_status === OrderStatus.pickup_point.status) && (
        <>
          {currentData?.type === OrderType.normal.status ? (
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Button
                  onClick={handleNightSurcharge}
                  size="large"
                  style={
                    !disableNight && {
                      backgroundImage: `url(${flashingImage})`,
                    }
                  }
                  disabled={disableNight}
                  type="primary"
                  danger
                >
                  <IntlMessage id="follow.night_surcharge" />
                </Button>
              </Col>
              {dayjs().isAfter(dayjs(eventTime.start)) &&
              dayjs().isBefore(dayjs(eventTime.end)) ? (
                <Col>
                  <Button
                    onClick={handleLunarNewYear}
                    disabled={Number(currentData?.event_surcharge) > 0}
                    danger
                    size="large"
                    type="primary"
                  >
                    <IntlMessage id="follow.lunar_new_year" />
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button
                    onClick={onOpenPoints}
                    disabled={
                      currentData?.waiting || currentData?.point >= 3 || waiting
                    }
                    danger
                    size="large"
                    type="primary"
                  >
                    <IntlMessage id="follow.extra_points" />
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  onClick={handleWaiting}
                  danger
                  size="large"
                  type="primary"
                >
                  {waiting ? (
                    <IntlMessage id="follow.stop" />
                  ) : (
                    <IntlMessage id="follow.wait" />
                  )}
                </Button>
              </Col>
            </Row>
          ) : (
            <Row justify="space-around">
              {Number(currentData?.block) === 24 && (
                <Col>
                  <Button
                    onClick={handleOvernight}
                    disabled={Number(currentData?.night_surcharge) > 0}
                    danger
                    size="large"
                    type="primary"
                  >
                    <IntlMessage id="follow.overnight" />
                  </Button>
                </Col>
              )}
              {dayjs().isAfter(dayjs(eventTime.start)) &&
                dayjs().isBefore(dayjs(eventTime.end)) && (
                  <Col>
                    <Button
                      onClick={handleLunarNewYear}
                      disabled={Number(currentData?.event_surcharge) > 0}
                      danger
                      size="large"
                      type="primary"
                    >
                      <IntlMessage id="follow.lunar_new_year" />
                    </Button>
                  </Col>
                )}
              <Col>
                <Button
                  onClick={handleWaiting}
                  danger
                  size="large"
                  type="primary"
                >
                  {waiting ? (
                    <IntlMessage id="follow.stop" />
                  ) : (
                    <IntlMessage id="follow.overtime" />
                  )}
                </Button>
              </Col>
            </Row>
          )}
          {waiting && (
            <Row>
              <Text>
                Thời gian chờ: {Math.floor(waitTime / 60 / 60)}:
                {Math.floor(waitTime / 60) % 60}:{waitTime % 60}
              </Text>
            </Row>
          )}

          {currentData?.order_status === OrderStatus.pickup_point.status ? (
            <Row style={{ marginTop: '15px' }} justify="center" gutter={8}>
              <Button
                onClick={handlePicked}
                size="large"
                style={{ width: '100%' }}
                type="primary"
              >
                <IntlMessage id="follow.start" />
              </Button>
            </Row>
          ) : (
            <Row style={{ marginTop: '15px' }} justify="center" gutter={8}>
              <Button
                onClick={onSuccess}
                size="large"
                style={{ width: '100%' }}
                type="primary"
              >
                <IntlMessage id="follow.completed" />
              </Button>
            </Row>
          )}
        </>
      )}
      {openBill && (
        <Bill
          currentData={currentData}
          open={openBill}
          onclose={onClose}
          onFinish={onFinish}
        />
      )}
    </>
  );
};

export default memo(MenuAction);
