import api from '../utils/api';

class OrderPriceApi {
  getPrice = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/order-price/get/price');
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  changePrice = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/order-price/change/price', data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}

const orderPriceApi = new OrderPriceApi();
export default orderPriceApi;
