import React, { Component } from 'react';
import { Layout } from 'antd';
import dayjs from 'dayjs';

var { Footer } = Layout;

export default class AppFooter extends Component {
  render() {
    return (
      <Footer
        style={{
          textAlign: 'center',
          padding: 16,
          // minHeight: 40,
          color: '#ddd',
          backgroundColor: 'rgb(89 89 98)',
        }}
      >
        ©{dayjs().get('year')} D-Car Lái Xe Hộ
      </Footer>
    );
  }
}
