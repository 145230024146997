import api from '../utils/api';

class DinvitesApi {
  getDinviteById(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/dinvites/driver/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  AcceptInvite(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/dinvites/accept-invite/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  RejectInvite(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/dinvites/reject-invite/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

const dinvitesApi = new DinvitesApi();
export default dinvitesApi;
