import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const localTimezone = dayjs.tz.guess();
export const offset = dayjs().utcOffset();

export const this_year = dayjs().year();
export const last_year = dayjs().year() - 1;
export const next_year = dayjs().year() + 1;
export const this_month = dayjs().month();
export const last_month = this_month === 0 ? 11 : this_month - 1;
export const next_month = this_month === 11 ? 0 : this_month + 1;
export const today = dayjs();
export const last_30_day = dayjs().subtract(30, 'day');
export const last_7_day = dayjs().subtract(7, 'day');
export const start_this_month = dayjs().startOf('month');
export const end_this_month = dayjs().endOf('month');
export const start_last_month = dayjs(
  `${this_month === 0 ? last_year : this_year}-${last_month + 1}`
).startOf('month');
export const end_last_month = dayjs(
  `${this_month === 0 ? last_year : this_year}-${last_month + 1}`
).endOf('month');
export const start_next_month = dayjs(
  `${this_month === 11 ? next_year : this_year}-${next_month + 1}`
).startOf('month');
export const end_next_month = dayjs(
  `${this_month === 11 ? next_year : this_year}-${next_month + 1}`
).endOf('month');

export const formatAsISOString = (date) => {
  return dayjs(date)
    .utc(true)
    .tz(localTimezone)
    .toISOString();
};

export const convertToAnyTimezone = (isoString) => {
  return dayjs(
    dayjs
      .utc(isoString)
      .utcOffset(offset, true)
      .format()
  );
};
