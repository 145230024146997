import {
  Button,
  Card,
  Col,
  Collapse,
  Empty,
  Flex,
  List,
  Row,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import IntlMessage from '../../components/IntlMessage';
import PageTitle from '../../components/PageTitle';
import { useSelector } from 'react-redux';
import config from '../../config';
import postApi from '../../api/posts';
import InfiniteScroll from 'react-infinite-scroll-component';

const { Text } = Typography;

const ListPostDriver = () => {
  const [reload, setReload] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.authUser);
  const pusher_app_key = config.PUSHER_APP_KEY;

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const payload = {
        limit: 99,
        page: 1,
        keyword: '',
        order_by: 'updated_at',
        order_dir: 'DESC',
      };
      const res = await postApi.getAllForDriver(payload);
      const data = res?.data;
      if (Array.isArray(data)) {
        setItems(data);
      }
      setLoading(false);
    }
    fetchData();
  }, [reload]);

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleReadAll = async () => {
    await postApi
      .readAll()
      .then(() => {
        onReload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRead = async (key) => {
    if (key?.length > 0) {
      await postApi
        .read(key)
        .then(() => {
          setItems((item) => {
            return item?.map((message) => {
              if (message?.id === Number(key[0])) {
                return {
                  ...message,
                  read_status: true,
                };
              }
              return message;
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getDataDisplay = (data) => {
    return data.map((item) => ({
      key: item.id,
      className: 'list_item',
      label: (
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <Text strong>{item?.post?.title}</Text>
          </Col>
          {!item?.read_status && (
            <Tag
              color={'red'}
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translateY(-50%)',
              }}
            >
              Mới
            </Tag>
          )}
        </Row>
      ),
      children: (
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <Space size={4}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.post?.content,
                }}
              />
            </Space>
          </Col>
        </Row>
      ),
    }));
  };

  return (
    <Row justify={'center'} style={{ width: '100%' }}>
      <Col span={24}>
        <PageTitle title={<IntlMessage id="sidebar.notification" />} />
      </Col>
      <Col span={24}>
        <Flex justify="end">
          <Button type="primary" onClick={handleReadAll}>
            <IntlMessage id="notification.readAll" />
          </Button>
        </Flex>
      </Col>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin style={{ textAlign: 'center' }} />
        </div>
      ) : items?.length > 0 ? (
        <Col span={24}>
          <Collapse
            items={getDataDisplay(items)}
            accordion={true}
            // onChange={onChange}
            expandIconPosition="end"
            destroyInactivePanel={true}
            onChange={handleRead}
            ghost={true}
          />
        </Col>
      ) : (
        <Empty description="Không có thông báo" />
      )}
    </Row>
  );
};

export default ListPostDriver;
