import { Button, Modal, Space, Typography, message } from 'antd';
import React, { useRef } from 'react';
import IntlMessage from '../../components/IntlMessage';
import { OrderType, eventTime, surchargePrice } from '../../utils/constant';
import Currency from '../../components/Currency';
import { stringPad } from '../../helpers/stringPad';
import dayjs from 'dayjs';
const { Text } = Typography;
const InforOrderForm = (props) => {
  const { open, onClose, item } = props;
  const contentRef = useRef(null);

  const handleCopy = () => {
    const range = document.createRange();
    range.selectNode(contentRef.current);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');

    selection.removeAllRanges();
    message.success({
      content: 'Đã sao chép thành công!',
      className: 'ant-message',
      style: {
        marginTop: '10vh',
      },
    });
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={[
        <Button type="primary" onClick={handleCopy}>
          copy
        </Button>,
      ]}
      title={<IntlMessage id="follow.order_title" />}
    >
      <Space direction="vertical" ref={contentRef}>
        <Text>D-Car. TT điều phối lái xe hộ Hà Nội.</Text>
        <Text>
          💡 Mã lệnh:{' '}
          <span style={{ color: '#247fff' }}>
            {item?.type === OrderType.normal.status
              ? `#1c${stringPad(item?.id)}`
              : `#2c${stringPad(item?.id)}`}
          </span>{' '}
          {item.type === OrderType.block.status &&
            (item.block === 4
              ? 'block 4h'
              : item.block === 8
              ? 'block 8h'
              : item.block === 24 && 'block 24h')}
        </Text>
        <Text>
          📞 Call/Zalo: <a href="tel:0342448438">0342 448 438</a>
        </Text>
        {dayjs().isAfter(dayjs(eventTime.start)) &&
          dayjs().isBefore(dayjs(eventTime.end)) && (
            <>
              <Text>🎯 Tuần lễ phục vụ Tết Nguyễn Đán 2024 🐉</Text>
              <Text>📅 Từ ngày 6-14/2 (tức 27-5 âm lịch)</Text>
            </>
          )}
        <Text>
          🚘 Lái xe:{' '}
          {item.customer.car_number &&
            JSON.parse(item.customer.car_number)[0]?.car_number}
        </Text>
        <Text>
          ☎ Số khách:{' '}
          <a href={`tel:${item?.customer?.mobile}`}>{item?.customer?.mobile}</a>
        </Text>
        {item?.type === OrderType.normal.status ? (
          <>
            <Text>- Điểm đón: {item?.from_add_text}</Text>
            <Text>
              - Điểm trả:{' '}
              {item.type === OrderType.block.status
                ? item?.from_add_text
                : item?.to_add_text}
            </Text>
          </>
        ) : (
          <Text>
            - Lộ trình: {item?.from_add_text} - {item?.to_add_text} -{' '}
            {item?.from_add_text}
          </Text>
        )}

        <Text>

          <React.Fragment>
            {item?.price_setting ? <Text>Cự ly hành trình: {item?.price_setting.from}-{item?.price_setting.to}km</Text> : null}
           
          </React.Fragment>

        </Text>
        <Text style={{ fontWeight: 'bold' }}>
          💰 Cước phí phục vụ:{' '}
          <Currency
            style={{ fontWeight: 'bold' }}
            value={item?.total}
          ></Currency>
          {item.type === OrderType.block.status &&
            (item.block === 4
              ? ' /4h'
              : item.block === 8
              ? ' /8h'
              : item.block === 24 && ' /24h')}
        </Text>
        {dayjs().isAfter(dayjs(eventTime.start)) &&
        dayjs().isBefore(dayjs(eventTime.end)) ? (
          <>
            <Text style={{ fontWeight: 'bold' }}>
              💸 Phụ phí TẾT: 100k / chuyến
            </Text>
            <Text style={{ fontWeight: 'bold' }}>
              * Phát sinh thêm giờ: <Currency value={surchargePrice.waiting} />
              /h
            </Text>
            {item.type === OrderType.normal.status && (
              <>
                <Text style={{ fontWeight: 'bold' }}>
                  * Phụ phí đêm nếu đón sau 22h:{' '}
                  <Currency value={surchargePrice.night.innerCity} />
                </Text>
              </>
            )}
            <Text>* Miễn phí đón trả nhiều điểm (nội thành).</Text>
          </>
        ) : item.type === OrderType.block.status ? (
          <>
            <Text style={{ fontWeight: 'bold' }}>
              * Phát sinh thêm giờ:{' '}
              <Currency
                style={{ fontWeight: 'bold' }}
                value={surchargePrice.block.overTime}
              />
              /h
            </Text>
            {item.block === 24 && (
              <Text style={{ fontWeight: 'bold' }}>
                * Phí hậu cần:{' '}
                <Currency
                  style={{ fontWeight: 'bold' }}
                  value={surchargePrice.block.night}
                />
              </Text>
            )}
          </>
        ) : (
          <>
            <Text style={{ fontWeight: 'bold' }}>
              * Phụ phí đêm nếu đón sau 22h:{' '}
              <Currency
                style={{ fontWeight: 'bold' }}
                value={surchargePrice.night.innerCity}
              />
            </Text>

            <Text style={{ fontWeight: 'bold' }}>
              * Phụ phí (nếu có) đón/trả thêm điểm ({'<'} 5km):{' '}
              <Currency
                style={{ fontWeight: 'bold' }}
                value={surchargePrice.point}
              />
              /điểm
            </Text>
            <Text style={{ fontWeight: 'bold' }}>
              * Phụ phí chờ đợi (nếu có):{' '}
              <Currency
                style={{ fontWeight: 'bold' }}
                value={surchargePrice.waiting}
              />
              /h
            </Text>
          </>
        )}
        <Text>- Đề nghị lái xe và khách hàng. Xác nhận thông tin.</Text>
        <Text>🎯 D-car bảo hiểm phục vụ chuyến đi: 1.000.000đ</Text>
        <Text>Xin cảm ơn.!</Text>
        <Text>
          Website:{' '}
          <a href="https://www.laixeho.net/">https://www.laixeho.net/</a>
        </Text>
      </Space>
    </Modal>
  );
};

export default InforOrderForm;
