import React, { useMemo, useState } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Card,
  Button,
  Result,
  Modal,
  message,
  Statistic,
  Space,
  Typography,
} from 'antd';
import { Redirect } from 'react-router-dom';

import IntlMessages from '../../components/IntlMessage';
import { LockTwoTone, PhoneTwoTone } from '@ant-design/icons';

import usersApi from '../../api/users';
import { login } from '../../redux/actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import userApi from '../../api/user';
import config from '../../config';
import { isMobile } from 'react-device-detect';
import CountryData from '../../utils/countryData';
const { Option } = Select;

const countries = [
  { title: 'Afghanistan', id: 'AF' },
  { title: 'Åland Islands', id: 'AX' },
  { title: 'Albania', id: 'AL' },
  { title: 'Algeria', id: 'DZ' },
  { title: 'American Samoa', id: 'AS' },
  { title: 'AndorrA', id: 'AD' },
  { title: 'Angola', id: 'AO' },
  { title: 'Anguilla', id: 'AI' },
  { title: 'Antarctica', id: 'AQ' },
  { title: 'Antigua and Barbuda', id: 'AG' },
  { title: 'Argentina', id: 'AR' },
  { title: 'Armenia', id: 'AM' },
  { title: 'Aruba', id: 'AW' },
  { title: 'Australia', id: 'AU' },
  { title: 'Austria', id: 'AT' },
  { title: 'Azerbaijan', id: 'AZ' },
  { title: 'Bahamas', id: 'BS' },
  { title: 'Bahrain', id: 'BH' },
  { title: 'Bangladesh', id: 'BD' },
  { title: 'Barbados', id: 'BB' },
  { title: 'Belarus', id: 'BY' },
  { title: 'Belgium', id: 'BE' },
  { title: 'Belize', id: 'BZ' },
  { title: 'Benin', id: 'BJ' },
  { title: 'Bermuda', id: 'BM' },
  { title: 'Bhutan', id: 'BT' },
  { title: 'Bolivia', id: 'BO' },
  { title: 'Bosnia and Herzegovina', id: 'BA' },
  { title: 'Botswana', id: 'BW' },
  { title: 'Bouvet Island', id: 'BV' },
  { title: 'Brazil', id: 'BR' },
  { title: 'British Indian Ocean Territory', id: 'IO' },
  { title: 'Brunei Darussalam', id: 'BN' },
  { title: 'Bulgaria', id: 'BG' },
  { title: 'Burkina Faso', id: 'BF' },
  { title: 'Burundi', id: 'BI' },
  { title: 'Cambodia', id: 'KH' },
  { title: 'Cameroon', id: 'CM' },
  { title: 'Canada', id: 'CA' },
  { title: 'Cape Verde', id: 'CV' },
  { title: 'Cayman Islands', id: 'KY' },
  { title: 'Central African Republic', id: 'CF' },
  { title: 'Chad', id: 'TD' },
  { title: 'Chile', id: 'CL' },
  { title: 'China', id: 'CN' },
  { title: 'Christmas Island', id: 'CX' },
  { title: 'Cocos (Keeling) Islands', id: 'CC' },
  { title: 'Colombia', id: 'CO' },
  { title: 'Comoros', id: 'KM' },
  { title: 'Congo', id: 'CG' },
  { title: 'Congo, The Democratic Republic of the', id: 'CD' },
  { title: 'Cook Islands', id: 'CK' },
  { title: 'Costa Rica', id: 'CR' },
  {
    title: "Cote D'Ivoire",
    id: 'CI',
  },
  { title: 'Croatia', id: 'HR' },
  { title: 'Cuba', id: 'CU' },
  { title: 'Cyprus', id: 'CY' },
  { title: 'Czech Republic', id: 'CZ' },
  { title: 'Denmark', id: 'DK' },
  { title: 'Djibouti', id: 'DJ' },
  { title: 'Dominica', id: 'DM' },
  { title: 'Dominican Republic', id: 'DO' },
  { title: 'Ecuador', id: 'EC' },
  { title: 'Egypt', id: 'EG' },
  { title: 'El Salvador', id: 'SV' },
  { title: 'Equatorial Guinea', id: 'GQ' },
  { title: 'Eritrea', id: 'ER' },
  { title: 'Estonia', id: 'EE' },
  { title: 'Ethiopia', id: 'ET' },
  { title: 'Falkland Islands (Malvinas)', id: 'FK' },
  { title: 'Faroe Islands', id: 'FO' },
  { title: 'Fiji', id: 'FJ' },
  { title: 'Finland', id: 'FI' },
  { title: 'France', id: 'FR' },
  { title: 'French Guiana', id: 'GF' },
  { title: 'French Polynesia', id: 'PF' },
  { title: 'French Southern Territories', id: 'TF' },
  { title: 'Gabon', id: 'GA' },
  { title: 'Gambia', id: 'GM' },
  { title: 'Georgia', id: 'GE' },
  { title: 'Germany', id: 'DE' },
  { title: 'Ghana', id: 'GH' },
  { title: 'Gibraltar', id: 'GI' },
  { title: 'Greece', id: 'GR' },
  { title: 'Greenland', id: 'GL' },
  { title: 'Grenada', id: 'GD' },
  { title: 'Guadeloupe', id: 'GP' },
  { title: 'Guam', id: 'GU' },
  { title: 'Guatemala', id: 'GT' },
  { title: 'Guernsey', id: 'GG' },
  { title: 'Guinea', id: 'GN' },
  { title: 'Guinea-Bissau', id: 'GW' },
  { title: 'Guyana', id: 'GY' },
  { title: 'Haiti', id: 'HT' },
  { title: 'Heard Island and Mcdonald Islands', id: 'HM' },
  { title: 'Holy See (Vatican City State)', id: 'VA' },
  { title: 'Honduras', id: 'HN' },
  { title: 'Hong Kong', id: 'HK' },
  { title: 'Hungary', id: 'HU' },
  { title: 'Iceland', id: 'IS' },
  { title: 'India', id: 'IN' },
  { title: 'Indonesia', id: 'ID' },
  { title: 'Iran, Islamic Republic Of', id: 'IR' },
  { title: 'Iraq', id: 'IQ' },
  { title: 'Ireland', id: 'IE' },
  { title: 'Isle of Man', id: 'IM' },
  { title: 'Israel', id: 'IL' },
  { title: 'Italy', id: 'IT' },
  { title: 'Jamaica', id: 'JM' },
  { title: 'Japan', id: 'JP' },
  { title: 'Jersey', id: 'JE' },
  { title: 'Jordan', id: 'JO' },
  { title: 'Kazakhstan', id: 'KZ' },
  { title: 'Kenya', id: 'KE' },
  { title: 'Kiribati', id: 'KI' },
  { title: "Korea, Democratic People'S Republic of", id: 'KP' },
  { title: 'Korea, Republic of', id: 'KR' },
  { title: 'Kuwait', id: 'KW' },
  { title: 'Kyrgyzstan', id: 'KG' },
  { title: "Lao People'S Democratic Republic", id: 'LA' },
  { title: 'Latvia', id: 'LV' },
  { title: 'Lebanon', id: 'LB' },
  { title: 'Lesotho', id: 'LS' },
  { title: 'Liberia', id: 'LR' },
  { title: 'Libyan Arab Jamahiriya', id: 'LY' },
  { title: 'Liechtenstein', id: 'LI' },
  { title: 'Lithuania', id: 'LT' },
  { title: 'Luxembourg', id: 'LU' },
  { title: 'Macao', id: 'MO' },
  { title: 'Macedonia, The Former Yugoslav Republic of', id: 'MK' },
  { title: 'Madagascar', id: 'MG' },
  { title: 'Malawi', id: 'MW' },
  { title: 'Malaysia', id: 'MY' },
  { title: 'Maldives', id: 'MV' },
  { title: 'Mali', id: 'ML' },
  { title: 'Malta', id: 'MT' },
  { title: 'Marshall Islands', id: 'MH' },
  { title: 'Martinique', id: 'MQ' },
  { title: 'Mauritania', id: 'MR' },
  { title: 'Mauritius', id: 'MU' },
  { title: 'Mayotte', id: 'YT' },
  { title: 'Mexico', id: 'MX' },
  { title: 'Micronesia, Federated States of', id: 'FM' },
  { title: 'Moldova, Republic of', id: 'MD' },
  { title: 'Monaco', id: 'MC' },
  { title: 'Mongolia', id: 'MN' },
  { title: 'Montserrat', id: 'MS' },
  { title: 'Morocco', id: 'MA' },
  { title: 'Mozambique', id: 'MZ' },
  { title: 'Myanmar', id: 'MM' },
  { title: 'Namibia', id: 'NA' },
  { title: 'Nauru', id: 'NR' },
  { title: 'Nepal', id: 'NP' },
  { title: 'Netherlands', id: 'NL' },
  { title: 'Netherlands Antilles', id: 'AN' },
  { title: 'New Caledonia', id: 'NC' },
  { title: 'New Zealand', id: 'NZ' },
  { title: 'Nicaragua', id: 'NI' },
  { title: 'Niger', id: 'NE' },
  { title: 'Nigeria', id: 'NG' },
  { title: 'Niue', id: 'NU' },
  { title: 'Norfolk Island', id: 'NF' },
  { title: 'Northern Mariana Islands', id: 'MP' },
  { title: 'Norway', id: 'NO' },
  { title: 'Oman', id: 'OM' },
  { title: 'Pakistan', id: 'PK' },
  { title: 'Palau', id: 'PW' },
  { title: 'Palestinian Territory, Occupied', id: 'PS' },
  { title: 'Panama', id: 'PA' },
  { title: 'Papua New Guinea', id: 'PG' },
  { title: 'Paraguay', id: 'PY' },
  { title: 'Peru', id: 'PE' },
  { title: 'Philippines', id: 'PH' },
  { title: 'Pitcairn', id: 'PN' },
  { title: 'Poland', id: 'PL' },
  { title: 'Portugal', id: 'PT' },
  { title: 'Puerto Rico', id: 'PR' },
  { title: 'Qatar', id: 'QA' },
  { title: 'Reunion', id: 'RE' },
  { title: 'Romania', id: 'RO' },
  { title: 'Russian Federation', id: 'RU' },
  { title: 'RWANDA', id: 'RW' },
  { title: 'Saint Helena', id: 'SH' },
  { title: 'Saint Kitts and Nevis', id: 'KN' },
  { title: 'Saint Lucia', id: 'LC' },
  { title: 'Saint Pierre and Miquelon', id: 'PM' },
  { title: 'Saint Vincent and the Grenadines', id: 'VC' },
  { title: 'Samoa', id: 'WS' },
  { title: 'San Marino', id: 'SM' },
  { title: 'Sao Tome and Principe', id: 'ST' },
  { title: 'Saudi Arabia', id: 'SA' },
  { title: 'Senegal', id: 'SN' },
  { title: 'Serbia and Montenegro', id: 'CS' },
  { title: 'Seychelles', id: 'SC' },
  { title: 'Sierra Leone', id: 'SL' },
  { title: 'Singapore', id: 'SG' },
  { title: 'Slovakia', id: 'SK' },
  { title: 'Slovenia', id: 'SI' },
  { title: 'Solomon Islands', id: 'SB' },
  { title: 'Somalia', id: 'SO' },
  { title: 'South Africa', id: 'ZA' },
  { title: 'South Georgia and the South Sandwich Islands', id: 'GS' },
  { title: 'Spain', id: 'ES' },
  { title: 'Sri Lanka', id: 'LK' },
  { title: 'Sudan', id: 'SD' },
  { title: 'Surilabel', id: 'SR' },
  { title: 'Svalbard and Jan Mayen', id: 'SJ' },
  { title: 'Swaziland', id: 'SZ' },
  { title: 'Sweden', id: 'SE' },
  { title: 'Switzerland', id: 'CH' },
  { title: 'Syrian Arab Republic', id: 'SY' },
  { title: 'Taiwan, Province of China', id: 'TW' },
  { title: 'Tajikistan', id: 'TJ' },
  { title: 'Tanzania, United Republic of', id: 'TZ' },
  { title: 'Thailand', id: 'TH' },
  { title: 'Timor-Leste', id: 'TL' },
  { title: 'Togo', id: 'TG' },
  { title: 'Tokelau', id: 'TK' },
  { title: 'Tonga', id: 'TO' },
  { title: 'Trinidad and Tobago', id: 'TT' },
  { title: 'Tunisia', id: 'TN' },
  { title: 'Turkey', id: 'TR' },
  { title: 'Turkmenistan', id: 'TM' },
  { title: 'Turks and Caicos Islands', id: 'TC' },
  { title: 'Tuvalu', id: 'TV' },
  { title: 'Uganda', id: 'UG' },
  { title: 'Ukraine', id: 'UA' },
  { title: 'United Arab Emirates', id: 'AE' },
  { title: 'United Kingdom', id: 'GB' },
  { title: 'United States', id: 'US' },
  { title: 'United States Minor Outlying Islands', id: 'UM' },
  { title: 'Uruguay', id: 'UY' },
  { title: 'Uzbekistan', id: 'UZ' },
  { title: 'Vanuatu', id: 'VU' },
  { title: 'Venezuela', id: 'VE' },
  { title: 'Viet Nam', id: 'VN' },
  { title: 'Virgin Islands, British', id: 'VG' },
  { title: 'Virgin Islands, U.S.', id: 'VI' },
  { title: 'Wallis and Futuna', id: 'WF' },
  { title: 'Western Sahara', id: 'EH' },
  { title: 'Yemen', id: 'YE' },
  { title: 'Zambia', id: 'ZM' },
  { title: 'Zimbabwe', id: 'ZW' },
];
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};
const steps = {
  registration_phone: '1',
  send_otp: '2',
  verify_otp: '3',
  registration_header: '4',
  login: '5',
  reset_password: '6',
};
const PlanType = {
  TRIAL: 'Trial',
  STARTUP: 'Startup',
  BUSINESS: 'Business',
};
export default function SignUp() {
  const _ = require('lodash');
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blur, setBlur] = useState(false);
  const deadline = useMemo(() => Date.now() + 1000 * 60 * 1); // Dayjs is also OK
  const [active, setactive] = useState(false);
  const handleTimeOTP = () => {
    setactive(true);
    setCountdown(false);
  };
  const showModal = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
  // const validate = (rules, value) => {
  //     if (blur) {
  //         if (!value) {
  //             return Promise.resolve();
  //         }

  //         return Promise.reject("error error")
  //     }
  //     return Promise.resolve();

  // }

  // const blurEmail = (e) => {
  //     if (e.target.value) {
  //         setBlur(true);
  //         form.validateFields(['email'])
  //     }

  // }
  const [activeKey, setActiveKey] = useState(steps.registration_phone);
  const [countdown, setCountdown] = useState(false);
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.authUser);
  const handleTabClick = (key) => {
    setActiveKey(key);
  };
  const handleBackRegistanPhone = () => {
    setActiveKey(steps.registration_phone);
  };
  const isValidPhone = (phone) =>
    /^(0?)(3[2-9]|5[2|5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/.test(
      phone
    );
  const onFinish = async (values) => {
    let data = {
      ...values,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language ? navigator.language : 'en-US',
    };
    setLoading(true);
    console.log(isValidPhone(data.mobile));
    if (isValidPhone(data.mobile)) {
      let phone = '';
      if (data.mobile.length === 9) {
        form.setFieldsValue({ mobile: '0' + data.mobile });
        phone = data.phone_code + '0' + data.mobile;
      } else {
        phone = data.phone_code + data.mobile;
      }
      try {
        const res = await usersApi.checkMobile(phone);
        if (Boolean(res) === false) {
          setLoading(false);
          setCountdown(true);
          handleTabClick(steps.registration_header);
          // const getOTP = await usersApi.getOTP({
          //   mobile: phone,
          // });
          // if (getOTP) {
          //   setLoading(false);
          //   setCountdown(true);
          //   handleTabClick(steps.registration_header);
          // }
          // message.error({
          //   content:
          //     'Tài khoản của bạn chưa được cập nhật tại hệ thống của chúng tôi, vui lòng thử lại sau!',
          //   className: 'ant-message',
          //   style: {
          //     marginTop: '10vh',
          //   },
          // });
        } else {
          setActiveKey(steps.login);
        }
        setLoading(false);
      } catch (error) {
        console.log('error', error);
        setLoading(false);
        if (error.status === 478) {
          message.error({
            content:
              'Tài khoản của bạn hiện chưa được kích hoạt trong hệ thống D-Car Lái xe hộ. Hãy thử liên hệ ngay để được hỗ trợ!',
            className: 'ant-message',
            style: {
              marginTop: '10vh',
            },
          });
        } else {
          message.error({
            content: 'Số điện thoại đã tồn tại',
            className: 'ant-message',
            style: {
              marginTop: '30vh',
            },
          });
        }
      }
    } else {
      setLoading(false);
      return message.error({
        content: `Số điện thoại không hợp lệ!`,
        className: 'ant-message',
        style: {
          marginTop: '30vh',
        },
      });
    }

    // signUp(data).
    //     then((res) => {
    //         console.log('res', res);
    //         setSuccess(true);
    //         setLoading(true);

    //     }).
    //     catch((error) => {
    //         console.log('error', error);
    //         setLoading(false)

    //         message.error({
    //             content: "Email has already exist",
    //             className: 'ant-message',
    //             style: {
    //                 marginTop: '30vh',
    //             },
    //         });
    //     });
  };

  const onFinishVerifyOTp = async (values) => {
    let data = {
      mobile: form.getFieldValue('phone_code') + form.getFieldValue('mobile'),
      otp: values.code,
    };
    setLoading(true);
    try {
      const res = await usersApi.verifyOTP(data);
      if (res) {
        handleTabClick(steps.registration_header);
        form3.setFieldsValue({
          phone_code: form.getFieldValue('phone_code'),
          mobile: form.getFieldValue('mobile'),
        });
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      message.error({
        content: 'Mã xác thực không tồn tại, vui lòng thử lại!',
        className: 'ant-message',
        style: {
          marginTop: '30vh',
        },
      });
    }
  };
  const handleReloadOTP = async () => {
    let data = {
      mobile: form.getFieldValue('phone_code') + form.getFieldValue('mobile'),
    };
    setLoading(true);
    try {
      const res = await usersApi.getOTP(data);
      setLoading(false);
      setCountdown(true);
      setactive(true);
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      message.error({
        content: `Gửi mã thật bại`,
        className: 'ant-message',
        style: {
          marginTop: '30vh',
        },
      });
    }
  };
  const onFinishSaveUser = async (values) => {
    let data = {
      ...values,
    };
    setLoading(true);
    try {
      const res = await usersApi.register(data);
      console.log(res);
      if (res) {
        // setCookie('token', res.tokens.accessToken, 30);
        // setCookie('refesh-token', res.tokens.refreshToken, 30);
        // dispatch({ type: LOGIN, payload: res.data });
        // window.location.href = '/app/home';
        Modal.success({
          title:
            'Tài khoản tạo thành công, vui lòng liên hệ D-Car Lái xe hộ để được kích hoạt tài khoản !',
          okText: 'Ok',
          onOk: handleTabClick(steps.registration_phone),
        });
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      message.error({
        content: 'Tài khoản tạo không thành công',
        className: 'ant-message',
        style: {
          marginTop: '30vh',
        },
      });
    }
    console.log(data);
  };
  const onFinishLogin = async (values) => {
    var dataSub = {
      mobile: form.getFieldValue('phone_code') + form.getFieldValue('mobile'),
      password: values.password,
    };
    console.log(dataSub);
    setLoading(true);
    try {
      dispatch(login(dataSub));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error({
        content: 'Mật khẩu không không chính xác!',
        className: 'ant-message',
        style: {
          marginTop: '30vh',
        },
      });
    }
  };
  const handleResetPassword = () => {
    setActiveKey(steps.reset_password);
  };
  const handleSendEmail = async () => {
    const data = {
      mobile: form.getFieldValue('mobile'),
    };
    setLoading(true);
    const res = await userApi.resetPassword(data);
    if (res) {
      if (!res.data) {
        message.error({
          content:
            'Email của bạn chưa được cập nhật tại hệ thống của chúng tôi, vui lòng liên hệ số điện thoại 0834509566 để được tư vấn hỗ trợ!',
          className: 'ant-message',
          style: {
            marginTop: '10vh',
          },
        });
      } else {
        message.error({
          content: 'Email đã được gửi, vui lòng kiểm tra email của bạn!',
          className: 'ant-message',
          style: {
            marginTop: '10vh',
          },
        });
      }
      setSuccess(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  if (authUser) return <Redirect to="/app/home" />;
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div className="fullscreen">
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: '100vh', padding: isMobile ? '0' : '0 0.5rem' }}
      >
        <Col xs={24} xl={7}>
          <Card
            style={{
              backgroundColor: 'rgba(255,255,255,0.7)',
              minHeight: isMobile ? '80vh' : '246px',
            }}
            className="sign-card"
          >
            <Row
              type="flex"
              justify="center"
              style={{
                marginBottom: isMobile ? '20px' : '10px',
              }}
            >
              {
                <img
                  src={require('../../assets/img/logo.png')}
                  className="login-logo"
                />
              }
            </Row>
            {activeKey === steps.registration_phone && (
              <>
                <Form
                  {...formItemLayout}
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  initialValues={{
                    phone_code: '+84',
                  }}
                  scrollToFirstError
                  labelAlign="left"
                  size="large"
                >
                  <Form.Item
                    name="phone_code"
                    hidden
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="global.requiredfield" />,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      showSearch
                      placeholder="Select type"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {CountryData.map((item, index) => {
                        let labelstr = '';
                        labelstr = `(+${item.code}) ${item.country}`;
                        return (
                          <Select.Option key={index} value={`+${item.code}`}>
                            {labelstr}
                          </Select.Option>
                        );
                      })}
                      ;
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="mobile"
                    label={<IntlMessages id="global.mobile_enter" />}
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="global.requiredfield" />,
                      },
                    ]}
                  >
                    <Input
                      prefix={<PhoneTwoTone className="site-form-item-icon" />}
                      placeholder="Nhập số điện thoại"
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      className="login-form-button"
                      htmlType="submit"
                      size="large"
                      loading={loading}
                      span={24}
                    >
                      {<IntlMessages id="global.continue" />}
                    </Button>
                  </Form.Item>
                  {/* <Typography.Text>
                    <IntlMessages id='global.comfirm_policy' />
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={config.policy}
                    >
                      {' '}
                      <IntlMessages id='global.comfirm_policy_link' />
                    </a>
                  </Typography.Text> */}
                </Form>
              </>
            )}
            {activeKey === steps.verify_otp && (
              <>
                <Form
                  {...formItemLayout}
                  form={form2}
                  name="register"
                  onFinish={onFinishVerifyOTp}
                  initialValues={{
                    phone_code: '+84',
                  }}
                  scrollToFirstError
                  labelAlign="left"
                  size="large"
                >
                  <Space
                    size="middle"
                    align="start"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Typography.Title level={5}>
                      <IntlMessages id="global.send_Otp" />{' '}
                      {form.getFieldValue('mobile') || 0}
                    </Typography.Title>
                    <Statistic.Countdown
                      format="mm:ss"
                      value={countdown && deadline}
                      onFinish={handleTimeOTP}
                    />
                  </Space>
                  <Space.Compact>
                    <Row gutter={[12, 12]}>
                      <Col span={17}>
                        <Form.Item
                          name="code"
                          rules={[
                            {
                              required: true,
                              message: (
                                <IntlMessages id="global.requiredfield" />
                              ),
                            },
                          ]}
                        >
                          <Input placeholder="Nhập mã xác thực"></Input>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Button
                          disabled={active === false ? true : false}
                          style={{ width: '100%' }}
                          onClick={handleReloadOTP}
                          span={24}
                        >
                          <IntlMessages id="global.load_more" />
                        </Button>
                      </Col>
                    </Row>
                  </Space.Compact>

                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={loading}
                      span={24}
                      style={{ width: '100%', marginBottom: '0.5rem' }}
                    >
                      {<IntlMessages id="global.continue" />}
                    </Button>
                    <Button
                      type="default"
                      htmlType="submit"
                      size="large"
                      span={24}
                      style={{ width: '100%' }}
                      onClick={handleBackRegistanPhone}
                    >
                      {<IntlMessages id="global.back" />}
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
            {activeKey === steps.registration_header && (
              <>
                <Form
                  {...formItemLayout}
                  form={form3}
                  name="register"
                  onFinish={onFinishSaveUser}
                  initialValues={{
                    phone_code: '+84',
                    plan: PlanType.TRIAL,
                    mobile: form.getFieldValue('mobile'),
                  }}
                  scrollToFirstError
                  labelAlign="left"
                  size="large"
                >
                  {/* <Form.Item
                    name="title"
                    label={<IntlMessages id="global.name_city" />}
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="global.requiredfield" />,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Nhập tên công ty" />
                  </Form.Item> */}
                  {/* <Space.Compact> */}
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label={<IntlMessages id="global.fullname" />}
                        name="lastname"
                        rules={[
                          {
                            required: true,
                            message: <IntlMessages id="global.requiredfield" />,
                          },
                        ]}
                      >
                        <Input placeholder="Nhập Họ và tên" />
                      </Form.Item>
                    </Col>
                    {/* <Col span={14}>
                        <Form.Item
                          label={<IntlMessages id="global.firstname" />}
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: (
                                <IntlMessages id="global.requiredfield" />
                              ),
                            },
                          ]}
                        >
                          <Input placeholder="Nhập tên" />
                        </Form.Item>
                      </Col> */}
                  </Row>
                  {/* </Space.Compact> */}

                  <Form.Item
                    name="phone_code"
                    hidden
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="global.requiredfield" />,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      showSearch
                      placeholder="Select type"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {CountryData.map((item, index) => {
                        let labelstr = '';
                        labelstr = `(+${item.code}) ${item.country}`;
                        return (
                          <Select.Option key={index} value={`+${item.code}`}>
                            {labelstr}
                          </Select.Option>
                        );
                      })}
                      ;
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="mobile"
                    label={<IntlMessages id="global.mobile" />}
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="global.requiredfield" />,
                      },
                    ]}
                  >
                    <Input
                      prefix={<PhoneTwoTone className="site-form-item-icon" />}
                      readOnly
                      placeholder="Nhập số điện thoại"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={<IntlMessages id="global.password" />}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập mật khẩu!',
                      },
                      { min: 6, message: 'Mật khẩu tối thiểu 6 ký tự!' },
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Nhập mật khẩu..." />
                  </Form.Item>
                  {/* <Form.Item
                    name="plan"
                    label={<IntlMessages id="global.your_plan" />}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn Plan!',
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={PlanType.TRIAL}>
                        <IntlMessages id="global.trial" />
                      </Radio>
                      <Radio value={PlanType.STARTUP}>
                        <IntlMessages id="global.startup" />
                      </Radio>
                      <Radio value={PlanType.BUSINESS}>
                        <IntlMessages id="global.business" />
                      </Radio>
                    </Radio.Group>
                  </Form.Item> */}
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                      loading={loading}
                      span={24}
                    >
                      {<IntlMessages id="global.signup" />}
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
            {activeKey === steps.login && (
              <>
                <Form
                  {...formItemLayout}
                  // layout="vertical"
                  initialValues={{
                    remember: true,
                    phone_code: '+84',
                  }}
                  onFinish={onFinishLogin}
                  onFinishFailed={onFinishFailed}
                  size="large"
                  labelAlign="left"
                >
                  <Form.Item
                    name="password"
                    label={<IntlMessages id="global.password" />}
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="global.password_required" />,
                      },
                      { min: 6, message: 'Mật khẩu tối thiểu 6 ký tự!' },
                    ]}
                  >
                    <Input.Password
                      prefix={<LockTwoTone className="site-form-item-icon" />}
                      placeholder="password"
                    />
                  </Form.Item>

                  <Row justify="space-between">
                    <Col></Col>
                    <Col>
                      <Typography.Link onClick={handleResetPassword}>
                        <IntlMessages id="global.forgot_password" />
                      </Typography.Link>
                    </Col>
                  </Row>

                  <Form.Item span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={loading}
                    >
                      <IntlMessages id="global.signin" />
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
            {activeKey === steps.reset_password && (
              <>
                <Result
                  title="Chúng tôi sẽ gửi thông tin lấy lại mật khẩu vào email của bạn"
                  subTitle="Vui lòng nhấn gửi email, kiểm tra và làm theo hướng dẫn để lấy lại mật khẩu. Liên hệ số điện thoại 0834509566 để được tư vấn hỗ trợ!"
                  extra={[
                    <Button
                      type="primary"
                      key="console"
                      className="login-form-button"
                      style={{ marginBottom: '0.5rem' }}
                      loading={loading}
                      onClick={() => setActiveKey(steps.login)}
                    >
                      <IntlMessages id="signup.back_to_login" />
                    </Button>,
                    <Button
                      type="default"
                      key="console"
                      className="login-form-button"
                      loading={loading}
                      onClick={handleSendEmail}
                    >
                      <IntlMessages id="global.send_email" />
                    </Button>,
                  ]}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
