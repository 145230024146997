import React, { useEffect, useState } from 'react';
import IntlMessages from '../components/IntlMessage';
import {
  BillStatus,
  OrderStatus,
  PayStatus,
  BillTypes,
  VoucherStatus,
} from '../utils/constant';

const useStatus = () => {
  const [orderStatusFilter, setOrderStatusFilter] = useState([]);
  const [payStatusFilter, setPayStatusFilter] = useState([]);
  const [billTypesFilter, setBillTypesFilter] = useState([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState([]);
  const [voucherStatusFilter, setVoucherStatusFilter] = useState([]);

  useEffect(() => {
    const filters = Object.keys(OrderStatus).map((item, index) => {
      return {
        id: item,
        title: <IntlMessages id={`global.${item}`} />,
      };
    });
    // filters.unshift({ id: null, title: <IntlMessages id="global.all" /> });
    setOrderStatusFilter(filters);
  }, []);

  useEffect(() => {
    const filters = Object.keys(PayStatus).map((item, index) => {
      return {
        id: item,
        title: <IntlMessages id={`global.${item}`} />,
      };
    });
    // filters.unshift({ id: null, title: <IntlMessages id="global.all" /> });
    setPayStatusFilter(filters);
  }, []);

  useEffect(() => {
    const filters = Object.keys(BillTypes).map((item, index) => {
      return {
        id: item,
        title: <IntlMessages id={`global.${item}`} />,
      };
    });
    // filters.unshift({ id: null, title: <IntlMessages id="global.all" /> });
    setBillTypesFilter(filters);
  }, []);

  useEffect(() => {
    const filters = Object.keys(BillStatus).map((item, index) => {
      return {
        id: item,
        title: <IntlMessages id={`global.${item}`} />,
      };
    });
    // filters.unshift({ id: null, title: <IntlMessages id="global.all" /> });
    setPaymentStatusFilter(filters);
  }, []);

  useEffect(() => {
    const filters = Object.keys(VoucherStatus).map((item, index) => {
      return {
        id: item,
        title: <IntlMessages id={`global.${item}`} />,
      };
    });
    setVoucherStatusFilter(filters);
  }, []);

  return {
    orderStatusFilter,
    payStatusFilter,
    billTypesFilter,
    paymentStatusFilter,
    voucherStatusFilter,
  };
};

export default useStatus;
