import { Button, Form, Input, Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import IntlMessage from '../../../components/IntlMessage';
import {
  MinusCircleOutlined,
  PhoneTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import customersApi from '../../../api/customers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useIntl } from 'react-intl';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const { TextArea } = Input;

const ModalUpdateCustomer = (props) => {
  const { open, onClose, item, onReload } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const intl = useIntl();

  const initialValues = {
    phone_code: '+84',
    name: item && item.name ? item.name : '',
    phone: item && item.mobile ? item.mobile : '',
    note: item && item.note ? item.note : '',
    car_number:
      item && item.car_number
        ? Array.isArray(item.car_number)
          ? item.car_number
          : JSON.parse(item.car_number)
        : [],
  };

  const isValidPhone = (phone) =>
    /^(0?)(3[2-9]|5[2|5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/.test(
      phone
    );

  const onFailed = (error) => {
    Modal.error({
      title: error.errorFields[0].errors[0],
    });
  };

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        let data = values;
        data.car_number = JSON.stringify(data.car_number);
        data.mobile = values.phone;
        delete data.phone;

        await customersApi
          .update(item.id, data)
          .then(() => {
            setLoading(false);
            onClose();
          })
          .catch((error) => {
            console.error(error);
            Modal.error({
              title: error.data.message,
              okText: intl.formatMessage({ id: 'global.close' }),
            });
            setLoading(false);
          });
        setLoading(false);
        onReload();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    setLoading(false);
  };

  const tabs = [
    {
      key: 1,
      label: <IntlMessage id="driver.info" />,
      forceRender: true,
      children: (
        <>
          <Form.Item name="name" label={<IntlMessage id="driver.last_name" />}>
            <Input placeholder="Nhập tên..." />
          </Form.Item>
          <Form.Item
            name="phone"
            label={<IntlMessage id="global.mobile" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.required" />,
              },
              {
                min: 9,
                max: 10,
                message: 'Không hợp lệ!!!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (isValidPhone(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Số điện thoại không hợp lệ!!');
                  }
                },
              }),
            ]}
          >
            <Input
              prefix={<PhoneTwoTone className="site-form-item-icon" />}
              placeholder="Nhập số điện thoại"
            />
          </Form.Item>
          <Form.List name="car_number">
            {(fields, { add, remove }) => (
              <Form.Item label="biển số">
                {fields.map(({ key, name, ...itemtField }, index) => (
                  <div key={key} style={{ display: 'flex', gap: '0 10px' }}>
                    <Form.Item
                      {...itemtField}
                      name={[name, 'car_number']}
                      // label={index === 0 ? 'Biển số' : ''}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessage id="global.requiredfield" />,
                        },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input
                        placeholder="Biển số..."
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    <IntlMessage id="customers.add_car_number" />
                  </Button>
                </Form.Item>
              </Form.Item>
            )}
          </Form.List>
          <Form.Item name="note" label={<IntlMessage id="global.note" />}>
            <TextArea rows={3} placeholder="Ghi chú..." />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <Modal open={open} onCancel={onClose} onOk={handleSubmit}>
      <Form
        {...layout}
        initialValues={initialValues}
        form={form}
        labelAlign="left"
        onFinishFailed={onFailed}
        onFinish={handleSubmit}
      >
        <Tabs items={tabs} />
      </Form>
    </Modal>
  );
};

export default ModalUpdateCustomer;
