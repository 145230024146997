import React, { useEffect, useState } from 'react';
import messageApi from '../../api/message';
import { Card, Pagination, Space, Spin, Typography } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const OrderStatusMessage = (props) => {
  const { onResetTotal } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    keyword: '',
    order_by: 'id',
    order_dir: 'DESC',
  });
  const [total, setTotal] = useState(null);
  const history = useHistory();

  const { Text } = Typography;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await messageApi
        .getAllForAdmin(filter)
        .then((res) => {
          if (Array.isArray(res.data)) {
            setTotal(res.total);
            setItems(res.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    fetchData();
  }, [filter]);

  useEffect(() => {
    const handleReadAll = async () => {
      await messageApi
        .readAllAdmin()
        .then(() => {
          onResetTotal();
        })
        .catch((error) => {
          console.error(error);
        });
    };

    handleReadAll();
  }, []);

  const handleDetail = (item) => {
    if (item) {
      if (item) {
        history.push('/app/orders/' + item.message);
      }
    }
  };

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={4}>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        ) : (
          <>
            {items?.map((item, index) => {
              return (
                <Card key={index} hoverable style={{ width: '100%' }} bordered>
                  <Text strong onClick={() => handleDetail(item)}>
                    {item?.title}
                  </Text>
                </Card>
              );
            })}
            <Pagination
              style={{ display: 'flex', justifyContent: 'center' }}
              current={filter.page}
              pageSize={filter.limit}
              onChange={handleChangePage}
              total={total}
            />
          </>
        )}
      </Space>
    </>
  );
};

export default OrderStatusMessage;
