import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Redirect, Switch, Route } from 'react-router-dom';
import { getAuthUser } from '../redux/actions/AuthActions';
import { LoadingScreen } from '../components/LoadingScreen';

//route
import AppRoutes from './AppRoutes';
import ResetPassword from '../routes/auth/ResetPassword';
import Signup from '../routes/auth/Signup';
import runOneSignal from '../components/onesignal';

function MainApp(props) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isMount = useRef(false);
  const user = useSelector((state) => state.auth.authUser);

  useEffect(() => {
    isMount.current = true;

    function fetchData() {
      if (isMount) {
        dispatch(getAuthUser())
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
    fetchData();

    return () => {
      isMount.current = false;
    };
  }, [dispatch]);

  useEffect(() => {
    // console.log(user);
    if (user) {
      runOneSignal(user.id);
    }
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  const { location, match } = props;
  if (location.pathname === '/') {
    return <Redirect to={'/app/home'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/resetpassword" component={ResetPassword}></Route>
        <Route exact path="/signup" component={Signup}></Route>
        <Route path={`${match.url}app`} component={AppRoutes}></Route>
      </Switch>
    </React.Fragment>
  );
}

export default withRouter(MainApp);
