import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import driverApi from '../../api/driver';
import IntlMessage from '../../components/IntlMessage';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Tabs,
  message,
} from 'antd';
import CountryData from '../../utils/countryData';
import UploadImage from '../../components/Elements/UploadImage';
import { useIntl } from 'react-intl';
import { PhoneTwoTone } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import { formatAsISOString } from '../../helpers/time';
import dayjs from 'dayjs';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const DriverForm = () => {
  const { driver_id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const intl = useIntl();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const initialValues = {
    phone_code: '+84',
  };
  const [isUpdateNew, setIsUpdateNew] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (driver_id) {
        const res = await driverApi.getDetail(driver_id);
        if (res) {
          setItem(res);
          form.setFieldsValue({
            lastname: res && res.lastname ? res.lastname : '',
            description: res && res.description ? res.description : '',
            mobile: res && res.mobile ? res.mobile : '',
            address: res && res.address ? res.address : '',
            image: res && res.image ? res.image : '',
            qr_code: res && res.qr_code ? res.qr_code : '',
            account_nb: res && res.bank ? JSON.parse(res.bank).account_nb : '',
            bank_name: res && res.bank ? JSON.parse(res.bank).bank_name : '',
          });
        }
      }
    }

    fetchData();
  }, [driver_id]);

  const isValidPhone = (phone) =>
    /^(0?)(3[2-9]|5[2|5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/.test(
      phone
    );

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        let data = { ...values };
        delete data.image;
        delete data.image_qr;
        delete data.confirm_password;
        delete data.bank_name;
        delete data.account_nb;
        delete data.is_new;
        data.bank = JSON.stringify({
          bank_name: values.bank_name,
          account_nb: values.account_nb,
        });
        data.description = data.description ? data.description : '';

        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        if (imgs) {
          formData.append('cover_image', imgs);
        }

        if (imgQr) {
          formData.append('qr_code', imgQr);
        }

        if (driver_id) {
          if (!data.password) formData.delete('password');
          if (isUpdateNew) {
            formData.append('end_date', formatAsISOString(dayjs()));
          }
          await driverApi
            .update(driver_id, formData)
            .then(() => {
              setLoading(false);
              history.push('/app/drivers-manager');

              message.success({
                content: 'Cập nhật thông tin tài xế thành công!',
              });
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: 'global.close' }),
              });
              setLoading(false);
            });
          setLoading(false);
        } else {
          await driverApi
            .create(formData)
            .then(() => {
              setLoading(false);
              history.push('/app/drivers-manager');
              message.success({
                content: 'Khởi tạo thông tin tài xế thành công!',
              });
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: 'global.close' }),
              });
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    setLoading(false);
  };

  const onFailed = (error) => {
    Modal.error({
      title: error.errorFields[0].errors[0],
    });
  };

  const fileList = useMemo(
    () =>
      item && item.image
        ? [
            {
              uid: '1',
              name: 'image.png',
              status: 'done ',
              url: item.image,
            },
          ]
        : [],
    [item]
  );

  const fileQrList = useMemo(
    () =>
      item && item.qr_code
        ? [
            {
              uid: '1',
              name: 'qr_code.png',
              status: 'done ',
              url: item.qr_code,
            },
          ]
        : [],
    [item]
  );

  const [imgs, setImgs] = useState(null);
  const [imgQr, setImgQr] = useState(null);

  const onChangeImage = (v, f) => {
    setImgs(f);
  };

  const onChangeImageQr = (v, f) => {
    setImgQr(f);
  };

  const handleUpdateNewDriver = (e) => {
    console.log(e);
    const check = e?.target?.checked;
    if (typeof check === 'boolean') {
      setIsUpdateNew(check);
    }
  };

  const tabs = [
    {
      key: 1,
      label: <IntlMessage id="driver.info" />,
      forceRender: true,
      children: (
        <>
          <Form.Item
            name="is_new"
            label="Tài xế mới"
            valuePropName="checked"
            rules={[
              {
                required: false,
                message: <IntlMessage id="global.required" />,
              },
            ]}
          >
            <Checkbox disabled={!driver_id} onChange={handleUpdateNewDriver}>
              New
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="lastname"
            label={<IntlMessage id="driver.last_name" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.required" />,
              },
            ]}
          >
            <Input placeholder="Nhập tên..." />
          </Form.Item>
          <Form.Item
            name="phone_code"
            hidden
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              allowClear
              showSearch
              placeholder="Select type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {CountryData.map((item, index) => {
                let labelstr = '';
                labelstr = `(+${item.code}) ${item.country}`;
                return (
                  <Select.Option key={index} value={`+${item.code}`}>
                    {labelstr}
                  </Select.Option>
                );
              })}
              ;
            </Select>
          </Form.Item>
          <Form.Item
            name="mobile"
            label={<IntlMessage id="global.mobile" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
              {
                min: 9,
                max: 10,
                message: 'Không hợp lệ!!!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (isValidPhone(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Số điện thoại không hợp lệ!!');
                  }
                },
              }),
            ]}
          >
            <Input
              prefix={<PhoneTwoTone className="site-form-item-icon" />}
              placeholder="Nhập số điện thoại"
            />
          </Form.Item>
          <Form.Item name="address" label={<IntlMessage id="global.address" />}>
            <Input placeholder="Nhập địa chỉ..." />
          </Form.Item>
          <Form.Item
            name="bank_name"
            label={<IntlMessage id="driver.bank_name" />}
          >
            <Input placeholder="Nhập tên ngân hàng..." />
          </Form.Item>
          <Form.Item
            name="account_nb"
            label={<IntlMessage id="driver.account_nb" />}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="Nhập số tài khoản..."
            />
          </Form.Item>
          <Form.Item label={<IntlMessage id="global.image" />}>
            <Form.Item
              name="image"
              style={{
                display: 'inline-block',
                width: 'calc(50% - 12px)',
              }}
            >
              <UploadImage onChangeData={onChangeImage} initList={fileList} />
            </Form.Item>
            <Form.Item
              name="image_qr"
              style={{
                display: 'inline-block',
                width: 'calc(50% - 12px)',
              }}
            >
              <UploadImage
                onChangeData={onChangeImageQr}
                initList={fileQrList}
              />
            </Form.Item>
          </Form.Item>
        </>
      ),
    },
    {
      key: 2,
      label: <IntlMessage id="driver.account" />,
      forceRender: true,
      children: (
        <>
          <Form.Item
            name="password"
            label={<IntlMessage id="global.password" />}
            rules={[
              {
                required: driver_id ? false : true,
                message: 'Vui lòng nhập mật khẩu!',
              },
              { min: 6, message: 'Mật khẩu tối thiểu 6 ký tự!' },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Nhập mật khẩu..." autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label={<IntlMessage id="global.confirm_password" />}
            dependencies={['password']}
            rules={[
              {
                required: driver_id ? false : true,
                message: 'Vui lòng xác nhận password!!!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Password không khớp!!!');
                  }
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Nhập lại password..." />
          </Form.Item>
        </>
      ),
    },
    {
      key: 3,
      label: <IntlMessage id="global.description" />,
      forceRender: true,
      children: (
        <>
          <Form.Item
            name="description"
            label={<IntlMessage id="global.description" />}
          >
            <Input.TextArea rows={4} placeholder="Nhập mô tả ..." />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      {driver_id ? (
        <PageTitle title={<IntlMessage id="drivers.edit_title" />} />
      ) : (
        <PageTitle title={<IntlMessage id="drivers.create_title" />} />
      )}
      <Form
        {...layout}
        initialValues={initialValues}
        form={form}
        labelAlign="left"
        onFinishFailed={onFailed}
        onFinish={handleSubmit}
      >
        <Tabs items={tabs} />
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          style={{ width: '100%' }}
          // onClick={handleSubmit}
        >
          <IntlMessage id="global.save" />
        </Button>
      </Form>
    </>
  );
};

export default DriverForm;
