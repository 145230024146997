import {
  Button,
  Card,
  Col,
  Collapse,
  Modal,
  Pagination,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import orderApi from '../../api/order';
import Status from '../../components/Status';
import IntlMessage from '../../components/IntlMessage';
import {
  InviteStatus,
  LocalStorageKeys,
  OrderStatus,
  OrderType,
} from '../../utils/constant';
import SentDriverForm from './SentDriverForm';
import FilterBar from '../../components/FilterBar';
import _, { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import useStatus from '../../hooks/useStatus';
import PageTitle from '../../components/PageTitle';
import Pusher from 'pusher-js';
import { Channels } from '../../utils/constant';
import config from '../../config';
import Currency from '../../components/Currency';
import InforOrderForm from './InforOrderForm';
import ModalChangeDriver from './ModalChangeDriver';
import { stringPad } from '../../helpers/stringPad';
import useTime from '../../hooks/useTime';
import dayjs from 'dayjs';
import { formatAsISOString } from '../../helpers/time';
import ModalOrderStatus from './ModalOrderStatus';
import { getWithExpiry, setWithExpiry } from '../../utils/localStorage';

const { Text } = Typography;
const pusher_app_key = config.PUSHER_APP_KEY;
const pusher = new Pusher(pusher_app_key, {
  cluster: 'ap1',
});

const ListOrder = () => {
  const storage = getWithExpiry(LocalStorageKeys.order_list);
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState(
    storage && storage?.filter
      ? storage.filter
      : { limit: 10, page: 1, keyword: '', order_by: 'date', order_dir: 'DESC' }
  );
  const [reload, setReload] = useState(false);
  const [openSent, setOpenSent] = useState(false);
  const [changeDriver, setChangeDriver] = useState(false);
  const [total, setTotal] = useState(0);
  const [item, setItem] = useState({});
  const [condition, setCondition] = useState({});
  const { orderStatusFilter } = useStatus();
  const [openInfor, setOpenInfor] = useState(false);
  const [loading, setLoading] = useState(false);
  const { convertToAnyTimezone } = useTime();
  const [openStatus, setOpenStatus] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await orderApi.getAll(filter);
      const data = res.data;
      if (res) {
        setTotal(res.total);
      }
      if (Array.isArray(data)) {
        setItems(data);
      }
      setLoading(false);
    }

    fetchData();
  }, [filter, reload]);

  useEffect(() => {
    if (filter?.filter_name && filter?.filter_value) {
      const newCondition = _.zipObjectDeep(
        filter?.filter_name,
        filter?.filter_value
      );
      setCondition(newCondition);
    }
    setWithExpiry(LocalStorageKeys.order_list, {
      filter: _.cloneDeep(filter),
    });
  }, [filter]);

  useEffect(() => {
    const channel = pusher.subscribe(Channels.dinvite.channel);
    channel.bind(Channels.dinvite.event.accept_invite, (data) => {
      // console.log(data);
      if (data) {
        console.log(data);
        setItems((item) =>
          item?.map((order) => {
            if (order.id === data.order_id) {
              return {
                ...order,
                driver: data.driver,
              };
            }
            return order;
          })
        );
      }
    });
    channel.bind(Channels.dinvite.event.reject_invite, (data) => {
      if (data) {
        console.log(data);
        setItems((item) =>
          item?.map((order) => {
            if (order?.invites?.length > 0) {
              return {
                ...order,
                invites: order.invites.map((invite) => {
                  if (invite.id === data.invite.id) {
                    return data.invite;
                  }
                  return invite;
                }),
              };
            }
            return order;
          })
        );
      }
    });
    return () => {
      pusher.unsubscribe(Channels.dinvite.channel);
      pusher.unbind();
    };
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe(Channels.order.channel);
    channel.bind(Channels.order.event.completed, (data) => {
      console.log(data);
      if (data) {
        setItems((item) =>
          item?.map((order) => {
            if (order.id === data.order_id) {
              return {
                ...order,
                order_status: OrderStatus.completed.status,
              };
            }
            return order;
          })
        );
      }
    });
    return () => {
      pusher.unsubscribe(Channels.order.channel);
      pusher.unbind();
    };
  }, []);

  const onFilter = debounce(async (value, title, type) => {
    if (type === 'search') {
      setFilter((filter) => {
        return { ...filter, keyword: value, page: 1 };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  const onReload = () => {
    setReload((e) => !e);
  };

  const onOpenSentDriver = (data) => {
    setItem(data);
    setOpenSent(true);
  };

  const onCloseSentDriver = () => {
    setOpenSent(false);
  };

  const onOpenInforOrder = (data) => {
    setItem(data);
    setOpenInfor(true);
  };

  const onCloseInforOrder = () => {
    setOpenInfor(false);
  };

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  const openChangeDriver = (data) => {
    setItem(data);
    setChangeDriver(true);
  };

  const closeChangeDriver = () => {
    setChangeDriver(false);
  };

  const handleEdit = (id) => {
    history.push('/app/edit-order/' + id);
  };

  const handleDetail = (id) => {
    history.push('/app/orders/' + id);
  };

  const handleOrderCopy = async (id) => {
    await orderApi
      .copyOrder(id, {
        date: formatAsISOString(dayjs()),
      })
      .then((res) => {
        if (res) {
          history.push('/app/orders/' + res.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCanceled = async (id) => {
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn hủy?',
      onOk: async () => {
        await orderApi
          .cancelOrder(id)
          .then(() => {
            onReload();
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  };

  const handleOpenModalStatus = (record) => {
    const oderStatus = record?.order_status;
    if (
      oderStatus !== OrderStatus.pending.status &&
      oderStatus !== OrderStatus.delivered.status
    ) {
      setItem(record);
      setOpenStatus(true);
    }
  };

  const closeModalStatus = () => {
    setOpenStatus(false);
  };

  return (
    <Space direction="vertical" style={{ display: 'block' }}>
      <Row justify="space-between">
        <Col>
          <PageTitle title={<IntlMessage id="order.title" />} />
        </Col>
        <Col>
          <Space>
            <Button
              style={{ margin: 'auto' }}
              type="primary"
              onClick={() => history.push('/app/create-order')}
            >
              Thêm
            </Button>
            <Button
              style={{ margin: 'auto' }}
              type="primary"
              onClick={() => {
                setWithExpiry(LocalStorageKeys.order_list, {
                  filter: _.cloneDeep(filter),
                });
                onReload();
              }}
            >
              Làm mới
            </Button>
          </Space>
        </Col>
      </Row>
      <FilterBar
        onFilter={onFilter}
        data={[
          {
            name: 'type',
            placeholder: 'Chọn chiều đi...',
            data: [
              {
                id: OrderType.normal.status,
                title: '1 chiều',
              },
              {
                id: OrderType.block.status,
                title: '2 chiều',
              },
            ],
          },
          {
            name: 'order_status',
            placeholder: 'Chọn trạng thái....',
            data: orderStatusFilter,
          },
        ]}
        storage={getWithExpiry(LocalStorageKeys.order_list)}
      />

      <Pagination
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '8px',
        }}
        showSizeChanger={false}
        current={filter.page}
        pageSize={filter.limit}
        onChange={handleChangePage}
        total={total}
      />

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin style={{ textAlign: 'center' }} />
        </div>
      ) : (
        <Space
          style={{ marginTop: '15px', width: '100%' }}
          direction="vertical"
        >
          {items?.map((item) => (
            <Card key={item?.id} style={{ width: '100%' }}>
              <Space direction="vertical" size={4}>
                <Space size={4}>
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.order_no" />:
                    </Text>
                  </div>

                  <Text
                    style={{ color: '#3e4095', cursor: 'pointer' }}
                    onClick={() => handleDetail(item?.id)}
                  >
                    {item?.type === OrderType.normal.status
                      ? `#1c${stringPad(item?.id)}`
                      : `#2c${stringPad(item?.id)}`}
                  </Text>
                </Space>
                <Space size={2}>
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.status" />:
                    </Text>
                  </div>
                  <Button
                    type="text"
                    onClick={() => {
                      handleOpenModalStatus(item);
                    }}
                  >
                    <Status type="order" status={item.order_status} />
                  </Button>
                  <Status type="type" status={item.type} />
                  {item?.waiting && <Tag color="#f3d60b">chờ</Tag>}
                </Space>

                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Space>
                    {item.is_proxy_call && <Tag color={'#ed4f7b'}>Gọi hộ</Tag>}
                    {item.customer_new && (
                      <Tag color={'#f50'}>Khách hàng mới</Tag>
                    )}
                  </Space>
                </div>

                <Space size={2}>
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.from_date" />:
                    </Text>
                  </div>
                  <Text>
                    {convertToAnyTimezone(item?.date).format(
                      'DD-MM-YYYY HH:mm'
                    )}
                  </Text>
                </Space>
                <Space size={4} style={{ width: '100%' }} align="start">
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.driver" />:
                    </Text>
                  </div>
                  {!item.driver ? (
                    !item.invites ||
                    (item.invites && item.invites.length === 0) ? (
                      <Text>Chưa có tài xế được mời</Text>
                    ) : (
                      <Space
                        direction="vertical"
                        size={4}
                        style={{ width: '100%' }}
                      >
                        {item.invites.map((invite) => (
                          <Space key={invite.id} size={4}>
                            <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                              <Text>
                                {invite.driver.firstname}{' '}
                                {invite.driver.lastname}
                              </Text>
                            </div>
                            <Status
                              type="invite"
                              status={invite.invite_status}
                            />
                          </Space>
                        ))}
                      </Space>
                    )
                  ) : (
                    <Space size={4}>
                      <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                        <Text
                          {...(item?.order_status !==
                            OrderStatus.cancelled.status &&
                          item?.order_status !== OrderStatus.completed.status
                            ? {
                                style: { color: '#2db7f5', cursor: 'pointer' },
                                onClick: () => openChangeDriver(item),
                              }
                            : {})}
                        >
                          {item.driver.firstname} {item.driver.lastname}
                        </Text>
                      </div>
                      <Status
                        type="invite"
                        status={InviteStatus.accepted.status}
                      />
                    </Space>
                  )}
                </Space>

                {item.type === OrderType.block.status && (
                  <Space size={4}>
                    <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                      <Text strong>
                        <IntlMessage id="global.hour_group" />:
                      </Text>
                    </div>
                    <Text>{`${item.block}h`}</Text>
                  </Space>
                )}
                <Space size={4}>
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.total" />:
                    </Text>
                  </div>
                  <Text>
                    <Currency
                      value={
                        Number(item?.total) +
                        Number(item?.extra) +
                        Number(item?.night_surcharge) +
                        Number(item?.waiting_surcharge) +
                        Number(item?.event_surcharge)
                      }
                    />
                  </Text>
                </Space>
                <Space wrap={true}>
                  <Button
                    size="small"
                    disabled={
                      item.order_status === OrderStatus.completed.status ||
                      item.order_status === OrderStatus.cancelled.status
                    }
                    danger
                    onClick={() => handleCanceled(item.id)}
                  >
                    <IntlMessage id="global.cancel" />
                  </Button>

                  <Button
                    size="small"
                    type="primary"
                    danger
                    onClick={() =>
                      handleEdit(item.id, OrderStatus.cancelled.status)
                    }
                  >
                    <IntlMessage id="global.edit" />
                  </Button>

                  <Button
                    size="small"
                    type="primary"
                    disabled={item.order_status !== OrderStatus.pending.status}
                    onClick={() => onOpenSentDriver(item)}
                  >
                    <IntlMessage id="order.sent" />
                  </Button>

                  <Button size="small" onClick={() => onOpenInforOrder(item)}>
                    <IntlMessage id="global.info" />
                  </Button>

                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleOrderCopy(item?.id)}
                  >
                    <IntlMessage id="global.order_copy" />
                  </Button>
                </Space>
              </Space>
            </Card>
          ))}
          <Pagination
            style={{ display: 'flex', justifyContent: 'center' }}
            current={filter.page}
            pageSize={filter.limit}
            onChange={handleChangePage}
            total={total}
          />
        </Space>
      )}

      {openInfor && (
        <InforOrderForm
          item={item}
          open={openInfor}
          onClose={onCloseInforOrder}
          onReloadOrders={onReload}
        />
      )}
      {openSent && (
        <SentDriverForm
          item={item}
          open={openSent}
          onClose={onCloseSentDriver}
          onReloadOrders={onReload}
        />
      )}
      {changeDriver && (
        <ModalChangeDriver
          item={item}
          onReload={onReload}
          open={changeDriver}
          onClose={closeChangeDriver}
        />
      )}
      {openStatus && (
        <ModalOrderStatus
          currentData={item}
          onReload={onReload}
          open={openStatus}
          onClose={closeModalStatus}
        />
      )}
    </Space>
  );
};

export default ListOrder;
