import { Button, Checkbox, Form, Modal, Radio, Space, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import IntlMessage from '../../components/IntlMessage';
import driverApi from '../../api/driver';
import Search from 'antd/es/input/Search';
import { debounce } from 'lodash';
import Currency from '../../components/Currency';
import { balance } from '../../utils/constant';

const SentDriverForm = (props) => {
  const { open, onClose, item, onReloadOrders } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [reload, setReload] = useState(false);
  const [tabKey, setTabKey] = useState('1');

  useEffect(() => {
    async function fetchData() {
      try {
        form.resetFields();
        const filter = {
          limit: 5,
          page: 1,
          order_by: 'id',
          order_dir: 'DESC',
        };

        const drivers = await driverApi.findAllDrivesEligible(filter);
        if (drivers && Array.isArray(drivers.data)) {
          setItems(drivers.data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [item, reload]);

  const onReload = () => {
    setReload((e) => !e);
  };

  const onFinish = () => {
    setLoading(true);
    try {
      form
        .validateFields()
        .then(async (values) => {
          let data = {};
          if (Array.isArray(values?.driver_ids)) {
            data = { ...values };
          } else {
            data = {
              driver_ids: [values.driver_ids],
            };
          }

          await driverApi.createInvite(item.id, data);
          setLoading(false);
          onClose();
          onReloadOrders();
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onFinishFail = (error) => {
    console.error(error);
  };

  const onChangeTab = (key) => {
    console.log(key);
    if (key) {
      setTabKey(key);
    }
    if (key === tabs[0].key) {
      console.log('not ');
      onSearchDriver('');
      return;
    }
    onFindNearestDriver();
  };

  const onFindNearestDriver = async () => {
    try {
      const filter = {
        count: 3,
        lat: item.from_add_lat,
        lon: item.from_add_lon,
      };

      const drivers = await driverApi.findNearestDrivers(filter);
      if (drivers && Array.isArray(drivers.data)) {
        setItems(drivers.data);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSearchDriver = debounce(async (keyword) => {
    const filter = {
      limit: 5,
      page: 1,
      keyword,
      order_by: 'id',
      order_dir: 'DESC',
    };

    const res = await driverApi.findAllDrivesEligible(filter);
    if (Array.isArray(res.data)) {
      setItems(res.data);
    }
  }, 300);

  const tabs = [
    {
      key: '1',
      label: <IntlMessage id="sentDriver.tab_designate" />,
      children: (
        <Space direction="vertical">
          <Search
            placeholder="Nhập tên tài xế hoặc sdt...."
            onChange={(e) => onSearchDriver(e.target.value)}
          />
          <Form.Item
            name="driver_ids"
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.required" />,
              },
            ]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {items?.map((item) => {
                  return (
                    <Radio key={`${item.id}-${item.mobile}`} value={item.id}>
                      {`${item.lastname} ~ ${item.mobile} (`}
                      <Currency
                        style={{
                          color:
                            item?.balance > balance.minimum ? 'green' : 'red',
                        }}
                        value={item?.balance}
                      />
                      {')'}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Space>
      ),
    },
    {
      key: '2',
      label: <IntlMessage id="sentDriver.tab_drivers" />,
      children: (
        <Form.Item name="driver_ids">
          <Checkbox.Group>
            <Space direction="vertical">
              {items?.map((item, index) => {
                return (
                  <Checkbox value={item.id} key={item.mobile}>
                    {`${item.lastname} ~ ${item.mobile}`}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={[
        <Button
          key={'save'}
          type="primary"
          loading={loading}
          onClick={onFinish}
        >
          <IntlMessage id="global.save" />
        </Button>,
      ]}
    >
      <Form form={form} onFinishFailed={onFinishFail} onFinish={onFinish}>
        <Tabs
          activeKey={tabKey}
          items={tabs}
          destroyInactiveTabPane={true}
          onChange={onChangeTab}
        />
      </Form>
    </Modal>
  );
};

export default SentDriverForm;
