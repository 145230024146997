import api from '../utils/api';

class SubscribersApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/subscribers', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  
  create = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post('/subscribers', data);

        resolve(res.data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
}
const subscribersApi = new SubscribersApi();
export default subscribersApi;
