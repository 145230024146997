import React, { useEffect, useState } from 'react';
import IntlMessage from '../../components/IntlMessage';
import driverApi from '../../api/driver';
import _, { debounce } from 'lodash';
import FilterBar from '../../components/FilterBar';
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Flex,
  Image,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
  Typography,
  message,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { DriverStatus, LocalStorageKeys } from '../../utils/constant';
import { useIntl } from 'react-intl';
import {
  EditOutlined,
  EnvironmentOutlined,
  ThunderboltOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import ModalMap from './ModalMap';
import PageTitle from '../../components/PageTitle';
import ModalOrders from './ModalOrders';
import ModalTopup from './ModalTopup';
import Currency from '../../components/Currency';
import ViewQr from './ViewQr';
import ModalBan from './ModalBan';
import { getWithExpiry, setWithExpiry } from '../../utils/localStorage';

const { Text } = Typography;

const Drivers = () => {
  const storage = getWithExpiry(LocalStorageKeys.driver_list);
  const [filter, setFilter] = useState(
    storage && storage?.filter
      ? storage.filter
      : {
          limit: 9999,
          page: 1,
          keyword: '',
          order_by: 'id',
          order_dir: 'DESC',
          filter_name: ['is_active'],
          filter_value: [1],
        }
  );
  const [condition, setCondition] = useState({});
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [reload, setReload] = useState(false);
  const history = useHistory();
  const [openMap, setOpenMap] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openTopup, setOpenTopup] = useState(false);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [viewQr, setViewQr] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [modalBan, setModalBan] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await driverApi.getAll(filter);
      if (Array.isArray(res.data)) {
        setTotal(res.total);
        setItems(res.data);
      }
      setLoading(false);
    }

    fetchData();
  }, [filter, reload]);

  useEffect(() => {
    if (filter?.filter_name && filter?.filter_value) {
      const newCondition = _.zipObject(
        filter?.filter_name,
        filter?.filter_value
      );
      setCondition(newCondition);
    }
    setWithExpiry(LocalStorageKeys.driver_list, {
      filter: _.cloneDeep(filter),
    });
  }, [filter]);

  const onFilter = debounce(async (value, title, type) => {
    if (type === 'search') {
      setFilter((filter) => {
        return { ...filter, keyword: value, page: 1 };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key] === '' && (key === 'is_active' || 'user.status')) {
          filter_name.push(key);
          filter_value.push(false);
        } else if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  const handleEdit = (id) => {
    history.push('/app/edit-driver/' + id);
  };

  const handleStatus = async (id, status) => {
    try {
      const data = {
        status,
      };
      await driverApi.updateStatus(id, data);
      message.success({
        content: 'Cập nhật thành công!',
      });
      setItems((item) =>
        item.map((driver) => {
          if (driver?.id === id) {
            return {
              ...driver,
              user: {
                ...driver.user,
                status,
              },
            };
          }
          return driver;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterStatus = (value, type) => {
    let filter_name = [];
    let filter_value = [];
    console.log(condition);
    for (const key in condition) {
      if (key !== type) {
        filter_name.push(key);
        filter_value.push(condition[key]);
      }
    }
    if (value !== undefined) {
      filter_name.push(type);
      filter_value.push(value);
    }
    setFilter((filter) => {
      return {
        ...filter,
        page: 1,
        filter_name: filter_name,
        filter_value: filter_value,
      };
    });
  };

  const handleOpenMap = (record) => {
    setOpenMap(true);
    setRecord(record);
  };
  const handleClose = () => {
    setOpenMap(false);
  };
  const handleOpenTopup = (record) => {
    setOpenTopup(true);
    setRecord(record);
  };
  const handleCloseTopup = () => {
    setOpenTopup(false);
  };
  const handleOpenOrders = (record) => {
    setOpenOrders(true);
    setRecord(record);
  };
  const handleCloseOrders = () => {
    setOpenOrders(false);
  };

  const handleWorking = async (id, status, e) => {
    e.stopPropagation();
    const data = {
      status,
    };
    await driverApi
      .workingStatus(id, data)
      .then(() => {
        onReload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onOpenViewQr = (src) => {
    setQrCode(src);
    setViewQr(true);
  };

  const onCloseViewQr = () => {
    setViewQr(false);
  };

  const handleBanAndUnban = (item) => {
    if (item?.status) {
      setRecord(item);
      setModalBan(true);
    } else {
      Modal.confirm({
        title: 'Bạn có chắc chắn muốn gỡ kỷ luật!!!',
        onOk: async () => {
          await driverApi.driverUnban(item.id).then(() => {
            onReload();
          });
        },
      });
    }
  };

  const getValueFilterName = (key) => {
    if (storage?.filter?.filter_name && storage?.filter?.filter_value) {
      const filter_name = storage?.filter?.filter_name;
      const filter_value = storage?.filter?.filter_value;
      if (Array.isArray(filter_name) && Array.isArray(filter_value)) {
        if (key) {
          const index = _.findIndex(filter_name, (name) => name === key);
          if (index !== -1) {
            return filter_value[index];
          }
        }
      }
    }
    return null;
  };

  const getDataDisplay = (data) => {
    return data?.map((item) => ({
      key: item.id,
      className: 'list_item',
      label: (
        <Row gutter={8}>
          <Col>
            <Space direction="vertical">
              <Row>
                <Space size={4}>
                  <Text strong>
                    {item.lastname} ~ {item.mobile}
                  </Text>
                  {item.is_active ? (
                    <Tag
                      color="#87d068"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleWorking(item?.id, false, e)}
                    >
                      ON
                    </Tag>
                  ) : (
                    <Tag
                      color="#c4bebe"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => handleWorking(item?.id, true, e)}
                    >
                      OFF
                    </Tag>
                  )}
                  {item?.is_processing && (
                    <Tag color="yellow">
                      <ThunderboltOutlined />
                    </Tag>
                  )}
                  {!item?.status && <Tag color="red">kỷ luật</Tag>}
                </Space>
              </Row>
            </Space>
          </Col>
        </Row>
      ),
      children: (
        <Row gutter={8}>
          <Col span={6}>
            <Flex vertical={true} justify="center">
              {item.image ? (
                <Image
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '100%',
                  }}
                  src={item.image}
                />
              ) : (
                <Avatar size={60} style={{ backgroundColor: '#f56a00' }}>
                  {item.lastname.charAt(0)}
                </Avatar>
              )}
            </Flex>
          </Col>
          <Col span={18}>
            <Row gutter={[15, 15]}>
              <Col span={24}>
                <Space>
                  <Tag
                    style={{ cursor: 'pointer' }}
                    color="magenta"
                    onClick={() => handleOpenTopup(item)}
                  >
                    <Currency
                      style={{ fontSize: '12px' }}
                      value={item?.balance || 0}
                    />
                  </Tag>

                  <Tag
                    style={{ cursor: 'pointer' }}
                    color="magenta"
                    onClick={() => handleOpenMap(item)}
                  >
                    <EnvironmentOutlined />
                  </Tag>
                </Space>
              </Col>
              <Col span={24}>
                <Text style={{ width: '230px' }}>{item.address}</Text>
              </Col>
              {/* <Space direction="vertical" size={8}>
                <Col span={24}>
                  Cuốc xe gần đây: {item.current_order_status}
                </Col>
                <Col span={24} style={{ marginLeft: '15px' }}>
                  Khách hàng: {item.current_order_customer_name}
                </Col>
                <Col span={24} style={{ marginLeft: '15px' }}>
                  Điểm trả: {item.current_order_address}
                </Col>
              </Space> */}
              <Col span={24}>
                <Space>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleEdit(item.id)}
                  >
                    <EditOutlined />
                  </Button>

                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleBanAndUnban(item)}
                    danger
                  >
                    {item.status ? 'kỷ luật' : 'Gỡ kỷ luật'}
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleOpenOrders(item)}
                  >
                    <UnorderedListOutlined />
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => onOpenViewQr(item.qr_code)}
                  >
                    <IntlMessage id="global.qr" />
                  </Button>

                  <Switch
                    checkedChildren={''}
                    unCheckedChildren={''}
                    checked={item?.user?.status}
                    onChange={(ischecked) => handleStatus(item.id, ischecked)}
                  />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    }));
  };

  return (
    <Space direction="vertical">
      <Row justify="space-between">
        <Col>
          <PageTitle title={<IntlMessage id="driver.title" />} />
        </Col>
        <Col>
          <Space>
            <Button
              style={{ margin: 'auto' }}
              type="primary"
              onClick={() => history.push('/app/create-driver')}
            >
              Thêm
            </Button>
            <Button
              style={{ margin: 'auto' }}
              type="primary"
              onClick={() => {
                setWithExpiry(LocalStorageKeys.driver_list, {
                  filter: _.cloneDeep(filter),
                });
                onReload();
              }}
            >
              Làm mới
            </Button>
          </Space>
        </Col>
      </Row>

      <FilterBar
        onFilter={onFilter}
        storage={getWithExpiry(LocalStorageKeys.driver_list)}
      >
        <Space>
          <Select
            allowClear={true}
            onChange={(value) => handleFilterStatus(value, 'user.status')}
            placeholder="Chọn trạng thái.."
            defaultValue={getValueFilterName('user.status')}
          >
            <Select.Option value={1}>
              <IntlMessage id="drivers.activated" />
            </Select.Option>
            <Select.Option value={0}>
              <IntlMessage id="drivers.inactive" />
            </Select.Option>
          </Select>
          <Select
            allowClear={true}
            onChange={(value) => handleFilterStatus(value, 'is_active')}
            placeholder="Hoạt động"
            defaultValue={
              getValueFilterName('is_active')
                ? getValueFilterName('is_active')
                : 1
            }
          >
            <Select.Option value={1}>ON</Select.Option>
            <Select.Option value={0}>OFF</Select.Option>
          </Select>
        </Space>
      </FilterBar>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin style={{ textAlign: 'center' }} />
        </div>
      ) : (
        <>
          <Collapse
            items={getDataDisplay(items)}
            accordion={true}
            // onChange={onChange}
            expandIconPosition="end"
            destroyInactivePanel={true}
            ghost={true}
          ></Collapse>
          <Pagination
            style={{ display: 'flex', justifyContent: 'center' }}
            current={filter.page}
            pageSize={filter.limit}
            onChange={handleChangePage}
            total={total}
          />
        </>
      )}
      {openMap && (
        <ModalMap
          openMap={openMap}
          handleClose={handleClose}
          currentData={record}
        ></ModalMap>
      )}
      {openOrders && (
        <ModalOrders
          open={openOrders}
          handleClose={handleCloseOrders}
          currentData={record}
        ></ModalOrders>
      )}
      {openTopup && (
        <ModalTopup
          open={openTopup}
          handleClose={handleCloseTopup}
          currentData={record}
          onReload={onReload}
        ></ModalTopup>
      )}
      {modalBan && (
        <ModalBan
          open={modalBan}
          onClose={() => {
            setModalBan(false);
          }}
          item={record}
          onReload={onReload}
        ></ModalBan>
      )}
      {viewQr && <ViewQr open={viewQr} onClose={onCloseViewQr} src={qrCode} />}
    </Space>
  );
};

export default Drivers;
