import moment from 'moment';
import api from '../utils/api';

class OrderApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/orders', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  orderHistoryForDriver = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/orders/driver/' + id, { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllOrderSeat = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train/order/order-seat/list', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // create = (data) => {
  //   return new Promise((resolve, reject) => {
  //     return api
  //       .post("/orders", data)
  //       .then((res) => {
  //         resolve(res.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };

  updateStatus = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/status/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updatePayStatus = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/train/order/pay-status/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (id) => {
    return new Promise((resolve, reject) => {
      return api
        .delete(`/train/order/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/orders/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  findByDriverID = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/orders/driver/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  searchOrder = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/admin/route/search', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  createOrder = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .post('/orders', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createPDF = (id) => {
    return new Promise((resolve, reject) => {
      return api
        .get(`/train/order/pdf/${id}`, {
          responseType: 'blob', // Yêu cầu phản hồi kiểu blob (dữ liệu nhị phân)
        })
        .then((response) => {
          // Tạo một đường dẫn đến tệp dữ liệu
          const url = window.URL.createObjectURL(new Blob([response.data]));

          // Tạo một thẻ a ẩn và sử dụng nó để kích hoạt tải xuống
          const link = document.createElement('a');
          link.href = url;

          this.getDetail(id)
            .then((res) => {
              link.setAttribute(
                'download',
                `E-voucher No ${res?.order_no}${
                  res?.group_code ? `-${res.group_code}` : ''
                }.pdf`
              );
              document.body.appendChild(link);
              link.click();
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createETicket = (id) => {
    return new Promise((resolve, reject) => {
      return api
        .get(`/train/order/eticket/${id}`, {
          responseType: 'blob', // Yêu cầu phản hồi kiểu blob (dữ liệu nhị phân)
        })
        .then((response) => {
          // Tạo một đường dẫn đến tệp dữ liệu
          const url = window.URL.createObjectURL(new Blob([response.data]));

          // Tạo một thẻ a ẩn và sử dụng nó để kích hoạt tải xuống
          const link = document.createElement('a');
          link.href = url;

          this.getDetail(id)
            .then((res) => {
              link.setAttribute(
                'download',
                `e-ticket_${res?.order_no}${
                  res?.group_code ? `-${res.group_code}` : ''
                }.pdf`
              );
              document.body.appendChild(link);
              link.click();
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        })

        .catch((error) => {
          reject(error);
        });
    });
  };

  searchAddonsOrder = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train-order-items/search/addons', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  updateAddonsOrder = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch(`/train-order-items/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllBooking = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train/order/booking/list', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getOrderDeparture = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/train/order/departure/${data}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllNotPaid = (customer_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/train/order/paystatus/list/${customer_id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getOrderReturn = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/train/order/return/${data}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  exportToExcel = (id) => {
    return new Promise((resolve, reject) => {
      return api
        .get(`/train/order/export/${id}`, {
          responseType: 'blob', // Yêu cầu phản hồi kiểu blob (dữ liệu nhị phân)
        })
        .then((response) => {
          // Tạo một đường dẫn đến tệp dữ liệu
          const url = window.URL.createObjectURL(new Blob([response.data]));

          // Tạo một thẻ a ẩn và sử dụng nó để kích hoạt tải xuống
          const link = document.createElement('a');
          link.href = url;

          this.getDetail(id)
            .then((res) => {
              link.setAttribute(
                'download',
                `booking_${res?.order_no}${
                  res?.group_code ? `-${res?.group_code}` : ''
                }.xlsx`
              );
              document.body.appendChild(link);
              link.click();
              // resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        })

        .catch((error) => {
          console.error('Error while downloading file:', error);
          reject(error);
        });
    });
  };

  updateOrder = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  searchCabinsOrder = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train-order-items/search/cabins', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  cancelOrder = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/cancel/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSendEmail = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post('/train/order/sendEmail', data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  createOrderSeat = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .post('/order-seats', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAllOrderSeated = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/order-seats/order/list', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  updateOrderSeat = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch('/order-seats', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  downloadAddonsOrder = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train-order-items/download/addons', {
          params: data,
          responseType: 'blob',
        });
        // Tạo một đường dẫn đến tệp dữ liệu
        const url = window.URL.createObjectURL(new Blob([res.data]));

        // Tạo một thẻ a ẩn và sử dụng nó để kích hoạt tải xuống
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `additional-services_${moment().format('DD-MM')}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  downloadPassengers = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train-order-items/download/passengers', {
          params: data,
          responseType: 'blob',
        });
        // Tạo một đường dẫn đến tệp dữ liệu
        const url = window.URL.createObjectURL(new Blob([res.data]));

        // Tạo một thẻ a ẩn và sử dụng nó để kích hoạt tải xuống
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `passengers_${moment().format('DD-MM')}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  downloadSeatsOrder = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/order-seats/download/pdf', {
          params: data,
          responseType: 'blob',
        });
        // Tạo một đường dẫn đến tệp dữ liệu
        const url = window.URL.createObjectURL(new Blob([res.data]));

        // Tạo một thẻ a ẩn và sử dụng nó để kích hoạt tải xuống
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `booked-seats.pdf`);
        document.body.appendChild(link);
        link.click();
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getBookingStatistics = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train/order/booking/statistics', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getInvoice = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train/order/invoice/customer', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllInternalNotes = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train/order/all/internal-notes', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  downloadInvoice = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train/order/invoice/pdf', {
          params: data,
          responseType: 'blob',
        });
        // Tạo một đường dẫn đến tệp dữ liệu
        const url = window.URL.createObjectURL(new Blob([res.data]));

        // Tạo một thẻ a ẩn và sử dụng nó để kích hoạt tải xuống
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `invoice_${moment().format('DD-MM')}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getTotalAmount = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train/order/total/amount', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  checkChangeDate = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/train/order/check/change-date', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  updateVoucherStatus = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/train/order/voucher-status/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  complete = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/complete/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  waitingSurcharge = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/waiting/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  extraSurcharge = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/extra-point/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  eventSurcharge = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/event-surcharge/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  nightSurcharge = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/night-surcharge/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  surcharge = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/surcharge/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  copyOrder = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .post(`/orders/copy/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  changeDriver = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .patch(`/orders/edit-driver/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getQuantityDoing = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/orders/doing');
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getOrderStatistics = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/orders/dashboard/statistics', {
          params: payload,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  changeStatus = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/orders/change/status/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}

const orderApi = new OrderApi();
export default orderApi;
