import React, { useEffect, useRef, useState } from 'react';
import { CarOutlined, UserOutlined } from '@ant-design/icons';
import GoogleMapReact from 'google-map-react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Modal,
  Pagination,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import orderApi from '../../api/order';
import { InviteStatus, OrderStatus, OrderType } from '../../utils/constant';
import Status from '../../components/Status';
import IntlMessage from '../../components/IntlMessage';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import FilterBar from '../../components/FilterBar';
import Currency from '../../components/Currency';
import _, { debounce } from 'lodash';
import useStatus from '../../hooks/useStatus';
import InforOrderForm from '../orders/InforOrderForm';
import SentDriverForm from '../orders/SentDriverForm';
import { stringPad } from '../../helpers/stringPad';
import { convertToAnyTimezone } from '../../helpers/time';

const { Text } = Typography;
const ModalOrders = (props) => {
  const { open = false, handleClose, currentData } = props;
  const isMount = useRef(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [item, setItem] = useState({});
  const { orderStatusFilter } = useStatus();
  const [openInfor, setOpenInfor] = useState(false);
  const [reload, setReload] = useState(false);
  const [total, setTotal] = useState(0);
  const [openSent, setOpenSent] = useState(false);
  const [condition, setCondition] = useState({});
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    keyword: '',
    order_by: 'id',
    order_dir: 'DESC',
  });

  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      setLoading(true);
      const res = await orderApi.orderHistoryForDriver(currentData?.id, filter);
      const data = res.data;
      if (res) {
        setTotal(res.total);
      }
      if (data && Array.isArray(data) && data.length > 0) {
        setItems(data);
      }
      setLoading(false);
    }

    fetchData();
    return () => {
      isMount.current = false;
    };
  }, [filter, reload]);

  useEffect(() => {
    if (filter?.filter_name && filter?.filter_value) {
      const newCondition = _.zipObjectDeep(
        filter?.filter_name,
        filter?.filter_value
      );
      setCondition(newCondition);
    }
  }, [filter]);

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleDetail = (id) => {
    history.push('/app/orders/' + id);
  };

  const onFilter = debounce(async (value, title, type) => {
    if (type === 'search') {
      setFilter((filter) => {
        return { ...filter, keyword: value, page: 1 };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  const handleStatus = (id, status) => {
    Modal.confirm({
      title: 'Bạn chắc chắn muốn hủy!!!',
      onOk: async () => {
        try {
          const data = {
            order_status: status,
          };
          await orderApi.updateStatus(id, data);
          onReload();
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const onCloseSentDriver = () => {
    setOpenSent(false);
  };

  const onCloseInforOrder = () => {
    setOpenInfor(false);
  };

  const handleEdit = (id) => {
    history.push('/app/edit-order/' + id);
  };

  const onOpenSentDriver = (data) => {
    setItem(data);
    setOpenSent(true);
  };

  const onOpenInforOrder = (data) => {
    setItem(data);
    setOpenInfor(true);
  };

  if (!currentData) return null;
  return (
    <Modal
      title={`Lịch sử cuốc xe`}
      open={open}
      onOk={handleClose}
      onClose={handleClose}
      onCancel={handleClose}
    >
      {items.length > 0 ? (
        <Space direction="vertical" style={{ display: 'block' }}>
          <FilterBar
            onFilter={onFilter}
            data={[
              {
                name: 'type',
                placeholder: 'Chọn chiều đi...',
                data: [
                  {
                    id: OrderType.normal.status,
                    title: '1 chiều',
                  },
                  {
                    id: OrderType.block.status,
                    title: '2 chiều',
                  },
                ],
              },
              {
                name: 'order_status',
                placeholder: 'Chọn trạng thái....',
                data: orderStatusFilter,
              },
            ]}
          />
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin style={{ textAlign: 'center' }} />
            </div>
          ) : (
            <Space
              style={{ marginTop: '15px', width: '100%' }}
              direction="vertical"
            >
              {items?.map((item) => (
                <Card key={item?.id} style={{ width: '100%' }}>
                  <Space direction="vertical" size={4}>
                    <Space size={4} wrap>
                      <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                        <Text strong>
                          <IntlMessage id="global.order_no" />:
                        </Text>
                      </div>

                      <Text
                        style={{ color: '#3e4095' }}
                        onClick={() => handleDetail(item?.id)}
                      >
                        {item?.type === OrderType.normal.status
                          ? `#1c${stringPad(item?.id)}`
                          : `#2c${stringPad(item?.id)}`}
                      </Text>
                      <Status type="order" status={item.order_status} />
                      <Status type="type" status={item.type} />
                    </Space>
                    <div
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <Space>
                        {item.is_proxy_call && (
                          <Tag color={'#ed4f7b'}>Gọi hộ</Tag>
                        )}
                        {item.customer_new && (
                          <Tag color={'#f50'}>Khách hàng mới</Tag>
                        )}
                      </Space>
                    </div>
                    <Space size={4} style={{ width: '100%' }} align="start">
                      <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                        <Text strong>
                          <IntlMessage id="global.driver" />:
                        </Text>
                      </div>
                      {!item.driver ? (
                        !item.invites ||
                        (item.invites && item.invites.length === 0) ? (
                          <Text>Chưa có tài xế được mời</Text>
                        ) : (
                          <Space
                            direction="vertical"
                            size={4}
                            style={{ width: '100%' }}
                          >
                            {item.invites.map((invite) => (
                              <Space key={invite.id} size={4}>
                                <div
                                  style={{ flex: '0 0 auto', minWidth: '80px' }}
                                >
                                  <Text>
                                    {invite.driver.firstname}{' '}
                                    {invite.driver.lastname}
                                  </Text>
                                </div>
                                <Status
                                  type="invite"
                                  status={invite.invite_status}
                                />
                              </Space>
                            ))}
                          </Space>
                        )
                      ) : (
                        <Space size={4}>
                          <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                            <Text>
                              {item.driver.firstname} {item.driver.lastname}
                            </Text>
                          </div>
                          <Status
                            type="invite"
                            status={InviteStatus.accepted.status}
                          />
                        </Space>
                      )}
                    </Space>
                    <Space size={4}>
                      <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                        <Text strong>
                          <IntlMessage id="global.from_date" />:
                        </Text>
                      </div>
                      <Text>
                        {convertToAnyTimezone(item?.date).format(
                          'DD-MM-YYYY HH:mm'
                        )}
                      </Text>
                    </Space>
                    <Space size={4}>
                      <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                        <Text strong>
                          <IntlMessage id="global.from" />:
                        </Text>
                      </div>
                      <Text>{item?.from_add_text}</Text>
                    </Space>
                    <Space size={4}>
                      <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                        <Text strong>
                          <IntlMessage id="global.to" />:
                        </Text>
                      </div>
                      <Text>{item?.to_add_text}</Text>
                    </Space>

                    {item.type === OrderType.block.status && (
                      <Space size={4}>
                        <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                          <Text strong>
                            <IntlMessage id="global.hour_group" />:
                          </Text>
                        </div>
                        <Text>{`${item.block}h`}</Text>
                      </Space>
                    )}
                    <Space size={4}>
                      <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                        <Text strong>
                          <IntlMessage id="global.total" />:
                        </Text>
                      </div>
                      <Text>
                        <Currency
                          value={
                            Number(item.total) +
                            Number(item.extra) +
                            Number(item.night_surcharge) +
                            Number(item.waiting_surcharge)
                          }
                        />
                      </Text>
                    </Space>
                  </Space>
                </Card>
              ))}
              <Pagination
                style={{ display: 'flex', justifyContent: 'center' }}
                current={filter.page}
                pageSize={filter.limit}
                onChange={handleChangePage}
                total={total}
              />
            </Space>
          )}

          {openInfor && (
            <InforOrderForm
              item={item}
              open={openInfor}
              onClose={onCloseInforOrder}
              onReloadOrders={onReload}
            />
          )}
          {openSent && (
            <SentDriverForm
              item={item}
              open={openSent}
              onClose={onCloseSentDriver}
              onReloadOrders={onReload}
            />
          )}
        </Space>
      ) : (
        <>Rất tiếc tài xế chưa thực hiện cuốc xe nào!</>
      )}
    </Modal>
  );
};

export default ModalOrders;
