import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const Title = ({ title }) => {
  return (
    <Text strong className='title'>
      {title}
    </Text>
  );
};

export default Title;
