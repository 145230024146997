import React, { Component } from 'react';
import { Badge, Card, Row, Typography } from 'antd';
// import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default class CardWithIcon extends Component {
  render() {
    var {
      count,
      className,
      link,
      title,
      description,
      icon,
      ...rest
    } = this.props;

    return (
      <Link to={link}>
        <Card {...rest} hoverable style={{ width: '100%', height: '100%' }}>
          <Row justify="center" style={{ fontSize: 36 }}>
            {icon}
          </Row>
          <Row justify="center" style={{ textAlign: 'center' }}>
            <Typography.Title level={4}>
              {title} <Badge overflowCount={99} count={count} />
            </Typography.Title>
          </Row>
          <Row justify="center">
            <Typography.Text type="secondary">{description}</Typography.Text>
          </Row>
        </Card>
      </Link>
    );
  }
}
