import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Card, Space, Typography } from 'antd';
import {
  UserOutlined,
  ShoppingOutlined,
  PlusCircleOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  RedEnvelopeOutlined,
  WalletOutlined,
  NotificationOutlined,
  PieChartOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import CardWithIcon from '../../components/CardWithIcon';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-day-picker/lib/style.css';
import { useIntl } from 'react-intl';
import orderApi from '../../api/order';
import Currency from '../../components/Currency';
import dayjs from 'dayjs';
import { formatAsISOString } from '../../helpers/time';

export default function HomeAdmin(props) {
  const intl = useIntl();
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalDriver, setTotalDriver] = useState(0);
  const [totalMessage, setTotalMessage] = useState(0);
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await orderApi.getQuantityDoing();
      if (res) {
        setTotalDriver(res?.total_driver);
        setTotalOrder(res?.total_order);
        setTotalMessage(res?.total_message);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const payload = {
        date: formatAsISOString(dayjs()),
      };
      const res = await orderApi.getOrderStatistics(payload);
      if (res) {
        setStatistics(res);
      }
    }
    fetchData();
  }, []);

  const cards = [
    {
      icon: <PlusCircleOutlined />,
      title: intl.formatMessage({ id: 'sidebar.create_orders' }),
      description: 'Tạo cuốc xe mới',
      link: '/app/create-order',
      total: 0,
    },
    {
      icon: <ShoppingOutlined />,
      title: intl.formatMessage({ id: 'sidebar.list_order' }),
      description: 'Quản lý cuốc xe',
      link: '/app/orders',
      total: totalOrder,
    },
    {
      icon: <ContactsOutlined />,
      title: intl.formatMessage({ id: 'sidebar.drivers-manager' }),
      description: 'Quản lý tài xế',
      link: '/app/drivers-manager',
      total: totalDriver,
    },
    {
      icon: <EnvironmentOutlined />,
      title: intl.formatMessage({ id: 'sidebar.driver_map' }),
      description: 'Quản lý vị trí',
      link: '/app/driver-map',
      total: 0,
    },
    {
      icon: <WalletOutlined />,
      title: intl.formatMessage({ id: 'sidebar.topup' }),
      description: 'Lịch sử nạp',
      link: '/app/topup-manager',
      total: 0,
    },
    {
      icon: <RedEnvelopeOutlined />,
      title: intl.formatMessage({ id: 'sidebar.create_topup' }),
      description: 'Nạp tiền',
      link: '/app/topup-create',
      total: 0,
    },
    {
      icon: <UserOutlined />,
      title: intl.formatMessage({ id: 'sidebar.customer_manager' }),
      description: 'Quản lý khách',
      link: '/app/customers-manager',
      total: 0,
    },
    {
      icon: <NotificationOutlined />,
      title: intl.formatMessage({ id: 'sidebar.notification' }),
      description: 'Quản lý thông báo',
      link: '/app/notifications-manager',
      total: totalMessage,
    },
    {
      icon: <PieChartOutlined />,
      title: intl.formatMessage({ id: 'sidebar.reports' }),
      description: 'Quản lý báo cáo',
      link: '/app/reports',
      total: 0,
    },
    {
      icon: <DollarOutlined />,
      title: intl.formatMessage({ id: 'sidebar.rates' }),
      description: 'Quản lý giá cước',
      link: '/app/change-price',
      total: 0,
    },
  ];
  return (
    <>
      <Row gutter={[16, 24]}>
        <Col md={24} sm={24}>
          <Row gutter={[16, 24]}>
            {cards.map((card, index) => (
              <Col span={12} key={index}>
                <CardWithIcon
                  count={card.total}
                  link={card.link}
                  icon={card.icon}
                  title={card.title}
                  description=""
                />
              </Col>
            ))}
            <Col span={12}>
              <Card bordered>
                <Space direction="vertical">
                  <Typography.Text strong>Tổng số đơn:</Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: '20px',
                      fontWeight: 700,
                      color: '#f9ae25',
                    }}
                    strong
                  >
                    {statistics?.total_order?.toLocaleString('vi-VN')}
                  </Typography.Text>
                </Space>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ height: '100%' }} bordered>
                <Space direction="vertical">
                  <Typography.Text strong>Tổng tiền:</Typography.Text>
                  <Currency
                    style={{
                      fontSize: '20px',
                      fontWeight: 700,
                      color: '#376598',
                    }}
                    value={statistics?.total_amount}
                  />
                </Space>
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered>
                <Space direction="vertical">
                  <Typography.Text strong>Tổng tiền thu:</Typography.Text>
                  <Currency
                    style={{
                      fontSize: '20px',
                      fontWeight: 700,
                      color: '#01a060',
                    }}
                    value={statistics?.total_revenue}
                  />
                </Space>
              </Card>
            </Col>
          </Row>
        </Col>

        {/* <Col md={3} sm={0}></Col> */}
      </Row>
    </>
  );
}
