import React, { useEffect, useMemo, useState } from 'react';
import PropType from 'prop-types';
import { duration } from 'moment';
import { Button, Col, Descriptions, Modal, Row, Space, Typography } from 'antd';
import Currency from '../../components/Currency';
import { OrderType, eventTime, surchargePrice } from '../../utils/constant';
import IntlMessage from '../../components/IntlMessage';
import { stringPad } from '../../helpers/stringPad';
import dayjs from 'dayjs';

const { Text } = Typography;

const Bill = ({ currentData, open, onclose, onFinish }) => {
  return (
    <Modal
      title={'Xác nhận tiền cước và kết thúc!!'}
      open={open}
      onCancel={onclose}
      footer={[
        <Button key="cancel" onClick={onclose}>
          <IntlMessage id="global.cancel" />
        </Button>,
        <Button type="primary" key="confirmed" onClick={onFinish}>
          <IntlMessage id="global.confirm" />
        </Button>,
      ]}
    >
      <Descriptions bordered>
        <Descriptions.Item key={6} label="Mã lệnh" span={3}>
          <Text style={{ color: '#3e4095' }}>
            {currentData?.type === OrderType.normal.status
              ? `#1c${stringPad(currentData?.id)}`
              : `#2c${stringPad(currentData?.id)}`}
          </Text>
        </Descriptions.Item>

        <Descriptions.Item label="Cước phí:" span={3}>
          <Currency value={currentData?.total} />
        </Descriptions.Item>
        <Descriptions.Item label="Phụ phí:" span={3}>
          <Row gutter={15}>
            {currentData?.type === OrderType.normal.status ? (
              <>
                <Col span={24}>
                  <Space>
                    <Text>
                      <IntlMessage id="surcharge.wait" />
                    </Text>
                    <Text>
                      {Math.floor(currentData?.waiting_duration / 60 / 60)}:
                      {Math.floor(currentData?.waiting_duration / 60) % 60}:
                      {currentData?.waiting_duration % 60}
                    </Text>
                    <Text>
                      <Currency value={currentData?.waiting_surcharge} />
                    </Text>
                  </Space>
                </Col>
                {dayjs().isAfter(dayjs(eventTime.start)) &&
                dayjs().isBefore(dayjs(eventTime.end)) ? (
                  <Col span={24}>
                    <Space>
                      <Text>
                        <IntlMessage id="surcharge.lunar_new_year" />
                      </Text>
                      <Text>
                        <Currency value={currentData?.event_surcharge} />
                      </Text>
                    </Space>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Space>
                      <Text>
                        <IntlMessage id="surcharge.points" />
                      </Text>
                      <Text>{currentData?.point} điểm</Text>
                      <Text>
                        <Currency value={currentData?.extra} />
                      </Text>
                    </Space>
                  </Col>
                )}
                <Col span={24}>
                  <Space>
                    <Text>
                      <IntlMessage id="surcharge.night" />
                    </Text>
                    <Text>
                      <Currency value={currentData?.night_surcharge} />
                    </Text>
                  </Space>
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Space>
                    <Text>
                      <IntlMessage id="surcharge.overtime" />
                    </Text>
                    <Text>
                      {Math.floor(currentData?.waiting_duration / 60 / 60)}:
                      {Math.floor(currentData?.waiting_duration / 60) % 60}:
                      {currentData?.waiting_duration % 60}
                    </Text>
                    <Text>
                      <Currency value={currentData?.waiting_surcharge} />
                    </Text>
                  </Space>
                </Col>
                {dayjs().isAfter(dayjs(eventTime.start)) &&
                  dayjs().isBefore(dayjs(eventTime.end)) && (
                    <Col span={24}>
                      <Space>
                        <Text>
                          <IntlMessage id="surcharge.lunar_new_year" />
                        </Text>
                        <Text>
                          <Currency value={currentData?.event_surcharge} />
                        </Text>
                      </Space>
                    </Col>
                  )}
                <Col span={24}>
                  <Space>
                    <Text>
                      <IntlMessage id="surcharge.overnight" />
                    </Text>
                    <Text>
                      <Currency value={currentData?.night_surcharge} />
                    </Text>
                  </Space>
                </Col>
              </>
            )}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="Tổng thu:" span={3}>
          <Currency
            style={{
              color: '#11b112',
              fontWeight: 'bold',
            }}
            value={
              Number(currentData?.total) +
              Number(currentData?.extra) +
              Number(currentData?.night_surcharge) +
              Number(currentData?.waiting_surcharge) +
              Number(currentData?.event_surcharge)
            }
          />
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

Bill.propTypesv = {
  currentData: PropType.object.isRequired,
  open: PropType.bool.isRequired,
  onclose: PropType.func.isRequired,
  onFinish: PropType.func.isRequired,
};

export default Bill;
