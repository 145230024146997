import { Button, Flex, Image, Modal, Typography } from 'antd';
import React from 'react';
import IntlMessage from '../../components/IntlMessage';

const { Text } = Typography;

const ViewQr = (props) => {
  const { open, onClose, src } = props;

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={[
        <Button type="primary" key="close" onClick={onClose}>
          <IntlMessage id="global.close" />
        </Button>,
      ]}
    >
      {src ? (
        <Flex style={{ width: '100%' }} justify="center">
          <Image
            style={{
              minWidth: '50px',
              maxWidth: '250px',
              padding: '15px',
              border: '1px #c6c2c2 solid',
              borderRadius: '5px',
            }}
            src={src}
            alt="mã qr"
            preview={false}
          />
        </Flex>
      ) : (
        <Text>Chưa thêm QR</Text>
      )}
    </Modal>
  );
};

export default ViewQr;
