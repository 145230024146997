export default {
  'transaction.created_at': 'Thời gian giao dịch',
  'transaction.amount': 'Tiền giao dịch',
  'transaction.action': 'Hành động',
  'global.fullname': 'Họ và tên',
  'global.doctorlist': 'Danh sách nhân viên',
  'global.document': 'Tài liệu',
  'global.document_alert':
    'Không thể xem file khác định dạng ảnh. Hãy thử download',
  'global.count_order': 'Số lượng đơn hàng',
  'global.avatar': 'Ảnh',
  'global.address': 'Địa chỉ',
  'global.earning_pending': 'Đang chờ rút',
  'global.transaction_pending': 'Đang chờ xác nhận',
  'global.transaction_approved': 'Đã xác nhận',
  'global.transaction_declined': 'Bị từ chối',
  'global.earning_available': 'Chưa rút',
  'global.earning_paid': 'Đã rút',
  'global.count_image': 'Số lượng ảnh',
  'global.discount': 'Chiết khấu',
  'global.earned': 'Tích điểm',
  'global.setup_calendar': 'Quản lý giá',
  'global.property_title': 'Tên căn hộ',
  'global.order_number': 'Mã đơn hàng',
  'global.order_pending_confirm': 'Chờ xác nhận',
  'global.customer_info': 'Người đặt',
  'global.order_cancel_pending': 'Xử lý hủy',
  'global.detail_order': 'Chi tiết đơn hàng',
  'global.detail_customer': 'Thông tin người đặt',
  'global.phone': 'Điện thoại',
  'global.order_reject': 'Từ chối',
  'global.order_cancel': 'Bạn có chắc chắn muốn hủy?',
  'global.order_complete': 'Đã hoàn thành',
  'global.order_expire': 'Hết hạn',
  'global.order_confirm': 'Bạn có chắc chắn muốn xác nhận đơn?',
  'global.total': 'Tổng tiền',
  'global.depart': 'Ngày đến',
  'global.return_date': 'Ngày đi',
  'global.adults': 'Số người',
  'global.full_name': 'Họ tên',
  'global.created': 'Ngày tạo',
  'global.setup': 'Cài đặt',
  'global.setup_calender': 'Quản lý giá',
  'global.role': 'Chức vụ',
  'global.add': 'Thêm mới',
  'global.edit': 'Sửa',
  'global.password': 'Mật khẩu (tối thiểu 6 ký tự)',
  'global.admin': 'Quản trị viên',
  'global.staff': 'Nhân viên',
  'global.gender': 'Giới tính',
  'global.date_of_bird': 'Ngày sinh',
  'global.identity': 'CMT/CCCD',
  'global.nam': 'Nam',
  'global.bills': 'Thu chi',
  'global.nu': 'Nữ',
  'global.ngay_cap': 'Ngày cấp',
  'global.noi_cap': 'Nơi cấp',
  'global.so_bhyt': 'Số BHYT',
  'widgets.recentNotifications': 'Thông báo gần đây',
  'button.viewAll': 'Xem tất cả',
  'widgets.recentMessage': 'Tin nhắn gần đây',
  'global.user': 'Người dùng',
  'global.status': 'Trạng thái',
  'global.status_working': 'Trạng thái làm việc',
  'global.add_new': 'Thêm mới',
  'global.published': 'Hiển thị',
  'global.unpublished': 'Ẩn',
  'global.delete': 'Xóa',
  'global.delete_confirm': 'Bạn có chắc chắn muốn xóa bản ghi này không?',
  'global.create': 'Tạo',
  'global.update': 'Cập nhật',
  'global.image_qr': 'Mã QR',
  'global.image': 'Ảnh',
  'global.title': 'Tiêu đề',
  'global.company': 'Công ty',
  'global.id': 'ID',
  'global.tabbasic': 'Thông tin cơ bản',
  'global.alias': 'Bí danh',
  'global.desc': 'Mô tả',
  'global.cancel': 'Hủy',
  'global.confirmed': 'Đã chốt',
  'global.confirm': 'Xác nhận',
  'global.active': 'Hoạt động',
  'global.inactive': 'Không hoạt động',
  'global.deactivate': 'Hủy kích hoạt',
  'global.submit': 'Gửi',
  'global.trashed': 'Đã xóa',
  'global.approved': 'Phê duyệt',
  'global.from': 'Điểm đón(A)',
  'global.to': 'Điểm trả(B)',
  'global.price': 'Giá',
  'global.car': 'Xe hơi',
  'global.type': 'Loại',
  'global.cancel_policy': 'Chính sách',
  'global.instant_bookable': 'Có thể đặt ngay',
  'global.yes': 'Có',
  'global.no': 'Không',
  'global.city': 'Thành phố',
  'global.street': 'Đường phố',
  'global.location': 'Vị trí',
  'global.choose-location': 'Chọn vị trí',
  'global.square_feet': 'Diện tích',
  'global.cover_img': 'Ảnh bìa',
  'global.gallery': 'Ảnh chỗ nghỉ',
  'global.security_deposit': 'Tiền đặt cọc',
  'global.minimum_nights': 'Số đêm tối thiểu',
  'global.cleaning_fee': 'Phí dọn dẹp',
  'global.maximum_nights': 'Số đêm tối đa',
  'global.months': 'Tháng',
  'global.ward': 'Xã/Phường',
  'global.nearbyUtils': 'Tiện ích xung quanh',
  'global.back': 'Quay lại',
  'global.next': 'Tiếp theo',
  'global.required': 'Bắt buộc',
  'global.default_option': 'Chọn một option',
  'global.province': 'Tỉnh/Thành phố',
  'global.district': 'Quận/Huyện',
  'global.no_house': 'Số nhà',
  'global.extra_price': 'Giá tăng thêm',
  'global.accommodation': 'Chỗ nghỉ',
  'global.dashboard': 'Trang chủ',
  'global.reservations': 'Đặt chỗ',
  'global.chat': 'Tin nhắn',
  'global.conversation': 'Hội thoại',
  'global.calendar': 'Lịch đặt chỗ',
  'global.earning': 'Doanh thu',
  'global.firstname': 'Tên',
  'global.lastname': 'Họ và đệm',
  'global.email': 'Email',
  'global.detail_property': 'Thông tin căn hộ',
  'global.mobile': 'Điện thoại',
  'global.birthday': 'Ngày sinh',
  'global.id_name': 'Họ tên đầy đủ',
  'global.id_number': 'Số CMND/Căn cước',
  'global.id_create': 'Ngày cấp',
  'global.id_image': 'Ảnh CMND/Căn cước',
  'global.property': 'Chỗ nghỉ',
  'global.create_bank': 'Thêm tài khoản ngân hàng',
  'global.name_bank': 'Ngân hàng',
  'global.branch': 'Chi nhánh',
  'global.account_name': 'Tên tài khoản',
  'global.account_number': 'Số tài khoản',
  'global.notes': 'Ghi chú',
  'global.note': 'Ghi chú',
  'global.note_by': 'Ghi chú bởi',
  'global.note_placeholder': 'Thêm ghi chú...',
  'global.day': 'Ngày',
  'global.last_30_day': '30 ngày gần nhất',
  'global.last_7_day': '7 ngày gần nhất',
  'global.last_month': 'Tháng trước',
  'global.this_month': 'Tháng này',
  'global.completed': 'Hoàn thành',
  'global.cancelled': 'Đã hủy',
  'global.assigned': 'Đã giao',
  'global.pickup_point': 'Đến điểm đón',
  'global.picked': 'Đã đón',
  'global.delivered': 'Đến điểm trả',
  'global.block': '2 chiều',
  'global.normal': '1 chiều',
  'global.more': 'more',

  'global.service_orgin': 'Xuất xứ',
  'global.service_clientCode': 'Mã Khách Hàng',

  'global.account_information': ' Thông tin tài khoản',
  'global.signout': 'Đăng xuất',
  'global.email_required': 'Email Required',
  'global.email_not_valid': 'Email not valid',

  'global.action': 'Hành động',
  'global.preview': 'Xem trước',
  'global.selected_items': 'Đang chọn {count} bản ghi',
  'global.ownership_document': 'Tài liệu',

  'sidebar.amenity': 'Lễ nghi',
  'sidebar.bills': 'Thu chi',
  'sidebar.services': 'Dịch vụ',
  'sidebar.doctors': 'Nhân viên',
  'sidebar.inbox': 'Tin nhắn',
  'sidebar.conversation': 'Cuộc hội thoại',
  'sidebar.reservations': 'Đặt chỗ',
  'sidebar.earning': 'Doanh thu',
  'sidebar.calendar': 'Lịch',
  'sidebar.promotion': 'Khuyến mại',
  'sidebar.promotionDetail': 'Chi tiết khuyến mại',
  'sidebar.reports': 'Báo cáo',
  'sidebar.create_orders': 'Tạo Cuốc xe',
  'sidebar.list_order': 'Cuốc xe',
  'sidebar.drivers-manager': 'Tài xế',
  'sidebar.order_follow': 'Đang thực hiện',
  'sidebar.create_driver': 'Tạo tài xế',
  'sidebar.topup': 'Lịch sử nạp',
  'sidebar.create_topup': 'Nạp / Trừ',
  'sidebar.customer_manager': 'Khách hàng',
  'sidebar.driver_map': 'Map',
  'sidebar.order_history': 'Lịch sử cuốc',
  'sidebar.notification': 'Thông báo',
  'sidebar.driver_report': 'Thống kê',
  'sidebar.fluctuations': 'Tài chính',
  'sidebar.rates': 'Giá cước',

  'global.working_day_price': 'Trong tuần',
  'global.weekend_price': 'Cuối tuần',
  'global.description': 'Mô tả',
  'global.save': 'Lưu',
  'global.actions': 'Hành động',

  'doctor.list': 'Danh sách bác sĩ và nhân viên',
  'promotion.start_date': 'Áp dụng từ',
  'promotion.end_date': 'Đến',
  'promotion.start_buy_date': 'Khi mua từ',
  'promotion.end_buy_date': 'Đến',
  'promotion.style': 'Kiểu',
  'promotion.amount': 'Giá trị',
  'promotion.information': 'Thông tin chung',
  'promotion.items': 'Danh sách items',
  'promotion.choose_product': 'Chọn loại',
  'promotion.choose_item': 'Chọn item',
  'global.transport': 'Di chuyển',
  'promotion.percent': 'Phần trăm',
  'promotion.fixed_number': 'Cố định',
  'promotion.normal': 'Thông thường',
  'promotion.addition_of_admin': 'Thêm bởi admin',
  'promotion.edit': 'Sửa khuyến mãi',
  'promotion.create': 'Thêm khuyến mãi',
  'global.male': 'Nam',
  'global.female': 'Nữ',
  'global.date': 'Ngày',
  'global.hour': 'Giờ',
  'global.free': 'Miễn phí',
  'global.detail': 'Chi tiết',
  'route.duration': 'Thời gian dự kiến',
  'route.free_waiting_time': 'Thời gian miễn phí chờ',
  'route.cancel_hour_policy': 'Huỷ miễn phí nếu huỷ ... trước giờ đón',
  'global.route_add': 'Tạo tuyến đường',
  'global.select_customer': 'Chọn một công ty',
  'global.select_vehicle': 'Chọn phương tiện',
  'global.input_title': 'Nhập tiêu đề',
  'global.select_airport': 'Chọn sân bay',
  'global.select_city': 'Chọn tỉnh/thành phố',
  'global.select_district': 'Chọn quận/huyện',
  'global.type_desc': 'Nhập mô tả',
  'route.success': 'Thành công',
  'route.create_success':
    'Tạo tuyến đường thành công. Bạn có muốn tiếp tục tạo tuyến đường khác?',
  'route.continue': 'Tiếp tục tạo',
  'route.goto_list': 'Quay lại danh sách',
  'global.route_detail': 'Chi tiết tuyến đường',
  'global.min': 'Phút',
  'global.terms&Conditions': 'Điều khoản và điều kiện',
  'global.$': 'VNĐ',
  'global.airport': 'Sân bay',
  'global.patients': 'Bệnh nhân',
  'global.setting': 'Cài đặt',
  'global.appointments': 'Lịch hẹn',
  'global.suppliers': 'Nhà cung cấp',
  'global.supplier': 'Nhà cung cấp',
  'global.code': 'Mã số',
  'global.clinic': 'Phòng khám',
  'global.website': 'Website',
  'global.contact_name': 'Tên liên hệ',
  'global.logo': 'Ảnh đại diện',

  'global.doctor': 'Bác sĩ',
  'global.service': 'Dịch vụ',
  'global.amount': 'Phí',
  'global.services': 'Dịch vụ',
  'global.datetime': 'Ngày giờ',
  'global.treatment': 'Thủ thuật',
  'global.select_service': 'Chọn dịch vụ',
  'global.select_doctor': 'Chọn bác sĩ',
  'global.assistant': 'Chọn trợ lý',
  'global.treated_at': 'Thời gian',
  'global.warranty': 'Bảo hành',
  'global.name': 'Tên',
  'global.signin': 'Đăng nhập',
  'global.registration_header': 'Đăng ký tài khoản',
  'signup.send_activation_success': 'Gửi email kích hoạt thành công',
  'signup.activation_guide':
    'Bạn hãy kiểm tra hòm thư hoặc trong mục thư rác của bạn và làm theo hướng dẫn để kích hoạt tài khoản',
  'signup.back_to_login': 'Quay lại trang đăng nhập',
  'global.signup': 'Đăng ký',
  'global.is_warrantable': 'Có bảo hành',
  'global.supplies_price': 'Giá vật tư',
  'global.forgot_password': 'Quên mật khẩu',
  'global.emai_required': 'Yêu cầu điền email',
  'global.password_required': 'Yêu cầu điền mật khẩu',
  'global.emai_not_valid': 'Email không đúng định dạng',
  'global.confirm_password': 'Xác nhận mật khẩu',
  'global.changepassword': 'Đổi mật khẩu',
  'global.confirm_new_password': 'Xác nhận mật khẩu mới',
  'global.new_password': 'Mật khẩu mới',
  'global.current_password': 'Mật khẩu hiện tại',
  'global.password_not_match': 'Mật khẩu không khớp',
  'global.password_min_charactor': 'Độ dài mật khẩu tối thiểu 6 ký tự',
  'global.continue': 'Tiếp tục',
  'global.country': 'Quốc gia',
  'global.clinic_name': 'Tên phòng khám',
  'global.email_reset_password': 'Điền email của bạn',
  'global.recover_password': 'Lấy lại mật khẩu',
  'global.search_placeholder': 'Gõ để tìm kiếm',
  'global.requiredfield': 'Bắt buộc điền dữ liệu',
  'global.deleted_success': 'Xóa dữ liệu thành công',
  'global.accept_agreement_warning':
    'Bạn phải đồng ý với các điều khoản để tiếp tục',
  'global.language': 'Ngôn ngữ',
  'global.currency': 'Tiền tệ',
  'global.save_success': 'Lưu thành công',
  'global.save_failed': 'Có lỗi xảy ra',
  'global.save_failed_password': 'Mật khẩu hiện tại không chính xác',
  'global.payment': 'Thanh toán',
  'global.orders': 'Đặt lái xe',
  'global.patient': 'Bệnh nhân',
  'global.timezone': 'Múi giờ',
  'appointment.scheduled_date': 'Thời gian hẹn',
  'appointment.create': 'Tạo lịch hẹn',
  'appointment.status_confirmed': 'Xác nhận',
  'appointment.status_checkedin': 'Đã đến',
  'appointment.status_noshow': 'Không đến',
  'appointment.status_cancelled': 'Đã hủy',
  'global.payment_method': 'Phương thức thanh toán',
  'global.method': 'Phương thức',
  'global.cash': 'Tiền mặt',
  'global.card': 'Thẻ',
  'global.bank': 'Chuyển khoản',
  'global.insurance': 'Bảo hiểm',
  'bill.billed_date': 'Ngày',
  'bill.serial': 'Mã',
  'bill.amount': 'Số tiền',
  'bill.total_amount': 'Tổng số tiền: ',
  'bill.total_cases': 'Tổng số ca: ',
  'global.data_initialization': 'Chúng tôi đang khởi tạo dữ liệu cho bạn !',

  'global.unit': 'Đơn vị',
  'unit.item': 'Chiếc',
  'unit.teeth': 'Răng',
  'unit.tooth': 'Hàm',
  'unit.time': 'Lần',
  'global.upgrade': 'Nâng cấp',
  'global.your_plan': 'Loại tài khoản',
  'global.trial_end': 'Ngày hết hạn',
  'global.license': 'Giấy phép',
  'global.telephone': 'Điện thoại',
  'clinic.alias': 'Tên viết tắt',
  'global.duration': 'Thời gian làm',
  'global.select_patient': 'Chọn bệnh nhân',
  'global.select_supplier': 'Chọn nhà cung cấp',
  'order.delivery_at': 'Ngày giao',
  'order.sample_at': 'Ngày nhận mẫu',
  'order.ordered_at': 'Ngày đặt',
  'global.qty': 'Số lượng',
  'global.total_qty': 'Tổng số lượng:',
  'global.story': 'Tiền sử bệnh',
  'global.field_bloodpressure': 'Huyết áp',
  'global.field_bpm': 'Nhịp tim',
  'global.field_spo2': 'Spo2',
  'global.field_height': 'Cao',
  'global.field_weight': 'Cân nặng',
  'global.add_supplier': 'Thêm nhà cung cấp',
  'global.paid': 'Đã TT',
  'global.notpaid': 'Chưa TT',
  'global.category': 'Nhóm',
  'global.select_category': 'Chọn nhóm',
  'group.xray': 'X Quang',
  'group.whitening': 'Làm trắng',
  'group.implant': 'Implant',
  'group.hygiene': 'Vệ sinh',
  'group.rootcanal': 'Điều trị tủy',
  'group.surgery': 'Phẫu thuật',
  'group.prosthetic': 'Răng giả',
  'group.consultation': 'Khám và tư vấn',

  'global.categories': 'Thể loại',
  'global.propertytypes': 'Khách sạn',

  'global.home': 'Trang chủ',
  'global.products': 'Sản phẩm',
  'global.loyaltys': 'Quản lý tích điểm',
  'global.loyaltyprograms': 'Chương trình',
  'global.loyaltycards': 'Thành viên',
  'global.loyaltytransactions': 'Lịch sử tích điểm',
  'global.loyaltyhistories': 'Lịch sử đổi quà',
  'global.loyaltyserials': 'Mã tem QR',
  'global.loyaltylandingpages': 'Landing pages',
  'global.serial': 'Mã seri',
  'global.updated': 'Ngày sửa',
  'global.refresh': 'Làm mới',
  'global.basic': 'Thông tin cơ bản',
  'global.Productpoints': 'Cấu hình tích điểm theo sản phẩm',
  'global.giftpoints': 'Cấu hình tích điểm đổi quà',
  'global.loyaltytiers': 'Cấu hình điểm xếp hạng người dùng',
  'global.policy': 'Chính sách',
  'global.random': 'Ngẫu nhiên',
  'order.productName': 'Tên sản phẩm',
  'global.point': 'Điểm',
  'global.addnew': 'Thêm mới',
  'global.customerId': 'Số điện thoại',
  'global.total_active': 'Tổng điểm chưa sử dụng',
  'global.total_active_serial': 'Tổng số mã chưa sử dụng',
  'global.total_used': 'Tổng điểm đã sử dụng',
  'global.redeened': 'Đổi quà',
  'global.select_program': 'Chọn chương trình',
  'global.select_product': 'Chọn sản phẩm',
  'global.refid': 'Mã tem QR',
  'global.current_point': 'Số điểm hiện tại',
  'global.voucher': 'Mã giảm giá',
  'global.gift': 'Quà',
  'global.pending': 'Đang chờ',
  'global.shipped': 'Đã giao hàng',
  'global.import_excel': 'Nhập mã tem sản phẩm',
  'global.export_excel': 'Tải về mã tem sản phẩm',
  'global.export_qrcode': 'Tải về mã tem QR',
  'global.generate': 'Tạo nhiều',
  'global.product': 'Sản phẩm',
  'global.used': 'Đã sử dụng',
  'global.quantity': 'Số lượng',
  'global.character': 'Số ký tự mã (6->8)',
  'global.import': 'Chọn file',
  'global.link': 'Đường dẫn trang web',
  'global.cover_image': 'Ảnh bìa',
  'global.color': 'Màu chủ đạo',
  'global.total_member': 'Số thành viên',
  'global.total_transaction': 'Số giao dịch',
  'global.total_earned': 'Số lần tích điểm',
  'global.total_redeened': 'Số lần đổi điểm',
  'global.short_description': 'Mô tả ngắn',
  'global.addredeem': 'Đổi quà',
  'global.pointsNeeded': 'Điểm xếp hạng',
  'global.comfirm_policy':
    'Khi bạn tiếp tục sử dụng có nghĩa là bạn đã đồng ý với',
  'global.comfirm_policy_link': 'các điều khoản và điều kiện của Pointhub',
  'global.total_serial': 'Số mã tem QR',
  'global.Manage_products': 'Quản lý sản phẩm',
  'global.Manage_programs': 'Quản lý chương trình',
  'global.Manage_members': 'Quản lý thành viên',
  'global.Manage_transactions': 'Quản lý lịch sử tích điểm',
  'global.Manage_serials': 'Quản lý mã tem QR',
  'global.Manage_landingpage': 'Quản lý Landing pages',

  'global.create_products': 'Tạo sản phẩm',
  'global.create_programs': 'Tạo chương trình',
  'global.create_serials': 'Tạo mã tem QR',
  'global.create_landingpage': 'Tạo Landing pages',
  'global.create_earned': 'Tích điểm và đổi quà',
  'global.footer': 'Thông tin công ty',
  'global.redeem_description': 'Thông tin quy trình đổi quà',
  'global.min_phone': 'Số điện thoại phải là 9 số hoặc 10',
  'global.check_phone': 'Số điện thoại đã tồn tại',
  'global.send_Otp': 'Mã xác thực đã được gửi đến số (+84): ',
  'global.mobile_enter': 'Nhập số điện thoại để tiếp tục',
  'global.trial': 'Trial',
  'global.startup': 'Startup',
  'global.business': 'Business',
  'global.name_city': 'Tên công ty',
  'global.history': 'Lịch sử',
  'global.load_more': 'Gửi lại mã',
  'global.send_email': 'Gửi đến email của bạn',
  'global.max_serial': 'Số Mã đổi quà',
  'global.close': 'Đóng',
  'global.qr': 'QR',
  'global.serial_err':
    'Số mã của bạn không đủ để thực hiện, vui lòng liên hệ tổng đài để tiếp tục sử dụng!',
  'global.redeem_description_point': 'quy điểm đổi quà',
  'global.EARNED': 'Cộng điểm',
  'global.REDEENED': 'Trừ điểm',
  'global.seri': 'Số seri',
  'global.serial_program': 'Mã chương trình',
  'global.serial_card': 'Mã thành viên',
  'global.serial_landingpages': 'Mã landing pages',
  'global.configuration': 'Cấu hình',
  'global.configuration_point': 'Cấu hình tích điểm sản phẩm',
  'global.title_giftpint': 'Tên món quà',
  'global.title_program': 'Tên chương trình',
  'global.customer_name': 'Tên khách',
  'global.customer_mobile': 'Điện thoại',
  'global.customer_car_number': 'Biển số xe',
  'global.start_point': 'Điểm đi',
  'global.end_point': 'Điểm đến',
  'global.from_date': 'Ngày đi',
  'global.finish_day': 'Ngày hoàn thành',
  'global.process': 'Quá trình',
  'global.order_no': 'Mã lệnh',
  'global.hour_group': 'Block',
  'global.driver': 'Tài xế',
  'global.accepted': 'Đã chấp thuận',
  'global.rejected': 'Từ chối',
  'global.ok': 'xong',
  'global.success': 'Thành công',
  'global.info': 'Info',
  'global.order_copy': 'Tạo lệnh',
  'global.balance_statistic': 'Thống kê',

  // system
  'global.systems': 'Hệ thống',
  'global.users': 'Người dùng',
  'global.driver_note': 'Ghi chú của tài xế',

  // booking
  'order.title': 'Cuốc xe',
  'order.create_order': 'Tạo lệnh',
  'order.update_order': 'Cập nhật',
  'order.nominate': 'Chỉ định',
  'order.select_driver': 'Chọn tài xế',
  'order.driver': 'Tài xế',
  'order.info_customer': 'Thông tin khách hàng',
  'order.info_address': 'Lộ trình',
  'order.input_customer_name': 'Nhập tên khách hàng',
  'order.input_total': 'Nhập giá',
  'order.book': 'Gửi đến lái xe',
  'order.dinvite': 'Số tài xế',
  'order.type': 'Chiều đi',
  'order.hour': 'Khung giờ',
  'order.sent': 'Gửi tài xế',
  'order.confirm': 'Xác nhận',
  'order.normal': '1 chiều',
  'order.block': '2 chiều',
  'order.distance': 'Khoảng cách',
  'order.is_new_customer': 'Khách hàng mới',
  'order.customer_car_number': 'Biển số xe',
  'order.customer': 'Khách hàng',

  //orders history
  'history.title': 'lịch sử đơn hàng',

  // sent driver
  'sentDriver.tab_drivers': 'Tài xế',
  'sentDriver.tab_designate': 'Chỉ định',

  // Driver
  'driver.title': 'Tài xế',
  'drivers.create_title': 'Tạo tài khoản tài xế',
  'drivers.edit_title': 'Sửa thông tin tài xế',
  'drivers.activated': 'Đã kích hoạt',
  'drivers.inactive': 'Chưa kích hoạt',
  'drivers.active': 'Kích hoạt',
  'drivers.lock': 'Khóa tài khoản',
  'driver.info': 'Thông tin',
  'driver.account': 'Tài khoản',
  'driver.first_name': 'Họ',
  'driver.last_name': 'Tên',
  'driver.account_nb': 'Số tài khoản',
  'driver.bank_name': 'Tên ngân hàng',
  'driver.name': 'Tên tài xế',

  // receive order
  'receive.order': 'Thông tin cuốc xe',
  'receive.approve': 'Chấp nhận',
  'receive.reject': 'Từ chối',

  // order follow
  'follow.overnight': 'Qua đêm',
  'follow.overtime': 'Thêm giờ',
  'follow.cancel': 'Hủy chuyến',
  'follow.completed': 'Kết thúc (tính tiền)',
  'follow.cancel_reason': 'Lý do hủy chuyến',
  'follow.confirm': 'Chốt khách',
  'follow.pickup_point': 'Đã đến điểm đón',
  'follow.night_surcharge': 'Phụ phí đêm',
  'follow.extra_points': 'Thêm điểm',
  'follow.lunar_new_year': 'Phí tết',
  'follow.wait': 'Chờ đợi',
  'follow.start': 'Xuất phát',
  'follow.finish_the_ride': 'Kết thúc cuốc xe',
  'follow.no_data': 'Không có cuốc xe',
  'follow.stop': 'Dừng',
  'follow.points_title': 'Phụ phí thêm điểm',
  'follow.night_title': 'Phụ phí đêm',
  'follow.points': 'Số điểm',
  'follow.select_region': 'Chọn vùng',
  'follow.order_title': 'Thông tin cuốc xe',
  'follow.sub_total': 'Cước phí',
  //title
  'title.assigned': 'Lệnh phục vụ',
  'title.pickup_point': 'Cuốc xe đang thực hiện',
  'title.picked': 'Cuốc xe đang thực hiện',
  'title.confirmed': 'Lệnh phục vụ',
  'title.delivered': 'Tính tiền cuốc xe',

  // customer
  'customers.title': 'Khách hàng',
  'customers.create_title': 'Thêm khách hàng',
  'customers.edit_title': 'Sửa khách hàng',
  'customers.add_car_number': 'Thêm biển số',
  'customers.car_number': 'Biển số',
  'customers.firstname': 'Tên khách',
  'customers.new': 'Khách hàng mới',

  // topup
  'topup.title': 'Lịch sử nạp',
  'topup.add': 'Nạp',
  'topup.deduction': 'Trừ',
  'topup.create_title': 'Nạp / Trừ',
  'topup.edit_title': 'Sửa số tiền',
  'topup.deposit_amount': 'số tiền nạp',
  'topup.driver': 'Tài xế',
  'topup.amount': 'Số tiền',
  'topup.note': 'Ghi chú',

  // qr topup
  'qr.title': 'Mã nạp tiền',

  // surcharge
  'surcharge.title': 'Phụ phí: ',
  'surcharge.wait': 'Phụ phí chờ: ',
  'surcharge.night': 'Phụ phí đêm: ',
  'surcharge.overnight': 'Phí hậu cần (nếu có) qua đêm: ',
  'surcharge.overtime': 'Phụ phí thêm giờ: ',
  'surcharge.points': 'Phụ phí thêm điểm: ',
  'surcharge.lunar_new_year': 'Phụ phí tết: ',
  'surcharge.block': 'Phát sinh thêm giờ: ',
  'surcharge.innerCity': ' + Nội thành: ',
  'surcharge.suburb': ' + Ngoại thành: ',
  'surcharge.province': ' + Đi tỉnh: ',

  // notification
  'notification.title': 'Thông báo',
  'notification.manager': 'Quản lý',
  'notification.order_status': 'Trạng thái đơn',
  'notification.readAll': 'Đánh dấu đã đọc',
  'notification.send': 'Gửi',
  'notification.create_title': 'Tạo thông báo',
  'notification.post': 'Tình huống',

  //post
  'post.title': 'Tình huống',
  'post.create_title': 'Tạo tình huống',
  'post.edit_title': 'Sửa tình huống',

  // fluctuations
  'fluctuations.title': 'Biến động số dư',

  // report
  'report.title': 'Thống kê',
  'report.title_order': 'Cuốc xe',
  'report.title_driver': 'Xếp hạng',
  'report.title_driver_new': 'Tài xế mới',
  'report.title_fund': 'Quỹ 10k',

  // fund
  'fund.create_title': 'Nạp tiền',
  'fund.edit_title': 'Sửa số tiền',
};
