import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

const CustomerPlacesAutoComplete = (props) => {
  const { address, onChange } = props;
  const [fromAddress, setFromAddress] = useState('');

  useEffect(() => {
    if (address) {
      setFromAddress(address);
    } else {
      setFromAddress('');
    }
  }, [address]);

  const handleChangeFrom = (address) => {
    setFromAddress(address);
    // console.log('ok');
    if (address && address.trim().length > 5) {
      // console.log('ok1');
      if (!address || (address && address.trim().length < 1)) {
        onChange(null, address);
      }
    }
  };

  const handleSelectFrom = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        // Các thành phần cần loại bỏ ở cuối địa chỉ
        const componentsToRemove = [
          ', Vietnam',
          ', Hanoi',
          ', Hanoi, Vietnam',
          ', Hà Nội, Việt Nam',
          ', Hà Nội',
          ', Việt Nam',
        ];

        // Tạo biểu thức chính quy từ các thành phần cần loại bỏ
        const regexPattern = new RegExp(
          ',?\\s*(' + componentsToRemove.join('|') + ')$',
          'i'
        );

        // Loại bỏ các thành phần khớp với biểu thức chính quy
        const address_text = address.replace(regexPattern, '');

        setFromAddress(address_text);
        var lng = results[0].geometry.location.lng();
        var lat = results[0].geometry.location.lat();
        onChange({ lat, lng }, address_text);
      })

      .catch((error) => console.error('Error', error));
  };

  return (
    <>
      <PlacesAutocomplete
        value={fromAddress}
        onChange={handleChangeFrom}
        onSelect={handleSelectFrom}
        shouldFetchSuggestions={fromAddress.trim().length > 5}
        searchOptions={{
          componentRestrictions: { country: 'VN' },
        }}
        debounce={500}
        highlightFirstSuggestion
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input allowClear
              {...getInputProps({
                placeholder: 'Nhập địa chỉ...',
                className: 'location-search-input',
                minLength: 5,
                min: 5,
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}

              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    key={suggestion.description}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default CustomerPlacesAutoComplete;
