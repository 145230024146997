import React, { useEffect, useState } from 'react';
import IntlMessage from '../../components/IntlMessage';
import FilterBar from '../../components/FilterBar';
import {
  Button,
  Col,
  Collapse,
  Modal,
  Pagination,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import _, { debounce } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import driverApi from '../../api/driver';
import PageTitle from '../../components/PageTitle';
import topUpApi from '../../api/topup';
import Currency from '../../components/Currency';
import dayjs from 'dayjs';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { formatAsISOString } from '../../helpers/time';

const { Text, Paragraph } = Typography;

const TopUpManager = () => {
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    keyword: '',
    order_by: 'id',
    order_dir: 'DESC',
  });
  const [condition, setCondition] = useState({});
  const [items, setItems] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [total, setTotal] = useState(0);
  const [reload, setReload] = useState(false);
  const history = useHistory();
  const [totalTopup, setTotalTopup] = useState({
    total_amount: 0,
    month: '',
  });

  useEffect(() => {
    async function fetchData() {
      const res = await topUpApi.getAll(filter);
      const topup = await topUpApi.getTotalTopupOfMonth({
        date_from: formatAsISOString(dayjs().startOf('month')),
        date_to: formatAsISOString(dayjs().endOf('month')),
      });
      if (Array.isArray(res.data)) {
        setTotal(res.total);
        setItems(res.data);
      }
      if (topup) {
        setTotalTopup(topup);
      }
    }

    fetchData();
  }, [filter, reload]);

  useEffect(async () => {
    const filter_driver = {
      ...filter,
      limit: 999,
    };
    const driverData = await driverApi.getAll(filter_driver);
    if (driverData?.data && Array.isArray(driverData?.data)) {
      setDrivers(
        driverData.data.map((item) => ({
          id: item?.id,
          title: item?.lastname,
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (filter?.filter_name && filter?.filter_value) {
      const newCondition = _.zipObjectDeep(
        filter?.filter_name,
        filter?.filter_value
      );
      setCondition(newCondition);
    }
  }, [filter]);

  const onFilter = debounce(async (value, title, type) => {
    if (type === 'search') {
      setFilter((filter) => {
        return { ...filter, keyword: value, page: 1 };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  const onEdit = async (id) => {
    history.push('/app/topup-edit/' + id);
  };

  const onRemove = async (id) => {
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa?',
      onOk: async () => {
        await topUpApi
          .remove(id)
          .then(() => {
            message.success('Thành công!!');
            onReload();
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  };

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  const handleAdd = (id) => {
    history.push('/app/topup-create');
  };

  const getDataDisplay = (data) => {
    return data?.map((item) => ({
      key: item.id,
      className: 'list_item',
      showArrow: true,
      label: (
        <Space direction="vertical">
          <Row>
            <Text strong>
              Số tiền:{' '}
              <Currency style={{ fontSize: '16px' }} value={item?.amount} />
            </Text>
          </Row>

          <Row>
            <Space>
              <Text>Tài xế: {item?.driver?.lastname}</Text>

              <Text> ~ sdt: {item?.driver?.mobile}</Text>
            </Space>
          </Row>
          <Row>
            <Space direction="vertical">
              <Text style={{ display: 'block' }}>
                Ngày: {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
              </Text>
            </Space>
          </Row>
          <Row>
            <Space>
              <Text>Note:</Text>
              <Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: <IntlMessage id="global.more" />,
                }}
                style={{ margin: '0px' }}
              >
                {item?.note?.split('\n')?.map((note, i) => (
                  <React.Fragment key={i}>
                    {note}
                    <br />
                  </React.Fragment>
                ))}
              </Paragraph>
            </Space>
          </Row>
        </Space>
      ),
      children: (
        <Row>
          <Space>
            <Button
              type="primary"
              danger
              size="small"
              onClick={() => onEdit(item?.id)}
            >
              <EditOutlined />
            </Button>
            <Button
              type="primary"
              danger
              size="small"
              onClick={() => onRemove(item?.id)}
            >
              <DeleteOutlined />
            </Button>
          </Space>
        </Row>
      ),
    }));
  };

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Row justify="space-between">
        <Col>
          <PageTitle title={<IntlMessage id="topup.title" />} />
        </Col>
        <Col>
          <Button style={{ margin: 'auto' }} type="primary" onClick={handleAdd}>
            Nạp
          </Button>
        </Col>
      </Row>
      <FilterBar
        onFilter={onFilter}
        data={[
          {
            name: 'driver_id',
            placeholder: 'Chọn tài xế....',
            data: drivers,
          },
        ]}
      ></FilterBar>

      <Row>
        <Col>
          <Space>
            <Text style={{ fontSize: '20px' }} strong>
              Tổng tháng {dayjs().format('MM')}:
            </Text>
            <Text strong>
              <Currency
                style={{
                  fontSize: '20px',
                  color: totalTopup.total_amount > 0 ? '#5dc75d' : 'red',
                }}
                value={Number(totalTopup.total_amount)}
              />
            </Text>
          </Space>
        </Col>
      </Row>

      <div>
        <Collapse
          items={getDataDisplay(items)}
          accordion={true}
          // onChange={onChange}
          destroyInactivePanel={true}
          ghost={true}
          expandIconPosition="end"
        />
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          current={filter.page}
          pageSize={filter.limit}
          onChange={handleChangePage}
          total={total}
        />
      </div>
    </Space>
  );
};

export default TopUpManager;
