import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Modal,
  Pagination,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  message,
} from 'antd';
import IntlMessage from '../../components/IntlMessage';
import PageTitle from '../../components/PageTitle';
import FilterBar from '../../components/FilterBar';
import _, { debounce } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DeleteOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import { today } from '../../helpers/time';
import { getWithExpiry, setWithExpiry } from '../../utils/localStorage';
import { LocalStorageKeys } from '../../utils/constant';
import postApi from '../../api/posts';

const { Text, Paragraph } = Typography;

const Post = () => {
  const storage = getWithExpiry(LocalStorageKeys.notification_manager);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(
    storage && storage?.filter
      ? storage.filter
      : {
          limit: 10,
          page: 1,
          keyword: '',
          order_by: 'id',
          order_dir: 'DESC',
        }
  );
  const [total, setTotal] = useState(null);
  const [reload, setReload] = useState(false);
  const [condition, setCondition] = useState({});
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await postApi
        .getAll(filter)
        .then((res) => {
          if (Array.isArray(res.data)) {
            setTotal(res.total);
            setItems(res.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    fetchData();
  }, [filter, reload]);

  useEffect(() => {
    if (filter?.filter_name && filter?.filter_value) {
      const newCondition = _.zipObjectDeep(
        filter?.filter_name,
        filter?.filter_value
      );
      setCondition(newCondition);
    }
    setWithExpiry(LocalStorageKeys.notification_manager, {
      ...storage,
      filter: _.cloneDeep(filter),
    });
  }, [filter, reload]);

  const onFilter = debounce(async (value, title, type) => {
    if (type === 'search') {
      setFilter((filter) => {
        return { ...filter, keyword: value, page: 1 };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  const onEdit = async (id) => {
    history.push('/app/post-edit/' + id);
  };

  const onRemove = async (id) => {
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa?',
      onOk: async () => {
        await postApi
          .remove(id)
          .then(() => {
            message.success('Thành công!!');
            onReload();
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  };

  const onSend = async (id) => {
    const data = {
      send_at: today.toISOString(),
    };
    await postApi
      .send(id, data)
      .then(() => {
        message.success('Thành công!!');
        onReload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getDataDisplay = (data) => {
    return data.map((item) => ({
      key: item.id,
      className: 'list_item',
      showArrow: true,
      label: (
        <Row gutter={8}>
          <Col span={24}>
            <Text strong>{item?.title}</Text>
          </Col>
          {!item?.status && (
            <Tag
              color={'red'}
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translateY(-50%)',
              }}
            >
              Mới
            </Tag>
          )}
          <Col span={24}>
            <Space>
              <Button
                disabled={item.status}
                size="small"
                onClick={() => onSend(item.id)}
              >
                <IntlMessage id="notification.send" />
              </Button>
              <Button
                type="primary"
                size="small"
                danger
                onClick={() => onEdit(item?.id)}
              >
                <EditOutlined />
              </Button>

              <Button
                type="primary"
                size="small"
                danger
                onClick={() => onRemove(item?.id)}
              >
                <DeleteOutlined />
              </Button>
            </Space>
          </Col>
        </Row>
      ),
      children: (
        <Row gutter={8}>
          <Col span={24}>
            <Space size={4}>
              <div dangerouslySetInnerHTML={{ __html: item?.content }} />
            </Space>
          </Col>
        </Row>
      ),
    }));
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="end">
        <Col>
          <Space>
            <Button
              style={{ margin: 'auto' }}
              type="primary"
              onClick={() => history.push('/app/post-create')}
            >
              Thêm
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col>
          <FilterBar
            onFilter={onFilter}
            storage={getWithExpiry(LocalStorageKeys.notification_manager)}
          />
        </Col>
      </Row>
      <Row justify={'center'}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin style={{ textAlign: 'center' }} />
          </div>
        ) : (
          <>
            <Col span={24}>
              <Collapse
                items={getDataDisplay(items)}
                accordion={true}
                // onChange={onChange}
                expandIconPosition="end"
                destroyInactivePanel={true}
                ghost={true}
              />
            </Col>
            <Col span={24}>
              <Pagination
                style={{ display: 'flex', justifyContent: 'center' }}
                current={filter.page}
                pageSize={filter.limit}
                onChange={handleChangePage}
                total={total}
              />
            </Col>
          </>
        )}
      </Row>
    </Space>
  );
};

export default Post;
