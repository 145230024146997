import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-day-picker/lib/style.css';
import { Roles } from '../../utils/constant';
import HomeDriver from './HomeDriver';
import HomeAdmin from './HomeAdmin';

const { Text, Title } = Typography;

export default function Home(props) {
  const authUser = useSelector((state) => state.auth.authUser);
  return (
    <>
      {authUser?.roles === Roles.admin || authUser?.roles === Roles.super ? (
        <HomeAdmin />
      ) : (
        <HomeDriver />
      )}
    </>
  );
}
