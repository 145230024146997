import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import orderApi from '../../../api/order';
import {
  Button,
  Col,
  Descriptions,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import PageTitle from '../../../components/PageTitle';
import IntlMessage from '../../../components/IntlMessage';
import {
  InviteStatus,
  OrderStatus,
  OrderType,
  Roles,
  eventTime,
} from '../../../utils/constant';
import Status from '../../../components/Status';
import Currency from '../../../components/Currency';
import SentDriverForm from '../SentDriverForm';
import InforOrderForm from '../InforOrderForm';
import { useSelector } from 'react-redux';
import useTime from '../../../hooks/useTime';
import { stringPad } from '../../../helpers/stringPad';
import dayjs from 'dayjs';
import { UserOutlined } from '@ant-design/icons';
import ModalUpdateCustomer from './ModalUpdateCustomer';
import orderPriceApi from '../../../api/order-price';

const { Text, Paragraph } = Typography;

const OrderDetail = () => {
  const { id } = useParams();
  const [item, setItem] = useState();
  const [reload, setReload] = useState(false);
  const [prices, setPrices] = useState([]);
  const history = useHistory();
  const [openSent, setOpenSent] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const { convertToAnyTimezone } = useTime();
  const user = useSelector((state) => state.auth.authUser);
  const [openModalCustomer, setOpenModalCustomer] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const prices = await orderPriceApi.getPrice();
     
      


      let res = await orderApi.getDetail(id);
      const price_setting = prices.find(segment => Number(segment.price) === Number(res.total));
      res={...res, price_setting: price_setting}
  
      if (res) {
        
        setItem(res);

      }
    }

    fetchData();
  }, [reload]);

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleStatus = (id, status) => {
    Modal.confirm({
      title: 'Bạn chắc chắn muốn hủy!!!',
      onOk: async () => {
        try {
          const data = {
            order_status: status,
          };
          await orderApi.updateStatus(id, data);
          onReload();
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const handleEdit = (id) => {
    history.push('/app/edit-order/' + id);
  };

  const onOpenSentDriver = (data) => {
    setItem(data);
    setOpenSent(true);
  };

  const onCloseSentDriver = () => {
    setOpenSent(false);
  };

  const onOpenInfoOrder = (data) => {
    setItem(data);
    setOpenInfo(true);
  };

  const onCloseInfoOrder = () => {
    setOpenInfo(false);
  };

  const onDisplayDate = (date, type) => {
    if (date) {
      return (
        <Row gutter={8}>
          <Col span={10}>
            <Text>
              <IntlMessage id={`global.${type}`} />:
            </Text>
          </Col>
          <Col span={14}>
            {type === OrderStatus.confirmed.status ||
            type === OrderStatus.completed.status ? (
              <Text>
                {convertToAnyTimezone(date).format('HH:mm DD-MM-YYYY')}
              </Text>
            ) : (
              <Text>{convertToAnyTimezone(date).format('HH:mm')}</Text>
            )}
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <Space direction="vertical">
      <Row justify="space-between">
        <Col>
          <PageTitle title={<IntlMessage id="order.title" />} />
        </Col>
      </Row>
      <Descriptions bordered>
        <Descriptions.Item key={1} label="Mã lệnh" span={3}>
          <Text style={{ color: '#3e4095' }}>
            {item?.type === OrderType.normal.status
              ? `#1c${stringPad(item?.id)}`
              : `#2c${stringPad(item?.id)}`}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item
          key={2}
          label={<IntlMessage id="global.status" />}
          span={3}
        >
          <Space size={4} wrap>
            <Status type="order" status={item?.order_status} />
            <Status type="type" status={item?.type} />
            {item?.waiting && <Tag color="#f3d60b">chờ</Tag>}
            {item?.is_proxy_call && <Tag color={'#ed4f7b'}>Gọi hộ</Tag>}
            {item?.customer_new && <Tag color={'#f50'}>Khách hàng mới</Tag>}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          key={3}
          label={<IntlMessage id="global.driver" />}
          span={3}
        >
          {!item?.driver ? (
            !item?.invites || (item?.invites && item?.invites.length === 0) ? (
              <Text>Chưa có tài xế được mời</Text>
            ) : (
              <Space direction="vertical" size={4} style={{ width: '100%' }}>
                {item?.invites.map((invite) => (
                  <Space key={invite.id} size={4}>
                    <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                      <Text>
                        {invite.driver.firstname} {invite.driver.lastname}
                      </Text>
                    </div>
                    <Status type="invite" status={invite.invite_status} />
                  </Space>
                ))}
              </Space>
            )
          ) : (
            <Space size={4}>
              <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                <Text>
                  {item?.driver.firstname} {item?.driver.lastname}
                </Text>
              </div>
              <Status type="invite" status={InviteStatus.accepted.status} />
            </Space>
          )}
        </Descriptions.Item>
        {item?.type === OrderType.block.status && (
          <Descriptions.Item
            key={4}
            label={<IntlMessage id="global.hour_group" />}
            span={3}
          >
            <Text>{`${item?.block}h`}</Text>
          </Descriptions.Item>
        )}
        <Descriptions.Item
          key={5}
          label={<IntlMessage id="global.customer_name" />}
          span={3}
        >
          <Text>{item?.customer.name}</Text>
        </Descriptions.Item>
        <Descriptions.Item
          key={6}
          label={<IntlMessage id="global.customer_mobile" />}
          span={3}
        >
          <Text>{item?.customer.mobile}</Text>
        </Descriptions.Item>
        <Descriptions.Item
          key={7}
          label={<IntlMessage id="global.customer_car_number" />}
          span={3}
        >
          <Space>
            {item?.customer?.car_number ? (
              item?.customer?.car_number.includes('car_number') ? (
                JSON.parse(item?.customer?.car_number).map((car, index) => {
                  return <Text key={index}>{car?.car_number}</Text>;
                })
              ) : (
                <Text>{item?.customer?.car_number}</Text>
              )
            ) : null}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          key={16}
          label={<IntlMessage id="global.from_date" />}
          span={3}
        >
          <Text>
            {convertToAnyTimezone(item?.date).format('DD-MM-YYYY HH:mm')}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item
          key={16}
          label={<IntlMessage id="global.process" />}
          span={3}
        >
          <Space size={4} direction="vertical" style={{ width: '100%' }}>
            {onDisplayDate(item?.date_confirmed, OrderStatus.confirmed.status)}
            {onDisplayDate(
              item?.date_pickup_point,
              OrderStatus.pickup_point.status
            )}
            {onDisplayDate(item?.date_picked, OrderStatus.picked.status)}
            {onDisplayDate(item?.date_delivered, OrderStatus.delivered.status)}
            {onDisplayDate(item?.date_completed, OrderStatus.completed.status)}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          key={8}
          label={<IntlMessage id="global.from" />}
          span={3}
        >
          <Text>{item?.from_add_text}</Text>
        </Descriptions.Item>
        {item?.route &&
          item?.route?.includes('route') &&
          JSON.parse(item?.route)?.map((item, index) => {
            return (
              <Descriptions.Item
                key={9}
                label={`Điểm trả A${index + 1}`}
                span={3}
              >
                <Text>{item?.route}</Text>
              </Descriptions.Item>
            );
          })}
        <Descriptions.Item
          key={9}
          label={<IntlMessage id="global.to" />}
          span={3}
        >
          <Text>{item?.to_add_text}</Text>
        </Descriptions.Item>
        <Descriptions.Item
          key={10}
          label={<IntlMessage id="global.notes" />}
          span={3}
        >
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: <IntlMessage id="global.more" />,
            }}
            style={{ margin: '0px' }}
          >
            {item?.note?.split('\n')?.map((note, i) => (
              <React.Fragment key={i}>
                {note}
                <br />
              </React.Fragment>
            ))}
          </Paragraph>
          <Paragraph>

            <React.Fragment>
              {item?.price_setting ? <Text>Cự ly hành trình: {item?.price_setting.from}-{item?.price_setting.to}km</Text> : null}
              <br />
            </React.Fragment>

          </Paragraph>

        </Descriptions.Item>
        <Descriptions.Item
          key={11}
          label={<IntlMessage id="global.driver_note" />}
          span={3}
        >
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: <IntlMessage id="global.more" />,
            }}
            style={{ margin: '0px' }}
          >
            {item?.driver_note?.split('\n')?.map((note, i) => (
              <React.Fragment key={i}>
                {note}
                <br />
              </React.Fragment>
            ))}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item key={12} label="Cước phí:" span={3}>
          <Currency value={item?.total} />
        </Descriptions.Item>

        <Descriptions.Item key={13} label="Phụ phí:" span={3}>
          <Row gutter={15}>
            {dayjs().isAfter(dayjs(eventTime.start)) &&
              dayjs().isBefore(dayjs(eventTime.end)) && (
                <Col span={24}>
                  <Space>
                    <Text>
                      <IntlMessage id="surcharge.lunar_new_year" />
                    </Text>
                    <Text>
                      <Currency value={item?.event_surcharge} />
                    </Text>
                  </Space>
                </Col>
              )}
            {item?.type === OrderType.normal.status ? (
              <>
                <Col span={24}>
                  <Space>
                    <Text>
                      <IntlMessage id="surcharge.wait" />
                    </Text>
                    <Text>
                      {Math.floor(item?.waiting_duration / 60 / 60)}:
                      {Math.floor(item?.waiting_duration / 60) % 60}:
                      {item?.waiting_duration % 60}
                    </Text>
                    <Text>
                      <Currency value={item?.waiting_surcharge} />
                    </Text>
                  </Space>
                </Col>
                {!(
                  dayjs().isAfter(dayjs(eventTime.start)) &&
                  dayjs().isBefore(dayjs(eventTime.end))
                ) && (
                  <Col span={24}>
                    <Space>
                      <Text>
                        <IntlMessage id="surcharge.points" />
                      </Text>
                      <Text>{item?.point} điểm</Text>
                      <Text>
                        <Currency value={item?.extra} />
                      </Text>
                    </Space>
                  </Col>
                )}
                <Col span={24}>
                  <Space>
                    <Text>
                      <IntlMessage id="surcharge.night" />
                    </Text>
                    <Text>
                      <Currency value={item?.night_surcharge} />
                    </Text>
                  </Space>
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Space>
                    <Text>
                      <IntlMessage id="surcharge.overtime" />
                    </Text>
                    <Text>
                      {Math.floor(item?.waiting_duration / 60 / 60)}:
                      {Math.floor(item?.waiting_duration / 60) % 60}:
                      {item?.waiting_duration % 60}
                    </Text>
                    <Text>
                      <Currency value={item?.waiting_surcharge} />
                    </Text>
                  </Space>
                </Col>
                {item?.block === 24 && (
                  <Col span={24}>
                    <Space>
                      <Text>
                        <IntlMessage id="surcharge.overnight" />
                      </Text>
                      <Text>
                        <Currency value={item?.night_surcharge} />
                      </Text>
                    </Space>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item
          key={14}
          label={<IntlMessage id="global.total" />}
          span={3}
        >
          <Text>
            <Currency
              value={
                Number(item?.total) +
                Number(item?.extra) +
                Number(item?.night_surcharge) +
                Number(item?.waiting_surcharge) +
                Number(item?.event_surcharge)
              }
            />
          </Text>
        </Descriptions.Item>
      </Descriptions>
      {user?.roles === Roles.admin && (
        <Space
          style={{
            justifyContent: 'center',
            width: '100%',
          }}
          wrap={true}
        >
          <Button
            disabled={
              item?.order_status !== OrderStatus.pending.status &&
              item?.order_status !== OrderStatus.assigned.status
            }
            danger
            onClick={() => handleStatus(item?.id, OrderStatus.cancelled.status)}
          >
            <IntlMessage id="global.cancel" />
          </Button>

          <Button
            type="primary"
            danger
            onClick={() => handleEdit(item?.id, OrderStatus.cancelled.status)}
          >
            <IntlMessage id="global.edit" />
          </Button>

          <Button
            type="primary"
            disabled={item?.order_status !== OrderStatus.pending.status}
            onClick={() => onOpenSentDriver(item)}
          >
            <IntlMessage id="order.sent" />
          </Button>

          <Button onClick={() => onOpenInfoOrder(item)}>
            <IntlMessage id="global.info" />
          </Button>

          <Button type="primary" onClick={() => setOpenModalCustomer(true)}>
            <UserOutlined />
          </Button>
        </Space>
      )}
      {openInfo && (
        <InforOrderForm
          item={item}
          open={openInfo}
          onClose={onCloseInfoOrder}
          onReloadOrders={onReload}
        />
      )}
      {openSent && (
        <SentDriverForm
          item={item}
          open={openSent}
          onClose={onCloseSentDriver}
          onReloadOrders={onReload}
        />
      )}
      {openModalCustomer && (
        <ModalUpdateCustomer
          item={item?.customer}
          open={openModalCustomer}
          onClose={() => setOpenModalCustomer(false)}
          onReload={onReload}
        />
      )}
    </Space>
  );
};

export default OrderDetail;
