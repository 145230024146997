import axios from 'axios';
import { getCookie, removeCookie, setCookie } from './cookie';
import appConfig from '../config';
import { message } from 'antd';
import { NotificationManager } from 'react-notifications';

const api = axios.create({
  baseURL: `${appConfig.API_URL}`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
    scope: 'store',
  },
});

api.interceptors.request.use(
  function(config) {
    let accessToken = getCookie('token');
    // Do something before request is sent
    if (accessToken) {
      config.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      // console.log("Please check your internet connection.");
      // return Promise.reject(new Error("Please check your internet connection."));

      NotificationManager.error('Please check your internet connection.');
    }
    const originalConfig = error.config;

    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      error.response.data.message !== 'error Unauthorized' &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;
      try {
        let refeshToken = getCookie('refesh-token');
        if (refeshToken) {
          api.defaults.headers.Authorization = `Bearer ${refeshToken}`;
          const rs = await api.get('/auth/refresh');

          if (rs && rs.status === 200) {
            // console.log(rs)
            api.defaults.headers.Authorization = `Bearer ${rs.data.tokens.accessToken}`;
            setCookie('token', rs.data.tokens.accessToken, 30);
            setCookie('refesh-token', rs.data.tokens.refreshToken, 30);
            // window.location.href = '/app/home'

            originalConfig.headers.Authorization = `Bearer ${rs.data.tokens.accessToken}`;
            return api(originalConfig);
          }
        }
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    if (error && error.response && error.response.status === 500) {
      message.error('Internal server error');
    }

    return Promise.reject(error.response);
  }
);

export default api;
