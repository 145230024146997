export default {
  'global.role': 'Role',
  'global.add': 'Add New',
  'global.password': 'Password',
  'global.resetpassword': 'Reset password',
  'global.input_password': 'Input password',
  'global.is_verified_email': 'Verify email',
  'global.is_mobile_verified': 'Verify phone',
  'global.unverified': 'Unverified',
  'global.verified': 'Verified',
  'global.status': 'Status',
  'global.add_new': 'Add new',
  'global.published': 'Published',
  'global.unpublished': 'Unpublished',
  'global.delete': 'Delete',
  'global.copy': 'Copy',
  'global.create': 'Create',
  'global.update': 'Update',
  'global.image': 'Image',
  'global.title': 'Title',
  'global.company': 'Company',
  'global.id': 'ID',
  'global.tabbasic': 'Basic',
  'global.alias': 'Alias',
  'global.description': 'Description',
  'global.cancel': 'Cancel',
  'global.active': 'Active',
  'global.deactivate': 'Deactivate',
  'global.submit': 'Submit',
  'global.edit': 'Edit',
  'global.trashed': 'Trashed',
  'global.approved': 'Approved',
  'global.approve': 'Approve',
  'global.from': 'From',
  'global.to': 'To',
  'global.price_discount': 'Price discount',
  'global.price_roundtrip': 'Price roundtrip',
  'global.price': 'Price ',
  'global.car': 'Car',
  'global.type': 'Type',
  'global.room_rate': 'Room rate',
  'global.cancel_policy': 'Cancel policy',
  'global.instant_bookable': 'Instant bookable',
  'global.yes': 'Yes',
  'global.no': 'No',
  'global.city': 'City',
  'global.street': 'Street',
  'global.neighbourhood_cleansed': ' Cleansed',
  'global.neighbourhood_group_cleansed': ' Group cleansed',
  'global.location': 'Location',
  'global.choose-location': 'Choose location',
  'global.square_feet': 'Square Feet',
  'global.bedrooms': 'Bedrooms',
  'global.bathrooms': 'Bathrooms',
  'global.beds': 'Beds',
  'global.bed_type': 'Bed_type',
  'global.cover_img': 'Cover image',
  'global.gallery': 'Gallery',
  'global.gallerySetting': 'Gallery Setting',
  'global.security_deposit': 'Security deposit ',
  'global.guests_included': 'Guests included',
  'global.minimum_nights': 'Minimum nights',
  'global.cleaning_fee': 'Cleaning fee',
  'global.extra_people': 'Extra people',
  'global.maximum_nights': 'Maximum nights',
  'global.months': 'Months',
  'global.calendar': 'Calendar',
  'global.province': 'Province',
  'global.district': 'District',
  'global.ward': 'Ward',
  'global.no_house': 'House number',
  'global.nearbyUtils': 'Near by utils',
  'global.extra_price': 'Extra price',
  'global.patients': 'Patients',
  'global.setting': 'Setting',
  'global.appointments': 'Appointments',
  'global.suppliers': 'Suppliers',
  'global.id_number': 'ID',
  'global.code': 'Code',
  'global.name': 'Name',
  'global.services': 'Services',
  'global.clinic': 'Clinic',
  'global.website': 'Website',
  'global.contact_name': 'Contact',
  'global.refresh': 'Refresh',

  'car.driver': 'Driver',
  'car.seat': 'Seat',
  'car.luggage': 'Luggage',
  'car.ordering': 'Booking',
  'car.facility': 'Facility',
  'car.air_machine': 'Air machine',
  'car.energy_saving': 'Energy saving',
  'car.stop_point': 'Stop point',
  'property.start_date': 'Start date ',
  'property.end_date': 'End date',
  'property.price_week': 'Price week',
  'property.weekend_price': 'Price weekend',
  'property.extra_price': 'Extra price',
  'property.host_languages': ' Host languages',
  'property.week_term_promo': 'Week term promo',
  'property.month_term_promo': 'Month term promo',
  'property.price_weekend': 'Price weekend',
  'global.guests_standard': 'Guests standard',
  'global.guests_max': 'Guests max',
  'sidebar.transport': 'Transport',
  'sidebar.amenity': 'Amenity',
  'sidebar.restaurant': 'Restaurant',
  'sidebar.property': 'Property',
  'sidebar.property_type': 'Property type',
  'sidebar.room': 'Room',
  'sidebar.cancel_policy': 'Cancel policy',
  'sidebar.vehicle': 'Vehicle',
  'sidebar.car': 'Car',
  'sidebar.route': 'Route',
  'sidebar.inbox': 'Inbox',
  'sidebar.conversation': 'Conversation',
  'sidebar.reservations': 'Reservations',
  'sidebar.earning': 'Earning',
  'sidebar.calendar': 'Calendar',
  'sidebar.reports': 'Reports',
  'sidebar.services': 'Services',
  'sidebar.addons_order': 'Additional services',
  'sidebar.debt_confirmation_record': 'Debt confirmation record',
  'global.requiredfield': 'Required field',

  'property.room_type': 'Room type',
  'global.propertytypes': 'Property type',
  'global.save': 'Save',
  'global.signin': 'Sign in',
  'global.signup': 'Signup',
  'global.forgot_password': 'Forgot password',
  'global.search_placeholder': 'Search',

  //Tour
  'global.duration': 'Duration',
  'global.category': 'Category',
  'global.cover_image': 'Cover',
  'global.operator': 'Operator',
  'global.from_price': 'Prices',
  'global.itineraries': 'Itinerary',
  'global.updated_at': 'Updated at',
  'global.from_priceMGMT': 'Prices',
  'global.from_price_setting': ' Prices setting',
  'global.ItineraryMGMT': ' Itineraries',
  'global.tour': 'Tour',

  'global.PriceMGMT': 'Price Management',
  'global.itinerariesMGMT': 'Itinerary management',
  'tour.title': 'Title',
  'global.select_category': 'Select category',
  'global.select_operator': 'Select operator',
  'global.select_cabin_type': 'Select cabin type',
  'tour.description': 'Tour description',
  'tour.inclusion': 'Inclusion',
  'tour.exclusion': 'Exclusion',
  'global.inactive': 'Inactive',
  'global.meal': 'Meals',
  'global.category_operator': 'Category and operator',
  'tour.pricing': 'Pricing model',
  'tour.special': 'Special',
  'tour.normal': 'Normal',
  'tour.featured': 'Featured',
  'tour.time_mgmt': 'Time management',
  'tour.duration_unit': 'Duration unit',
  'tour.duration': 'Duration',
  'tour.start_time': 'Start time',
  'global.delete_confirm': 'Are you sure want to delete this item?',
  'global.cancel_order': 'Are you sure want to cancel this item?',
  'tour.gallery': 'Gallery',
  'global.ticket': 'Ticket',
  'global.min_person': 'Min person',
  'global.freeCancelHours': 'Free cancel hours',
  'global.freeCancel': 'Free cancel',
  'tour.freeCancel': 'Free cancel',
  'global.cancelPolicy': 'Cancel policy',
  'global.meeting': 'Meeting point',
  'global.meetingPoint': 'Meeting point',
  'global.meetingPointNotes': 'Meeting point notes',
  'tour.cutOffTime': 'Cut off time(hours)',
  'tooltip.cutOffTime': 'e.g: 2 days before departure date is 48 hours',
  'global.perPerson': 'Per person',
  'global.perGroup': 'Per group',
  'global.optionTitle': 'Option title',
  'global.checkall': 'Check all',

  //Option
  'global.max_person': 'Max person',
  'global.adult_price': 'Adult price',
  'global.child_price': 'Child price',
  'global.select_tour': 'Select tour',
  'global.option': 'Option',
  'option.group': 'Group',
  'global.Vehicles': 'Vehicles',
  'global.timeStart': 'Time start',

  //Destination
  'global.destination': 'Destination',
  'global.select_destination': 'Select destination',
  'global.countries': 'Country',
  'global.select_country': 'Select country',
  'destination.longitude': 'Longitude',
  'destination.latitude': 'Latitude',
  'global.destination_parent': 'Destination parent',

  //Categories
  'global.categories': 'Category',

  //Operators
  'global.operators': 'Operator',
  'global.firstname': 'First name',
  'global.lastname': 'Last name',
  'global.email': 'Email',
  'global.phone': 'Phone',

  //Currencies
  'global.currencies': 'Currency',
  'currency.name': 'Name',
  'currency.display': 'Display',
  'currency.exchange_rate': 'Exchange rate',
  'currency.thousand': 'Format',
  'currency.image': 'Icon',
  'currency.symbol': 'Symbol',
  //post
  'global.posts': 'Post',
  'post.fulltext': 'Full Text',
  'post.short': 'Short',
  'global.featured': 'Featured',
  'post.hit': 'Hit',
  'post.metadesc': 'Meta desc',
  'post.metakey': 'Meta key',
  'post.image': 'Image',

  //Log-in Log-out
  'global.account_information': 'Account information',
  'global.signout': 'Sign out',
  'global.email_required': 'Email required',
  'global.password_required': 'Password required',
  'global.email_not_valid': 'Email not valid',

  //Site:
  'global.site': 'Site',
  'sites.hotline': 'Hotline',
  'sites.locale': 'Locale',
  'sites.from_email': 'From email',
  'sites.from_name': 'From name',
  'sites.currency': 'Currency',
  'sites.timezone': 'Time zone',
  'sites.meta_description': 'Meta description',
  'sites.meta_title': 'Meta title',
  'sites.email': 'Email',
  'global.sites': 'Sites',
  //Bookings:
  'global.order': 'Booking',
  'global.orderId': 'Booking ID',
  'order.tour_title': 'Tour title',
  'order.route_title': 'Title',
  'order.pay_status': 'Pay status',
  'order.order_status': 'Booking status',
  'order.depart_date': 'Depart date',
  'order.customer': 'Customer',
  'order.totalTravelers': 'Total traveler',
  'order.totalPrice': 'Total price',
  'order.created': 'Booked at',
  'order.created_by': 'Created by',
  'order.create': 'Create booking',
  'order.order_seat': 'Seat Allocation',
  'global.one_way': 'One way',
  'global.two_way': 'Two way',
  'global.time': 'Time',
  'global.search': 'Search',
  'global.departure': 'Departure',
  'global.return': 'Return',
  'global.total': 'Total',
  'global.group_code': 'Code',
  'order.depart/return': 'Depart / Return',
  //Customers:
  'global.customers': 'Customer',
  'global.fullname': 'Full name',
  'global.userId': 'User ID',
  'global.company_name': 'Company name',
  'global.company_shortname': 'Short name',
  'global.company_tax_id': 'Tax code',
  'global.address': 'Address',
  'global.business': 'Business',
  'global.not_business': 'Not business',
  'global.select_business': 'Select business',

  //Users:
  'global.roles': 'Role',
  'global.users': 'Users',

  //Schedule
  'global.schedule_setting': 'Schedule setting',
  'global.schedule': 'Schedule',
  'global.start_date': 'Start date',
  'global.end_date': 'End date',
  'global.weekdays': 'Weekdays',

  //wishlist
  'global.wishlist': 'Wishlist',
  'wishlist.tour_title': 'Tour title',
  'wishlist.customer': 'Customer',
  'global.basic': 'Basic',
  'global.item': 'Item',
  'global.items': 'Items',
  'global.sku': 'SKU',
  'global.widgets': 'Widgets',
  'global.widget': 'Widget',

  //menus
  'global.menus': 'Menus',
  'global.menu': 'Menu',
  'global.key': 'Key',
  'global.menu_items': 'Menu items',
  'global.menu_item': 'Menu item',

  //Register
  'global.registration_header': 'Register form',
  'global.country': 'Country',
  'global.mobile': 'Mobile',
  'signup.back_to_login': 'Back to login',
  'global.continue': 'Continue',
  'global.close': 'Close',

  //
  'global.statistics': 'Statistics',
  'global.tours': 'Tours',
  'global.destinations': 'Destinations',
  'global.orders': 'Bookings',

  'global.language': 'Language',
  'global.currency': 'Currency',
  'global.your_plan': 'Plan',
  'global.trial_end': 'Expired date',
  'global.timezone': 'Timezone',
  'global.emai_required': 'Yêu cầu điền email',
  // 'global.password_required': 'Yêu cầu điền mật khẩu',
  'global.emai_not_valid': 'Email không đúng định dạng',
  'global.confirm_password': 'Confirm password',
  'global.changepassword': 'Change password',
  'global.confirm_new_password': 'Confirm new password',
  'global.new_password': 'New password',
  'global.current_password': 'Current password',
  'global.password_not_match': 'Password not match',
  'global.password_min_charactor': '6 characters min',
  'global.home': 'Home',

  // Cabins
  'global.cabins': 'Cabins',
  'global.size': 'Size',
  'global.qty': 'Quantity',
  'global.route': 'Route',

  // Customer Groups
  'global.discount': 'Discount',
  'global.cGroups': 'Customer groups',
  'global.group': 'Group',

  //
  'global.routes': 'Routes',
  'global.hops': 'Hops',
  'global.systems': 'Systems',
  'global.start_time': 'Start time',
  'global.start_stop': 'Destination start',
  'global.end_stop': 'Destination end',
  'global.cabin-rates': 'Cabin rates',
  'global.title_route': 'Route',

  // Addons
  'global.addons': 'Additional services',
  'global.seat': 'Seat',
  'global.from_date': 'From',
  'global.to_date': 'Up to and including',

  'global.order_group': 'Booking group',
  'global.route_tital': 'Route',
  'global.select_customer': 'Select customer',
  'global.order_status': 'Booking status',
  'global.order_cancel': 'Are you sure want to cancel this order?',
  'order.pax': 'Pax',
  'global.passport': 'Passport',
  'global.addnew': 'Add',
  'global.select_county': 'Select county',
  'global.cabin': 'Cabin',
  'global.price_type': 'Price type',
  'global.voucher': 'Voucher',
  'global.passengers': 'Passengers',
  'global.notes': 'Notes',
  'global.order_id': 'Booking ID',
  'global.select_date': 'Select date',
  'global.select_hops': 'Select hop',
  'global.pay_status': 'Pay status',
  'bill.serial': 'Serial',
  'bill.billed_date': 'Staff payment date',
  'bill.amount': 'Amount',
  'global.method': 'Method',
  'global.payment_method': 'Payment method',
  'global.bills': 'Payment',
  'global.notpaid': 'Not paid',
  'global.paid': 'Paid',
  'global.collect': 'Collect',
  'global.spend': 'Spend',
  'global.cash': 'Cash',
  'global.card': 'Card',
  'global.bank': 'Bank',
  'global.insurance': 'Insurance',
  'global.select_customers': 'Select customer',
  'global.select_types': 'Select type',
  'sidebar.dept': 'AR',
  'global.total_debt_begin_period': 'Debt begin period',
  'global.total_paid_begin_period': 'Paid begin period',
  'global.total_debt_in_period': 'Debt in period',
  'global.total_paid_in_period': 'Paid in period',
  'global.total_debt_end_period': 'Total debt',

  'global.ar': 'Account receivable',
  'global.confirm': 'Confirm',
  'global.order_confirm': 'Are you sure want to confirm this order?',
  'global.bill_confirm': 'Are you sure want to approve this bill?',
  'global.cabin_rates': 'Cabin rates',
  'global.bookingCalendar': 'Booking calendar',
  'global.invoice': 'Invoice',
  'global.list_order_not_paid': 'List order not paid',
  'global.export_to_excel': 'Export to Excel',
  'global.report_debt_confirmation': 'Debt confirmation record',
  'global.debt': 'Debt',
  'global.bill': 'Payment',
  'global.record': 'Record',
  // report
  'global.date': 'Date',
  'global.hop': 'Hop',
  'global.addon': 'Additional service',
  'global.customer': 'Customer',
  'global.year': 'Year',

  'global.select_menu': 'Select menu',
  'global.select_role': 'Select role',

  'global.add_walk_in_customer': 'Add walk-in customer',
  'global.order_details': 'Booking details',
  'global.cabins_order': 'Cabins order',
  'global.last_30_day': 'Last 30 days',
  'global.last_7_day': 'Last 7 days',
  'global.last_month': 'Last month',
  'global.this_month': 'This month',
  'global.next_month': 'Next month',
  'global.seller': 'Seller',
  'global.send_email': 'Send email',
  'global.sale': 'Sale',
  'global.finance': 'Finance',
  'global.carriages': 'Carriages',
  'global.logo': 'Logo',
  'global.content': 'Content',
  'global.path': 'Path',
  'global.seat_allocation': 'Seat allocation',
  'global.cabinSerials': 'Cabin serials',
  'global.title_carriage': 'Title carriage',
  'global.title_cabin': 'Title cabin',
  'global.select_carriage': 'Select carriage',
  'global.order_seat': 'Booking seat',
  'global.order_seats': 'Booking seats',
  'global.depart_seating': 'Depart seating',
  'global.return_seating': 'Return seating',
  'global.seating': 'Seating',
  'global.summary': 'Summary',
  'global.action': 'Action',
  'global.created_at': 'Created at',
  'global.created_by': 'Created by',
  'global.roundtrip': 'Roundtrip',

  'sites.url': 'URL',
  'sites.ga_script': 'Google analytic script',
  'global.meta_data': 'Meta Data',
  'global.meta_title': 'Meta title',
  'global.meta_description': 'Meta Description',
  'global.meta_keywords': 'Meta Keywords',
  'global.before_head': 'Before Head',
  'global.before_body': 'Before Body',
  'global.layout': 'Layout',
  'global.socials': 'Socials',

  // status
  'global.all': 'All',
  'global.pending': 'Pending',
  'global.confirmed': 'Confirmed',
  'global.cancelled': 'Cancelled',
  'global.refund': 'Refund',
  'global.mark_as_paid': 'Mark as paid',

  'global.super': 'Super',
  'global.admin': 'Admin',
  'global.staff': 'Staff',
  'global.agent': 'Agent',
  'global.upgrade': 'Upgrade',
  'global.cabin_no': 'Cabin No',
  'global.carriage': 'Carriage',
  'global.cabin_type': 'Cabin Type',
  'global.view_seat': 'View Seat',
  'global.view': 'View',
  'global.ok': 'Ok',
  'global.seats': 'Seats',
  'global.time_period': 'Time period',
  'global.booked_seats': 'Booked seats',
  'global.allocated_seat': 'Allocated seat',
  'global.revenue': 'Revenue',
  'global.revenue_report': 'Revenue report',
  'global.cabins_performance': 'Cabins performance',
  'global.created_date': 'Created date',
  'global.depart_date': 'Depart date',
  'global.download': 'Download',
  'global.permission': 'Permission',
  'global.permissions': 'Permissions',
  'permissions.decline': 'Decline',
  'permissions.allow': 'Allow',
  'global.e_ticket': 'E-Ticket',
  'global.internal_notes': 'Internal notes',
  'global.information_customer': 'Information customer',
  'global.cgroup': 'Customer group',
  'global.order_information': 'Booking information',
  'global.accountant_email': 'Accountant email',
  'global.more': 'more',
  'global.full': 'Full',
  'global.deposited': 'Deposited',
  'global.payment_type': 'Payment type',
  'global.payment_status': 'Payment status',
  'global.contact_information': 'Contact information',
  'global.contacts': 'Contacts',
  'global.contact': 'Contact',
  'global.add_contact': 'Add contact',
  'global.office': 'Office',
  'global.select_contact': 'Select contact',
  'global.select_seller': 'Select seller',
  'global.input_group_code': 'Input code',
  'global.input_pax': 'Input pax',
  'global.input_order_notes': 'Input order notes',
  'global.input_order_internal_notes': 'Input order internal notes',
  'global.input_contact_name': 'Input contact name',
  'global.input_contact_email': 'Input contact email',
  'global.input_contact_mobile': 'Input contact mobile',
  'validate.is_not_email': 'Field is not email',
  'validate.is_not_phone_number': 'Field is not phone number',
  'order.select_other_contact': 'Other',
  'global.select_hop': 'Select a hop',
  'global.select_route': 'Select a route',
  'global.book': 'Book',
  'global.user': 'User',
  'global.save_success': 'Save successfully',
  'global.save_failed': 'Save failed',
  'global.guide_number': 'Guide',
  'global.input_guide_number': 'Input guide',
  'global.input_your_password': 'Please input your password!',
  'global.input_beds': "Please input beds's name or delete this field",
  'global.input_email_or_delete':
    "Please input email's name or delete this field",
  'global.select_destination_start': 'Select destination start',
  'global.select_destination_end': 'Select destination end',
  'placeholder.select_hop': 'Select hop',
  'placeholder.select_addon': 'Select additional service',
  'placeholder.from': 'From',
  'placeholder.to': 'To',
  'placeholder.select_category': 'Select category',
  'placeholder.select_customer_group': 'Select customer group',
  'global.select_user': 'Select user',
  'placeholder.select_user': 'Select user',
  'global.select_permission': 'Select permission',
  'global.select_all': 'Select all',
  'global.advanced_settings_for_each_user': 'Advanced settings for each user',
  'note.reload_page': 'Need to reload the page after finishing editing',
  'global.history': 'History',
  'global.clear': 'Clear',
  'history.from_date': 'From date',
  'history.to_date': 'To date',
  'history.week_days': 'Weekdays',
  'global.reload': 'Reload',
  'global.clear_rate_confirm': 'Are you sure want to clear this data?',
  'placeholder.booking_id_or_code': 'Booking ID or Code',
  'global.special_notes': 'Special notes',
  'placeholder.input_special_notes': 'Input special notes',
  'global.choice_a_time_period': 'Choice a time period',
  'global.not_found': 'Not found',
  'global.select_a_customer': 'Select a customer',
  'placeholder.select_customer': 'Select customer',
  'placeholder.input_notes': 'Input notes',
  'placeholder.input_description': 'Input description',
  'placeholder.select_creator': 'Select creator',
  'placeholder.select_status': 'Select status',
  'placeholder.payment_from': 'Transaction from',
  'placeholder.payment_to': 'Transaction to',
  'global.initialization_time': 'Initialization time',
  'placeholder.input_title': 'Input title',
  'placeholder.input_code': 'Input code',
  'placeholder.select_route': 'Select route',
  'error.quantity_is_not_the_same':
    'Quantity is not the same as ordered quantity',
  'global.addons_booking': 'Additional services',
  'error.missing_addon': 'Please select an additional service',
  'error.missing_customer': 'Please select an customer',
  'warning.not_found_carriages': 'No carriages available',
  'global.individual': 'Individual',
  'global.available': 'Available',
  'global.booking_statistics': 'Booking statistics',
  'global.total_number_of_tickets': 'Total number of tickets',
  'global.sold': 'Sold',
  'global.remaining': 'Remaining',
  'global.capacity': 'Capacity',
  'global.general_capacity': 'General capacity',
  'global.files_manager': 'Files manager',
  'error.upload_file': 'Error uploading file. Please try again.',
  'success.upload_file': 'File uploaded successfully!',
  'global.create_folder': 'Create folder',
  'placeholder.input_folder_name': 'Input folder name',
  'global.folder_name': 'Folder name',
  'error.create_folder': 'Error create folder. Please try again.',
  'error.delete_file': 'Error delete, please try again.',
  'error.folder_name': 'Invalid folder name, please try again.',
  'error.missing_file_upload': 'Please select a file to upload.',
  'placeholder.select_date': 'Select date',
  'placeholder.input_email': 'Input email',
  'global.send': 'Send',
  'global.download_addon_booking': 'Download additional services',
  'global.download_passengers': 'Download passengers',
  'global.download_invoice': 'Download invoice',
  'error.send_email': 'Error send email. Please try again.',
  'global.send_email_success': 'Email sent successfully',
  'global.customer_name': 'Customer name',
  'global.seated_by': 'Seated by',
  'global.seated_date': 'Seated date',
  'sidebar.invoice': 'Invoice',
  'sidebar.invoice_management': 'Invoice management',
  'global.booked_from': 'Booked from',
  'global.booked_to': 'Booked to',
  'global.amount_of_money': 'Amount of money',
  'global.track': 'Track',
  'placeholder.input_track': 'Input track',
  'global.amenities': 'Amenities',
  'global.icon': 'Icon',
  'error.must_contain_least_one_value':
    'Must contain at least one information of passenger',
  'global.representative_passenger': 'Representative passenger',
  'placeholder.input_firstname': 'Input firstname',
  'placeholder.input_lastname': 'Input lastname',
  'error.missing_passenger_information':
    'Please input least one information of passenger',
  'global.maintenance_time': 'Maintenance time',
  'global.period': 'Period',
  'placeholder.input_representative_passenger':
    'Input representative passenger',
  'global.initialization_depart': 'Initialization depart',
  'warning.carriage_not_belong_to_any_route':
    'The carriage does not belong to any route',
  'warning.carriage_is_maintenance': 'The carriage is maintenance',
  'placeholder.depart_from': 'Depart from',
  'placeholder.depart_to': 'Depart to',
  'global.search_bookings': 'Search bookings',
  'global.excess_money': 'Excess money',
  'global.excess': 'Excess',
  'global.debt_money': 'Debt money',
  'placeholder.search_with_code': 'Search with code',
  'global.return_date': 'Return date',
  'global.cancel_return_trip': 'Cancel return trip',
  'error.date_not_yet_been_priced': 'The change date has not yet been priced',
  'global.date_of_receipt': 'Date of receipt',
  'global.payment': 'Payment',
  'error.code_already_exists': 'Code already exists',
  'placeholder.payment_staff_from': 'Staff payment from',
  'placeholder.payment_staff_to': 'Staff payment to',
  'global.refund_policy': 'Refund policies',
  'global.refund_policies': 'Refund policies',
  'global.promotions': 'Promotions',
  'global.cancel_fee': 'Cancellation fee',
  'global.hours': 'Hours',
  'global.is_default': 'Is default',
  'global.free_cancel': 'Free cancel',
  'global.belongs_penalty': 'Belongs to the penalty period',
  'global.penalty': 'Penalty',
  'global.cancel_booking': 'Cancel booking',
  'global.staff_payment_date': 'Staff payment date',
  'placeholder.guest_payment_date': 'Transaction date',
  'info.no_debt': 'The booking has no debt',
  'global.payments': 'Payments',
  'global.refund_amount': 'Refund amount',
  'placeholder.select_type': 'Select type',
  'global.penalty_date': 'Penalty date',
  'global.today': 'Today',
  'global.penalty_status': 'Penalty status',
  'global.no_payment_yet': 'No payment yet',
  'global.updated_by': 'Updated by',
  'placeholder.last_updated_by': 'Last updated by',
  'placeholder.updated_from': 'Updated from',
  'placeholder.updated_to': 'Updated to',
  'bill.debt': 'Debt',
  'global.penalty_amount': 'Penalty amount',
  'global.booking_value': 'Booking value',
  'global.create_from': 'Create at from',
  'global.create_to': 'Create at from',
  'global.used_form': 'Used at from',
  'global.used_to': 'Used at to',
  'global.combined': 'Combine',
  'global.create_at': 'Create at',
  'global.used_at': 'Use at',
  'global.guest_payment_date': 'Transaction date',
  'global.policy_penalty_name': 'Penalty policy name',
  'global.guest_payment': 'Transaction',
  'global.guest_payment_detail': 'Transaction detail',
  'global.waiting_approve': 'Waiting approve',
  'global.created_payments': 'Created payments',
  'global.sent': 'Sent',
  'global.not_send': 'Not send',
  'global.voucher_status': 'Voucher status',
  'global.voucher_sent': 'Voucher sent',
  'global.waiting_cancel': 'Waiting cancel',
  'global.vip': 'Vip',
  'global.income': 'Income',
  'global.expense': 'Expense',
  'global.pay': 'Pay',
  'global.debit': 'Debit',
  'global.amount': 'Amount',
  'global.service_date': 'Date of service',
  'global.service_location': 'Location of service',
  'global.transaction': 'Transaction',
  'global.transactions': 'Transactions',
  'error.amount_less_than_0': 'The amount must be greater than 0',
  'global.confirm_cancel_return_trip': 'Confirm cancel return trip',
  'global.create_transaction': 'Create transaction',
  'global.new_booking_value': 'New booking value',
  'global.debt_report': 'Debt report',
  'global.incurred': 'Incurred',
  'global.credit': 'Credit',
  'global.total_debt': 'Total debt',
  'placeholder.input_date_of_service': 'Input date of service',
  'placeholder.input_location_of_service': 'Input location of service',
  'global.depart_value': 'Depart value',
  'global.return_value': 'Return value',
  'global.still_in_debt': 'Still in debt',
  'global.refund_recording': 'Refund recording',
  'placeholder.input_amount': 'Input amount',
  'success.refund_recorded': 'Refund recorded',
  'global.carriage_maintenance': 'Carriage maintenance',
  'global.maintenance': 'Maintenance',
  'placeholder.select_carriage': 'Select carriage',
  'global.maintenance_from': 'Maintenance from',
  'global.maintenance_to': 'Maintenance to',
  'placeholder.search_for_booking_information':
    'Search for booking information',
  'placeholder.transaction_by': 'Transaction by',
  'global.transaction_by': 'Transaction by',
  'global.coach': 'Coach',
  'placeholder.input_coach': 'Input coach',
  'global.total_booking_values': 'Total booking values',
  'global.total_payments': 'Total payments',
  'global.total_refunds': 'Total refunds',
  'global.mode': 'Mode',
  'placeholder.select_mode': 'Select mode',
  'global.month': 'Month',
  'global.day': 'Day',
  'global.depart_year': 'Depart year',
  'placeholder.input_year': 'Input year',
  'global.slug': 'Slug',
  'global.image_alt': 'Image Alt',
  //invoice management
  'invoice.info': 'General information',
  'invoice.no': 'Invoice no',
  'invoice.date': 'Invoice date',
  'invoice.tax_no': 'Tax no',
  'invoice.tax_total': 'Tax total',
  'invoice.unit': 'Unit',
  'invoice.item': 'Invoice details',
  'invoice.e_invoice': 'E-invoice',
  'invoice.issue_e_invoice': 'Issue e-invoice',
  'invoice.sent_e_invoice': 'Sent e-invoice',
  'invoice.e_invoice_sent': 'E-invoice sent',
  'invoice.create': 'Create invoice',
  'invoice.edit': 'Edit invoice',
  'global.waiting': 'Waiting',
  'global.sell_by': 'Sell by',
  'global.last_updated': 'Last updated',
  'invoice.payment_method': 'Payment method',
  'invoice.issued_date': 'Issued date',
  'invoice.sent_date': 'Sent date to Customer',
  'global.unsent': 'Unsent',
  'global.sent_invoice_confirm':
    'Are you sure you want to send this invoice to the MISA meInvoice system?',
  'global.sent_invoice_success': 'sent invoice successfully',
  'global.published_invoice_confirm':
    'Make sure the MISA system has issued this invoice',
  'global.unpublished_invoice':
    'The MISA system has not been released for this single invoice',
  'global.published_invoice': 'The invoice has been issued successfully',
  'global.invoice_vat': 'VAT Invoice',
  'global.vat_rate': 'VAT rate',
  'global.total_product_cost': 'Total product cost',
  'global.vat_amount': 'VAT amount',
  'global.upload_file': 'Upload file pdf',
  'invoice.upload_invoice': 'Upload invoice',
  'global.upload_invoice_success': 'Upload invoice successfully',
  'global.upload_invoice_fail': 'Upload invoice fail',
  'placeholder.from_issued_date': 'Issued from',
  'placeholder.to_issued_date': 'Issued to',
  'global.transaction_id': 'Transaction ID',
  'global.unit_type': 'Unit type',
  'placeholder.input_unit_type': 'Input unit type',
};
