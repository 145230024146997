import React, { useEffect, useState } from 'react';
import IntlMessage from '../../components/IntlMessage';
import Title from '../../components/Title';
import FilterBar from '../../components/FilterBar';
import {
  Button,
  Col,
  Collapse,
  Modal,
  Pagination,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import _, { debounce } from 'lodash';
import {
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import customersApi from '../../api/customers';
import PageTitle from '../../components/PageTitle';
import ModalOrders from './ModalOrders';
import { getWithExpiry, setWithExpiry } from '../../utils/localStorage';
import { LocalStorageKeys } from '../../utils/constant';

const { Text, Paragraph } = Typography;
const CustomerManager = () => {
  const storage = getWithExpiry(LocalStorageKeys.customer_list);
  const [filter, setFilter] = useState(
    storage && storage?.filter
      ? storage.filter
      : {
          limit: 10,
          page: 1,
          keyword: '',
          order_by: 'id',
          order_dir: 'DESC',
        }
  );
  const [condition, setCondition] = useState({});
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [openOrders, setOpenOrders] = useState(false);
  const [record, setRecord] = useState(null);
  const [reload, setReload] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await customersApi.getAll(filter);
      if (Array.isArray(res.data)) {
        setTotal(res.total);
        setItems(res.data);
      }
      setLoading(false);
    }

    fetchData();
  }, [filter, reload]);

  useEffect(() => {
    if (filter?.filter_name && filter?.filter_value) {
      const newCondition = _.zipObjectDeep(
        filter?.filter_name,
        filter?.filter_value
      );
      setCondition(newCondition);
    }
    setWithExpiry(LocalStorageKeys.customer_list, {
      filter: _.cloneDeep(filter),
    });
  }, [filter]);

  const onFilter = debounce(async (value, title, type) => {
    if (type === 'search') {
      setFilter((filter) => {
        return { ...filter, keyword: value, page: 1 };
      });
    } else {
      const filter_obj = { ...condition, [title]: value };
      let filter_name = [];
      let filter_value = [];
      for (var key in filter_obj) {
        if (filter_obj[key]) {
          filter_name.push(key);
          filter_value.push(filter_obj[key]);
        }
      }
      setFilter((filter) => {
        return {
          ...filter,
          filter_name: filter_name,
          filter_value: filter_value,
        };
      });
    }
  }, 300);

  const handleOpenOrders = (record) => {
    setOpenOrders(true);
    setRecord(record);
  };

  const handleCloseOrders = () => {
    setOpenOrders(false);
  };

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  const handleEdit = (id) => {
    history.push('/app/customers-edit/' + id);
  };

  const onRemove = async (id) => {
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa?',
      onOk: async () => {
        await customersApi
          .remove(id)
          .then(() => {
            message.success('Thành công!!');
            onReload();
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  };

  const getDataDisplay = (data) => {
    return data?.map((item) => ({
      key: item.id,
      className: 'list_item',
      showArrow: false,
      label: (
        <>
          <Space style={{ width: '100%' }} direction="vertical">
            <Row>
              <Text strong>{item?.name}</Text>
            </Row>

            <Row>
              <Text>sdt: {item?.mobile}</Text>
            </Row>

            <Row gutter={5}>
              <Col>
                <Text>
                  <IntlMessage id="customers.car_number" />:{' '}
                </Text>
              </Col>

              <Col>
                <Space direction="vertical">
                  {item?.car_number ? (
                    item.car_number.includes('car_number') ? (
                      JSON.parse(item.car_number).map((car, index) => {
                        return <Text key={index}>{car?.car_number}</Text>;
                      })
                    ) : (
                      <Text>{item?.car_number}</Text>
                    )
                  ) : null}
                </Space>
              </Col>
            </Row>
            <Row gutter={5}>
              <Col>
                <Text>
                  <IntlMessage id="global.note" />:{' '}
                </Text>
              </Col>

              <Col>
                <Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: <IntlMessage id="global.more" />,
                  }}
                  style={{ margin: '0px' }}
                >
                  {item?.note?.split('\n')?.map((note, i) => (
                    <React.Fragment key={i}>
                      {note}
                      <br />
                    </React.Fragment>
                  ))}
                </Paragraph>
              </Col>
            </Row>

            <Row>
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleEdit(item.id)}
                >
                  <EditOutlined />
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleOpenOrders(item)}
                >
                  <UnorderedListOutlined />
                </Button>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={() => onRemove(item?.id)}
                >
                  <DeleteOutlined />
                </Button>
              </Space>
            </Row>
          </Space>
        </>
      ),
    }));
  };

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Row justify="space-between">
        <Col>
          <PageTitle title={<IntlMessage id="customers.title" />} />
        </Col>
        <Col>
          <Space>
            <Button
              style={{ margin: 'auto' }}
              type="primary"
              onClick={() => history.push('/app/customers-create')}
            >
              Thêm
            </Button>
            <Button
              style={{ margin: 'auto' }}
              type="primary"
              onClick={() => {
                setWithExpiry(LocalStorageKeys.customer_list, {
                  filter: _.cloneDeep(filter),
                });
                onReload();
              }}
            >
              Làm mới
            </Button>
          </Space>
        </Col>
      </Row>
      <FilterBar
        onFilter={onFilter}
        storage={getWithExpiry(LocalStorageKeys.customer_list)}
      ></FilterBar>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin style={{ textAlign: 'center' }} />
        </div>
      ) : (
        <div>
          <Pagination
            style={{ display: 'flex', justifyContent: 'center' }}
            current={filter.page}
            pageSize={filter.limit}
            showSizeChanger={false}
            onChange={handleChangePage}
            total={total}
          />
          <Collapse
            items={getDataDisplay(items)}
            accordion={true}
            // onChange={onChange}
            destroyInactivePanel={true}
            ghost={true}
            collapsible={'icon'}
          />
          <Pagination
            style={{ display: 'flex', justifyContent: 'center' }}
            current={filter.page}
            pageSize={filter.limit}
            showSizeChanger={false}
            onChange={handleChangePage}
            total={total}
          />
        </div>
      )}
      {openOrders && (
        <ModalOrders
          open={openOrders}
          handleClose={handleCloseOrders}
          currentData={record}
        />
      )}
    </Space>
  );
};

export default CustomerManager;
