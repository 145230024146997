import { Button, Col, Form, InputNumber, Row, Space, Tabs } from 'antd';
import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle';
import IntlMessage from '../../components/IntlMessage';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import orderPriceApi from '../../api/order-price';
import _, { debounce } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const ManagerPrice = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const res = await orderPriceApi.getPrice();
      if (Array.isArray(res)) {
        form.setFieldValue('prices', res);
      }
    }

    fetchData();
  }, []);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values);
        const data = { ...values };

        await orderPriceApi.changePrice(data).then(() => {
          history.push('/app/home');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChangePrice = debounce(() => {
    const value = form.getFieldsValue()?.prices;
    const newValue = _.sortBy(value, ['from', 'to']);
    form.setFieldValue('prices', newValue);
  }, 1000);

  const tabs = [
    {
      key: '1',
      label: 'Giá cước',
      children: (
        <>
          <Form.List name="prices">
            {(fields, { add, remove }) => (
              <Form.Item label="Giá cước">
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'from']}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessage id="global.required" />,
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        placeholder="từ"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/(,*)/g, '')}
                        onChange={onChangePrice}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'to']}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessage id="global.required" />,
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        placeholder="Tới"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'price']}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessage id="global.required" />,
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        placeholder="Giá"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Thêm giá cước
                  </Button>
                </Form.Item>
              </Form.Item>
            )}
          </Form.List>
        </>
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <Row>
        <Col span={24}>
          <PageTitle title="Gía cước" />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Form
            // {...layout}
            // initialValues={initialValues}
            form={form}
            labelAlign="left"
          >
            <Tabs items={tabs} activeKey="1"></Tabs>
          </Form>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            style={{
              width: '100%',
            }}
            onClick={handleSubmit}
          >
            Lưu
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

export default ManagerPrice;
