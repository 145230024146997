import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Layout,
  Modal,
  Button,
  Avatar,
  Row,
  Col,
  Popover,
  Divider,
  Space,
  Typography,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
// actions
import { logout } from '../redux/actions/AuthActions';
import IntlMessages from '../components/IntlMessage';
import { injectIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import menu from '../menu';
import MenuMobile from './menu/MenuMobile';
import { MenuPermission, Roles } from '../utils/constant';

const { Text } = Typography;
const { Header } = Layout;
const { confirm } = Modal;

function AppHeader() {
  const history = useHistory();
  const location = useLocation();
  // const { pathname } = location;
  const authUser = useSelector((state) => state.auth.authUser);
  const dispatch = useDispatch();
  // const [menuDisplay, setMenuDisplay] = useState([]);

  // useEffect(() => {
  //   if (authUser?.roles === MenuPermission.super) {
  //     setMenuDisplay(menu);
  //   } else {
  //     setMenuDisplay(
  //       menu.map((item) => {
  //         return checkRolesMenu(item);
  //       })
  //     );
  //   }
  // }, []);

  // const checkRolesMenu = (item) => {
  //   if (
  //     authUser.roles === item.permission ||
  //     item.permission === MenuPermission.public
  //   ) {
  //     if (Array.isArray(item.children)) {
  //       const newChildren = item?.children?.map((child) => {
  //         return checkRolesMenu(child);
  //       });
  //       return {
  //         ...item,
  //         children: newChildren,
  //       };
  //     } else {
  //       return item;
  //     }
  //   } else {
  //     return null;
  //   }
  // };

  const onLogout = () => {
    confirm({
      title: 'Bạn chắc chắn muốn đăng xuất?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        dispatch(logout()).then(() => {
          window.location.href = '/signup';
        });
      },
    });
  };

  // const onClickMenu = (key) => {
  //   history.push(key);
  // };

  return (
    <Header>
      <Row justify={'space-between'} style={{ margin: '0 15px' }}>
        <Col className="logo">
          <img
            className="logo"
            src={require('../assets/img/logo.png')}
            alt="Logo"
            onClick={() => history.push('/')}
          ></img>
        </Col>

        <Col>
          <Popover
            trigger="click"
            className="header-actions"
            placement="bottomRight"
            content={
              <>
                <Row gutter={[20, 20]}>
                  <Col>
                    <Row align="middle" justify="space-around">
                      {authUser.image ? (
                        <Avatar src={authUser.image} />
                      ) : (
                        <Avatar style={{ backgroundColor: '#f56a00' }}>
                          {authUser.lastname.charAt(0)}
                        </Avatar>
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <Text strong>
                      {authUser.lastname + ' ' + authUser.firstname}
                    </Text>
                  </Col>
                </Row>
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <Link to="/app/profile">
                  <Button type="link">
                    <IntlMessages id="global.account_information" />
                  </Button>
                </Link>
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <Button type="link" onClick={() => onLogout()}>
                  <Space>
                    <IntlMessages id="global.signout" />
                  </Space>
                </Button>
              </>
            }
            title={null}
          >
            {authUser.image ? (
              <Avatar src={authUser.image} />
            ) : (
              <Avatar style={{ backgroundColor: '#f56a00' }}>
                {authUser.lastname.charAt(0)}
              </Avatar>
            )}
          </Popover>
        </Col>
      </Row>
    </Header>
  );
}

export default injectIntl(withRouter(AppHeader));
