import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import IntlMessage from '../../components/IntlMessage';
import { Button, Form, Input, Modal, Tabs } from 'antd';
import { useIntl } from 'react-intl';
import {
  MinusCircleOutlined,
  PhoneTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import customersApi from '../../api/customers';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const { TextArea } = Input;
const CustomerForm = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const intl = useIntl();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const initialValues = {
    phone_code: '+84',
  };

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const data = await customersApi.getDetail(id);
        if (data) {
          const res = data;
          setItem(res);
          form.setFieldsValue({
            name: res && res.name ? res.name : '',
            mobile: res && res.mobile ? res.mobile : '',
            note: res && res.note ? res.note : '',
            car_number:
              res && res.car_number
                ? Array.isArray(res.car_number)
                  ? res.car_number
                  : JSON.parse(res.car_number)
                : [],
          });
        }
      }
    }

    fetchData();
  }, [id]);

  const isValidPhone = (phone) =>
    /^(0?)(3[2-9]|5[2|5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/.test(
      phone
    );

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        let data = values;
        data.car_number = JSON.stringify(data.car_number);

        if (id) {
          delete data.password;

          await customersApi
            .update(id, data)
            .then(() => {
              setLoading(false);
              history.push('/app/customers-manager');
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: 'global.close' }),
              });
              setLoading(false);
            });
          setLoading(false);
        } else {
          await customersApi
            .create(data)
            .then(() => {
              setLoading(false);
              history.push('/app/customers-manager');
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: 'global.close' }),
              });
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    setLoading(false);
  };

  const onFailed = (error) => {
    Modal.error({
      title: error.errorFields[0].errors[0],
    });
  };

  const tabs = [
    {
      key: 1,
      label: <IntlMessage id="driver.info" />,
      forceRender: true,
      children: (
        <>
          <Form.Item name="name" label={<IntlMessage id="driver.last_name" />}>
            <Input placeholder="Nhập tên..." />
          </Form.Item>
          <Form.Item
            name="mobile"
            label={<IntlMessage id="global.mobile" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.required" />,
              },
              {
                min: 9,
                max: 10,
                message: 'Không hợp lệ!!!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (isValidPhone(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Số điện thoại không hợp lệ!!');
                  }
                },
              }),
            ]}
          >
            <Input
              prefix={<PhoneTwoTone className="site-form-item-icon" />}
              placeholder="Nhập số điện thoại"
            />
          </Form.Item>
          <Form.List name="car_number">
            {(fields, { add, remove }) => (
              <Form.Item label="biển số">
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key} style={{ display: 'flex', gap: '0 10px' }}>
                    <Form.Item
                      {...restField}
                      name={[name, 'car_number']}
                      // label={index === 0 ? 'Biển số' : ''}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessage id="global.requiredfield" />,
                        },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input
                        placeholder="Biển số..."
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    <IntlMessage id="customers.add_car_number" />
                  </Button>
                </Form.Item>
              </Form.Item>
            )}
          </Form.List>
          <Form.Item name="note" label={<IntlMessage id="global.note" />}>
            <TextArea rows={3} placeholder="Ghi chú..." />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      {id ? (
        <PageTitle
          title={<IntlMessage id="customers.edit_title" />}
        ></PageTitle>
      ) : (
        <PageTitle
          title={<IntlMessage id="customers.create_title" />}
        ></PageTitle>
      )}
      <Form
        {...layout}
        initialValues={initialValues}
        form={form}
        labelAlign="left"
        onFinishFailed={onFailed}
        onFinish={handleSubmit}
      >
        <Tabs items={tabs} />
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          style={{ width: '100%' }}
          // onClick={handleSubmit}
        >
          <IntlMessage id="global.save" />
        </Button>
      </Form>
    </>
  );
};

export default CustomerForm;
