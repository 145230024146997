import OneSignalReact from 'react-onesignal';
import subscribersApi from '../../api/subscribers';
import config from '../../config';

export default async function runOneSignal(userId) {
  // window.OneSignal = window.OneSignal || [];
  // await OneSignalReact.init({
  //     allowLocalhostAsSecureOrigin: true,
  //     appId: config.ONESIGNAL_APP_KEY,
  //     safari_web_id: "web.onesignal.auto.01b20842-ed7c-48c4-bd42-e78491d78625",
  //     notifyButton: {
  //         enable: true,
  //     },
  //     subdomainName: "pointhub-me",
  // });
  await OneSignalReact.init({
    appId: config.ONESIGNAL_APP_KEY,
    allowLocalhostAsSecureOrigin: true,
  });

  OneSignalReact.showSlidedownPrompt();

  OneSignalReact.registerForPushNotifications();
  OneSignalReact.on('notificationPermissionChange', async function(
    permissionChange
  ) {
    var currentPermission = permissionChange.to;
    console.log('New permission state:', currentPermission);
    if (currentPermission === 'granted') {
      console.log(
        'New permission state:',
        OneSignalReact.isPushNotificationsEnabled()
      );
      if (OneSignalReact.isPushNotificationsEnabled()) {
        let deviceId = await OneSignalReact.getUserId();
        if (deviceId) {
          subscribersApi
            .create({ user_id: userId, device_id: deviceId })
            .then((res) => console.log('Register success'))
            .catch((error) => console.log('Failed!', error.message));
        }
      } else {
        console.log('Device ID is null');
      }
      // let deviceId = await OneSignalReact.getUserId()
      // subcribersApi
      //     .create({ user_id: userId, device_id: deviceId })
      //     .then((res) => console.log("Register success"))
      //     .catch((error) => console.log("Failed!", error.message));
    }
  });
  if (OneSignalReact.isPushNotificationsEnabled()) {
    if (OneSignalReact.isPushNotificationsEnabled()) {
      let deviceId = await OneSignalReact.getUserId();
      if (deviceId) {
        subscribersApi
          .create({ user_id: userId, device_id: deviceId })
          .then((res) => console.log('Register success'))
          .catch((error) => console.log('Failed!', error.message));
      } else {
        console.log('Device ID is null');
      }
      // let deviceId = await OneSignalReactReact.getUserId()
      // console.log('New permission state:', OneSignalReact.isPushNotificationsEnabled());
      // subcribersApi
      //     .create({ user_id: userId, device_id: deviceId })
      //     .then((res) => console.log("Register success"))
      //     .catch((error) => console.log("Failed!", error.message));
    }
    // OneSignalReact.getUserId().then((userId) => console.log(userId))
  }
}
