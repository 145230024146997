import { Button, Form, Input, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import IntlMessage from '../../components/IntlMessage';
import usersApi from '../../api/users';
import PageTitle from '../../components/PageTitle';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { debounce } from 'lodash';
import { Roles } from '../../utils/constant';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import _ from 'lodash';
import postApi from '../../api/posts';

const PostForm = () => {
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [type, setType] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const filter = {
    limit: 9999,
    page: 1,
    order_by: 'id',
    order_dir: 'DESC',
  };

  useEffect(() => {
    async function fetchData() {
      const res = await usersApi.getAll(filter);
      const user_ids = res?.data?.map((item) => item.id);
      if (Array.isArray(res.data)) {
        setUserOptions(() => {
          const data = res.data.map((item) => ({
            value: item.id,
            label: item?.lastname,
          }));
          data.unshift({
            value: 'all',
            label: 'Tất cả',
          });
          return data;
        });
        setUserIds(user_ids);
      }
      if (id) {
        const message = await postApi.getDetail(id);
        if (message) {
          const listIds =
            message && message?.groups?.length > 0
              ? message.groups.map((item) => item.user_id)
              : [];
          // const isAll = isEqual(user_ids, listIds);
          setType(message?.is_all ? ['all'] : []);
          form.setFieldsValue({
            title: message && message.title ? message.title : '',
            message: message && message.content ? message.content : '',
            user_ids: message && message.is_all ? ['all'] : listIds,
          });
        }
      }
    }

    fetchData();
  }, []);

  const isEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    } else {
      for (const i of arr1) {
        if (arr2.indexOf(i) === -1) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSearchUser = debounce(async (value) => {
    const payload = {
      ...filter,
      keyword: value || '',
    };
    await usersApi.getAll(payload).then((res) => {
      if (Array.isArray(res.data)) {
        setUserOptions(() => {
          const data = res.data.map((item) => ({
            value: item.id,
            label: item?.lastname,
          }));
          data.unshift({
            value: 'all',
            label: 'Tất cả',
          });
          return data;
        });
      }
    });
  });

  const onFailed = (error) => {
    console.error(error);
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const data = { ...values };
        data.type = Roles.driver;
        data.content = values.message;
        delete data.message;
        if (type === 'all') {
          data.user_ids = userIds;
          data.is_all = true;
        } else {
          data.is_all = false;
        }
        // console.log(data);
        if (id) {
          await postApi
            .update(id, data)
            .then(() => {
              message.success({
                content: `Thành công!!`,
                className: 'ant-message',
                style: {
                  marginTop: '5vh',
                },
              });
              setLoading(false);
              history.push('/app/notifications-manager');
            })
            .catch((error) => {
              setLoading(false);

              console.error(error);
            });
        } else {
          await postApi
            .create(data)
            .then(() => {
              message.success({
                content: `Thành công!!`,
                className: 'ant-message',
                style: {
                  marginTop: '5vh',
                },
              });
              setLoading(false);
              history.push('/app/notifications-manager');
            })
            .catch((error) => {
              setLoading(false);

              console.error(error);
            });
        }
      })
      .catch((error) => {
        setLoading(false);

        console.error(error);
      });
  };

  const onChange = (value) => {
    const option = value.find((item) => item === 'all');
    // console.log(option);
    if (option) {
      setType(option);
    }
  };

  return (
    <>
      <PageTitle
        title={
          id ? (
            <IntlMessage id="post.edit_title" />
          ) : (
            <IntlMessage id="post.create_title" />
          )
        }
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onFailed}
      >
        <Form.Item
          name="title"
          label="Tiêu đề"
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <Input placeholder="Nhập tiêu đề..." />
        </Form.Item>
        <Form.Item
          name="message"
          label="Nội dung"
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
          valuePropName="data"
          getValueFromEvent={(event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data });
            return data;
          }}
        >
          <CKEditor editor={ClassicEditor} />
        </Form.Item>
        <Form.Item
          name="user_ids"
          label="Tài xế"
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <Select
            mode="multiple"
            filterOption={false}
            showSearch
            onSearch={handleSearchUser}
            options={userOptions}
            onChange={onChange}
            placeholder="Chọn tài xế"
          />
        </Form.Item>

        <Button
          loading={loading}
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
        >
          <IntlMessage id="global.save" />
        </Button>
      </Form>
    </>
  );
};

export default PostForm;
