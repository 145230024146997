import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const localTimezone = dayjs.tz.guess();
const offset = dayjs().utcOffset();

const this_year = dayjs().year();
const last_year = dayjs().year() - 1;
const next_year = dayjs().year() + 1;
const this_month = dayjs().month();
const last_month = this_month === 0 ? 11 : this_month - 1;
const next_month = this_month === 11 ? 0 : this_month + 1;
const today = dayjs();
const last_30_day = dayjs().subtract(30, 'day');
const last_7_day = dayjs().subtract(7, 'day');
const start_this_month = dayjs().startOf('month');
const end_this_month = dayjs().endOf('month');
const start_last_month = dayjs(
  `${this_month === 0 ? last_year : this_year}-${last_month + 1}`
).startOf('month');
const end_last_month = dayjs(
  `${this_month === 0 ? last_year : this_year}-${last_month + 1}`
).endOf('month');
const start_next_month = dayjs(
  `${this_month === 11 ? next_year : this_year}-${next_month + 1}`
).startOf('month');
const end_next_month = dayjs(
  `${this_month === 11 ? next_year : this_year}-${next_month + 1}`
).endOf('month');

// compare date
function convertDateToSortableFormat(dateString) {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

const formatAsISOString = (date) => {
  return dayjs(date)
    .utc(true)
    .tz(localTimezone)
    .toISOString();
};

const convertToAnyTimezone = (isoString) => {
  return dayjs(
    dayjs
      .utc(isoString)
      .utcOffset(offset, true)
      .format()
  );
};

const useTime = () => {
  return {
    localTimezone,
    offset,
    this_year,
    last_year,
    next_year,
    this_month,
    next_month,
    last_month,
    today,
    last_7_day,
    last_30_day,
    start_last_month,
    end_last_month,
    start_next_month,
    end_next_month,
    start_this_month,
    end_this_month,
    convertDateToSortableFormat,
    formatAsISOString,
    convertToAnyTimezone,
  };
};

export default useTime;
