export const GET_CONFIG = 'GET_CONFIG';
export const SET_CONFIG = 'SET_CONFIG';

//auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const GET_AUTH_PERMISSION = 'GET_AUTH_PERMISSION';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const GET_BANK = 'GET_BANK';
export const ADD_BANK = 'ADD_BANK';
export const DELETE_BANK = 'DELETE_BANK';
export const SET_DEFAULT_BANK = 'SET_DEFAULT_BANK';
export const GET_DRIVER_INFO = 'GET_DRIVER_INFO';

//permission
export const GET_ALL_PERMISSION = 'GET_ALL_PERMISSION';
export const CREATE_NEW_PERMISSION = 'CREATE_NEW_PERMISSION';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';

// Chat App Actions
export const CHAT_WITH_SELECTED_USER = 'CHAT_WITH_SELECTED_USER';
export const SEND_MESSAGE_TO_USER = 'SEND_MESSAGE_TO_USER';
export const GET_ALL_CONVERSATION_CHAT = 'GET_ALL_CONVERSATION_CHAT';
export const GET_ALL_RESERVATION_CONVERSATION_CHAT =
  'GET_ALL_RESERVATION_CONVERSATION_CHAT';
export const GET_USER_CONVERSATION_CHAT = 'GET_USER_CONVERSATION_CHAT';
export const GET_ALL_MESSAGES_CHAT = 'GET_ALL_MESSAGES_CHAT';
export const REPLY_CONVERSATION_CHAT = 'REPLY_CONVERSATION_CHAT';
export const SET_READ_CONVERSATION_CHAT = 'SET_READ_CONVERSATION_CHAT';
export const GET_NEW_MESSAGE_CONVERSATION_CHAT =
  'GET_NEW_MESSAGE_CONVERSATION_CHAT';

//inbox action
export const INBOX_WITH_SELECTED_USER = 'INBOX_WITH_SELECTED_USER';
export const INBOX_MESSAGE_TO_USER = 'INBOX_MESSAGE_TO_USER';
export const GET_ALL_MESSAGES_INBOX = 'GET_ALL_MESSAGES_INBOX';
export const SET_READ_CONVERSATION_INBOX = 'SET_READ_CONVERSATION_INBOX';
export const GET_NEW_MESSAGE_CONVERSATION_INBOX =
  'GET_NEW_MESSAGE_CONVERSATION_INBOX';
export const REMOVE_CURRENT_INBOX = 'REMOVE_CURRENT_INBOX';
export const REMOVE_CONVERSATION_INLIST_INBOX =
  'REMOVE_CONVERSATION_INLIST_INBOX';
export const OPEN_INBOX = 'OPEN_INBOX';
export const REPLY_INBOX_CHAT = 'REPLY_INBOX_CHAT';

//message
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const DELETE_MESSAGES = 'DELETE_MESSAGES';
export const SEND_MESSAGES = 'SEND_MESSAGES';
export const GET_ALL_MESSAGES_WITHOUT_PAGINATE =
  'GET_ALL_MESSAGES_WITHOUT_PAGINATE';

//messages
export const GET_ALL_SUBSCRIBERS = 'GET_ALL_SUBSCRIBERS';
export const GET_ALL_SUBSCRIBERS_WITHOUT_PAGINATE =
  'GET_ALL_SUBSCRIBERS_WITHOUT_PAGINATE';

//send message

export const GET_LIST_MESSAGES_CHAT = 'GET_LIST_MESSAGES_CHAT';
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const SEND_MESSAGES_CHAT = 'SEND_MESSAGES_CHAT';
export const GET_LIST_NEW_MESSAGES = 'GET_LIST_NEW_MESSAGES';
export const REPLY = 'REPLY';
export const RELOAD_MESSAGE = 'RELOAD_MESSAGE';

export const GET_SYSTEM_ERRORS = 'GET_SYSTEM_ERRORS';

//role action
export const GET_ALL_ROLE = 'GET_ALL_ROLE';
export const CREATE_NEW_ROLE = 'CREATE_NEW_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const GET_ALL_PERMISSION_OF_ROLE = 'GET_ALL_PERMISSION_OF_ROLE';
export const GET_ALL_ADD_PERMISSION_OF_USER = 'GET_ALL_ADD_PERMISSION_OF_USER';
export const UPDATE_ADD_PERMISSION_ROLE_USER =
  'UPDATE_ADD_PERMISSION_ROLE_USER';

//role action
export const GET_ALL_SPECIALITY = 'GET_ALL_SPECIALITY';
export const CREATE_NEW_SPECIALITY = 'CREATE_NEW_SPECIALITY';
export const UPDATE_SPECIALITY = 'UPDATE_SPECIALITY';
export const DELETE_SPECIALITY = 'DELETE_SPECIALITY';

// file
export const GET_LIST_FILE_AND_FOLDER = 'GET_LIST_FILE_AND_FOLDER';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DELETE_FOLDER_FILE = 'DELETE_FOLDER_FILE';

export const CHANGE_STATUS = 'CHANGE_STATUS';
export const GET_ALL_DESTINATION_PARENT = 'GET_ALL_DESTINATION_PARENT';
export const PUBLISH_STATUSES = 'PUBLISH_STATUSES';
export const UNPUBLISH_STATUSES = 'UNPUBLISH_STATUSES';

export const GET_ALL_PROVINCE = 'GET_ALL_PROVINCE';
export const GET_ALL_DISTRICT = 'GET_ALL_DISTRICT';
export const GET_ALL_WARD = 'GET_ALL_WARD';

//Promotion
export const GET_ALL_PROMOTION = 'GET_ALL_PROMOTION';
export const CREATE_NEW_PROMOTION = 'CREATE_NEW_PROMOTION';
export const UPDATE_PROMOTION = 'UPDATE_ALL_PROMOTION';
export const GET_PROMOTION_DETAIL = 'GET_PROMOTION_DETAIL';
export const DELETE_PROMOTION = 'DELETE_PROMOTION';

//patients
export const GET_ALL_PATIENTS = 'GET_ALL_PATIENTS';
export const CREATE_NEW_PATIENT = 'CREATE_NEW_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const GET_PATIENT_DETAIL = 'GET_PATIENT_DETAIL';
export const DELETE_PATIENT = 'DELETE_PATIENT';

// orders
export const UPDATE_CABINS_DEPARTURE_CREATE_ORDER =
  'UPDATE_CABINS_DEPARTURE_CREATE_ORDER';
export const UPDATE_ADDONS_DEPARTURE_CREATE_ORDER =
  'UPDATE_ADDONS_DEPARTURE_CREATE_ORDER';
export const UPDATE_CABINS_RETURN_CREATE_ORDER =
  'UPDATE_CABINS_RETURN_CREATE_ORDER';
export const UPDATE_ADDONS_RETURN_CREATE_ORDER =
  'UPDATE_ADDONS_RETURN_CREATE_ORDER';
export const UPDATE_SEARCH_ORDER_VALUES = 'UPDATE_SEARCH_ORDER_VALUES';
export const CLEAR_CREATE_ORDER_VALUES = 'CLEAR_CREATE_ORDER_VALUES';

export const UPDATE_CABINS_DEPARTURE_EDIT_ORDER =
  'UPDATE_CABINS_DEPARTURE_EDIT_ORDER';
export const UPDATE_ADDONS_DEPARTURE_EDIT_ORDER =
  'UPDATE_ADDONS_DEPARTURE_EDIT_ORDER';
export const UPDATE_CABINS_RETURN_EDIT_ORDER =
  'UPDATE_CABINS_RETURN_EDIT_ORDER';
export const UPDATE_ADDONS_RETURN_EDIT_ORDER =
  'UPDATE_ADDONS_RETURN_EDIT_ORDER';
export const UPDATE_SEARCH_EDIT_ORDER_VALUES =
  'UPDATE_SEARCH_EDIT_ORDER_VALUES';
export const CLEAR_EDIT_ORDER_VALUES = 'CLEAR_EDIT_ORDER_VALUES';
export const UPDATE_EDIT_ORDER_VALUES = 'UPDATE_EDIT_ORDER_VALUES';

export const UPDATE_ORDER_INFO = 'UPDATE_ORDER_INFO';
export const CLEAR_ORDER_INFO = 'CLEAR_ORDER_INFO';

export const UPDATE_STATUS_ORDER_WORKING = 'UPDATE_STATUS_ORDER_WORKING';
