import { Button, Form, Modal, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import driverApi from '../../api/driver';
import { debounce } from 'lodash';
import Search from 'antd/es/input/Search';
import IntlMessage from '../../components/IntlMessage';
import orderApi from '../../api/order';

const ModalChangeDriver = (props) => {
  const { open, item, onClose, onReload } = props;
  const [form] = Form.useForm();
  const [filter, setFilter] = useState({
    limit: 5,
    page: 1,
    keyword: '',
    order_by: 'id',
    order_dir: 'DESC',
  });
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await driverApi.getAllDriversIsActive(filter);
        if (res && Array.isArray(res.data)) {
          setItems(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const onSearchDriver = debounce(async (keyword) => {
    const filter = {
      limit: 5,
      page: 1,
      keyword,
      order_by: 'id',
      order_dir: 'DESC',
    };

    const res = await driverApi.getAllDriversIsActive(filter);
    if (Array.isArray(res.data)) {
      setItems(res.data);
    }
  }, 300);

  const onFinishFail = (error) => {
    console.error(error);
  };

  const onFinish = () => {
    setLoading(true);
    try {
      form
        .validateFields()
        .then(async (values) => {
          let data = { ...values };
          // console.log(data);
          await orderApi.changeDriver(item.id, data);
          setLoading(false);
          onClose();
          onReload();
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={[
        <Button type="primary" loading={loading} onClick={onFinish}>
          <IntlMessage id="global.save" />
        </Button>,
      ]}
    >
      <Space direction="vertical">
        <Search
          placeholder="Nhập tên tài xế hoặc sdt...."
          onChange={(e) => onSearchDriver(e.target.value)}
        />
        <Form form={form} onFinishFailed={onFinishFail} onFinish={onFinish}>
          <Form.Item name="driver_id">
            <Radio.Group>
              <Space direction="vertical">
                {items?.map((item) => {
                  return (
                    <>
                      <Radio key={item.id + item.lastname} value={item.id}>
                        {`${item.lastname} ~ ${item.mobile}`}
                      </Radio>
                    </>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

export default ModalChangeDriver;
