import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import driverApi from '../../api/driver';
import { CarOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import IntlMessage from '../../components/IntlMessage';
import moment from 'moment';

const Marker = ({ text, date }) => (
  <div style={markerStyle}>
    <CarOutlined style={{ fontSize: '18px', color: 'red' }} />
    <div style={{ fontWeight: '600' }}>{text}</div>
    {date && <div>{date}</div>}
  </div>
);
const markerStyle = {
  height: '100px',
  width: '100px',
  // marginTop: '-50px',
};
const DriversMap = (props) => {
  const [filter, setFilter] = useState({
    limit: 1000,
    page: 1,
    keyword: '',
    order_by: 'id',
    order_dir: 'DESC',
    filter_name: ['status', 'is_active'],
    filter_value: [1, 1],
  });
  const [listdriver, setListdriver] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await driverApi.getAll(filter);
      if (Array.isArray(res.data)) {
        setListdriver(res.data);
      }
    }

    fetchData();
  }, [filter]);

  const defaultProps = {
    center: {
      lat: 21.030653,
      lng: 105.84713,
    },
    zoom: 11,
  };
  return (
    <>
      <PageTitle title={<IntlMessage id="driver.title" />} />
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: '' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          {listdriver.length > 0
            ? listdriver.map((driver) => {
                return (
                  <Marker
                    key={driver.id}
                    lat={driver.lat}
                    lng={driver.long}
                    text={driver.lastname}
                    date={
                      driver.geo_update_at
                        ? moment(driver.geo_update_at).format(
                            'HH:mm - DD/MM/YYYY'
                          )
                        : undefined
                    }
                  />
                );
              })
            : null}
        </GoogleMapReact>
      </div>
    </>
  );
};
export default DriversMap;
