import { Button, Form, Modal, Radio, Select, Space } from 'antd';
import React from 'react';
import IntlMessage from '../../components/IntlMessage';
import { OrderStatus } from '../../utils/constant';
import orderApi from '../../api/order';

const ModalOrderStatus = (props) => {
  const { open, onClose, currentData, onReload } = props;
  const [form] = Form.useForm();
  const initialValue = {
    order_status: currentData?.order_status,
  };

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      const data = { ...values };
      await orderApi.changeStatus(currentData.id, data).then(() => {
        onReload();
        onClose();
      });
    });
  };

  const handleCancel = () => {
    onClose();
  };

  const onFinishFailed = (error) => {
    console.error(error);
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      footer={[
        <Button key={'cancel'} onClick={handleCancel}>
          hủy
        </Button>,
        <Button key={'ok'} type="primary" onClick={handleSubmit}>
          Lưu
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinishFailed={onFinishFailed}
        onFinish={handleSubmit}
        initialValues={initialValue}
      >
        <Form.Item
          name="order_status"
          label="Trạng thái"
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              {Object.keys(OrderStatus).map((key, index) => {
                if (
                  key !== OrderStatus.pending.status &&
                  key !== OrderStatus.completed.status &&
                  key !== OrderStatus.delivered.status
                ) {
                  return (
                    <Radio value={key} key={index}>
                      <IntlMessage id={`global.${key}`} />
                    </Radio>
                  );
                }
              })}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalOrderStatus;
