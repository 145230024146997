import React from 'react';
import { Input, Col, Row, Select, Button } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Option } = Select;

const FilterBar = (props) => {
  // states
  const {
    data,
    textSearch,
    onFilter,
    searchOption,
    justify,
    // showActionBar,
    colTextSearch,
    children,
    storage,
    placeholder,
    onSearch,
    isShowSearchButton,
  } = props;
  const intl = useIntl();

  const createData = (data) => {
    if (data.length) {
      const defaultOption = {
        name: 'name',
        col: 12,
        placeholder: 'Select',
        data: [],
        type: 'multiple',
      };
      return data.map((item) => {
        if (storage?.filter?.filter_name && storage?.filter?.filter_value) {
          const filter_name = storage?.filter?.filter_name;
          const filter_value = storage?.filter?.filter_value;
          if (Array.isArray(filter_name) && Array.isArray(filter_value)) {
            if (item?.name) {
              const index = _.findIndex(
                filter_name,
                (name) => name === item.name
              );
              if (index !== -1) {
                item['defaultValue'] = filter_value[index];
              }
            }
          }
        }

        return {
          ...defaultOption,
          ...item,
          onChange: (value) => onFilter(value, item.name, ''),
        };
      });
    }
    return [];
  };

  const createElement = (data) => {
    var dataElement = createData(data);
    if (dataElement?.length) {
      return dataElement?.map((item, index) => {
        return index === 0 ? (
          <Col sm={{ span: 12 }} xs={{ span: 12 }} key={index}>
            <Select
              mode={item.multiple ? 'multiple' : null}
              showSearch
              onSearch={item?.onSearch}
              placeholder={item?.placeholder}
              onChange={item?.onChange}
              style={{ width: '100%' }}
              allowClear={true}
              optionFilterProp='children'
              filterOption={
                item?.type === 'search'
                  ? false
                  : (input, option) => {
                      if (typeof option.children !== 'string') {
                        if (option?.children?.props?.id) {
                          return (
                            intl
                              .formatMessage({
                                id: option?.children?.props?.id,
                              })
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          );
                        } else {
                          return (
                            option.value
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          );
                        }
                      }
                      return (
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      );
                    }
              }
              defaultValue={item?.defaultValue}
            >
              {item?.data?.length &&
                item?.data?.map((option, i) => {
                  return (
                    <Option key={i} value={option?.id ? option?.id : ''}>
                      {option?.title}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        ) : (
          <Col sm={{ span: item?.col }} xs={{ span: 12 }} key={index}>
            <Select
              mode={item.multiple ? 'multiple' : null}
              showSearch
              onSearch={item?.onSearch}
              placeholder={item?.placeholder}
              onChange={item?.onChange}
              style={{ width: '100%' }}
              allowClear={true}
              optionFilterProp='children'
              filterOption={
                item?.type === 'search'
                  ? false
                  : (input, option) => {
                      if (typeof option.children !== 'string') {
                        if (option?.children?.props?.id) {
                          return (
                            intl
                              .formatMessage({
                                id: option?.children?.props?.id,
                              })
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          );
                        } else {
                          return (
                            option.value
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          );
                        }
                      }
                      return (
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      );
                    }
              }
              defaultValue={item?.defaultValue}
            >
              {item?.data?.length &&
                item?.data?.map((option, i) => {
                  return (
                    <Option key={i} value={option?.id ? option?.id : ''}>
                      {option?.title}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        );
      });
    }
    return null;
  };

  return (
    <Row type='flex' gutter={[8, 8]} justify={justify}>
      <Col sm={{ span: 24 }} xs={{ span: 24 }}>
        {textSearch ? (
          <Search
            name='search'
            className='txtSearch'
            placeholder={placeholder}
            allowClear
            onChange={(e) => onFilter(e.target.value, e.target.name, 'search')}
            style={{ width: '100%' }}
            {...searchOption}
            defaultValue={storage?.filter?.keyword}
          />
        ) : null}
      </Col>
      {createElement(data)}
      {children}
      {isShowSearchButton && (
        <Col>
          <Button icon={<SearchOutlined />} type='primary' onClick={onSearch} />
        </Col>
      )}
    </Row>
  );
};

FilterBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  textSearch: PropTypes.bool,
  onFilter: PropTypes.func,
  searchOption: PropTypes.object,
  justify: PropTypes.oneOf(['start', 'end', 'center', 'space-between']),
  showActionBar: PropTypes.bool,
  colTextSearch: PropTypes.number,
  onSearch: PropTypes.func,
};

FilterBar.defaultProps = {
  data: [],
  onFilter: () => {},
  textSearch: true,
  searchOption: {},
  justify: 'start',
  showActionBar: true,
  colTextSearch: 6,
  placeholder: 'Search...',
};

export default FilterBar;
