import { MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Drawer,
  Menu,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import IntlMessage from '../../components/IntlMessage';

const { Text } = Typography;

const MenuMobile = (props) => {
  const { style, menu, pathname, onClickMenu } = props;
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type='link'
        style={{ color: '#3e4095', ...style }}
        onClick={onOpen}
      >
        <MenuOutlined style={{ fontSize: '20px' }} />
      </Button>
      <Drawer
        width={'60%'}
        placement='left'
        closable={false}
        open={open}
        onClose={onClose}
      >
        <Space style={{ width: '100%' }} direction='vertical'>
          <Row>
            <Menu
              style={{ borderRight: 0 }}
              theme='light'
              mode='inline'
              selectedKeys={[pathname]}
              // style={{ borderRight: 0 }}
              onClick={({ item, key, keyPath }) => {
                onClickMenu(key);
                onClose();
              }}
              items={menu}
              // breakpoint='lg'
              // collapsedWidth='0'
            />
          </Row>
        </Space>
      </Drawer>
    </>
  );
};

export default MenuMobile;
