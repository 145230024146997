import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import IntlMessage from '../../components/IntlMessage';
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Tabs,
} from 'antd';
import { useIntl } from 'react-intl';
import PageTitle from '../../components/PageTitle';
import topUpApi from '../../api/topup';
import driverApi from '../../api/driver';
import { debounce } from 'lodash';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const TopUpForm = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const intl = useIntl();
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [type, setType] = useState('add');
  const [suggestions, setSuggestions] = useState([]);

  const onReload = () => {
    setReload((e) => !e);
  };

  useEffect(async () => {
    const filter_driver = {
      limit: 999,
      page: 1,
      order_by: 'id',
      order_dir: 'DESC',
    };
    const driverData = await driverApi.getAll(filter_driver);
    if (driverData?.data && Array.isArray(driverData?.data)) {
      setDrivers(
        driverData.data.map((item) => ({
          value: item?.id,
          label: `${item?.lastname} ~ ${item?.mobile}`,
        }))
      );
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const res = await topUpApi.getDetail(id);
        if (res) {
          form.setFieldsValue({
            amount: res && res.amount ? res.amount : 0,
            driver_id: res && res.driver ? res.driver.id : null,
            note: res && res.note ? res.note : null,
          });
        }
      }
    }

    fetchData();
  }, [id]);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        let data = values;
        if (type === 'deduction') {
          data.amount = -values.amount;
        }
        if (id) {
          await topUpApi
            .update(id, data)
            .then(() => {
              setLoading(false);
              history.goBack();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: 'global.close' }),
              });
              setLoading(false);
            });
        } else {
          await topUpApi
            .create(data)
            .then(() => {
              setLoading(false);
              history.push('/app/topup-manager');
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: 'global.close' }),
              });
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    setLoading(false);
  };

  const onFailed = (error) => {
    Modal.error({
      title: error.errorFields[0].errors[0],
    });
  };

  const handleChangeAmount = (value = 1) => {
    if (value && value > 0 && value < 10000) {
      const item = value.toString();
      setSuggestions([
        Number(item.padEnd(5, '0')),
        Number(item.padEnd(6, '0')),
        Number(item.padEnd(7, '0')),
      ]);
      return;
    }

    if (value && value > 10000) {
      setSuggestions([value * 10, value * 100, value * 1000]);
      return;
    }
  };

  const handleSetAmount = (item) => {
    if (item) {
      form.setFieldValue('amount', item);
      setSuggestions([]);
    }
  };

  const onSearchDriver = debounce(async (value) => {
    const filter_driver = {
      page: 1,
      limit: value === '' ? 10 : 9999,
      keyword: value || '',
      order_by: 'id',
      order_dir: 'DESC',
    };
    const res = await driverApi.getAll(filter_driver);
    const driverData = res?.data;
    if (Array.isArray(driverData)) {
      setDrivers(
        driverData.map((item) => ({
          value: item?.id,
          label: `${item?.lastname} ~ ${item?.mobile}`,
        }))
      );
      onReload();
    }
  }, 300);

  const onChangeTabs = (key) => {
    setType(key);
    form.resetFields();
  };

  const tabs = [
    {
      key: 'add',
      label: <IntlMessage id="topup.add" />,
      children: (
        <>
          <Form.Item
            name="driver_id"
            label={<IntlMessage id="topup.driver" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
            ]}
          >
            <Select
              options={drivers}
              placeholder="Chọn tài xế..."
              allowClear
              showSearch
              filterOption={false}
              onSearch={onSearchDriver}
            />
          </Form.Item>
          <Form.Item
            name="amount"
            label={<IntlMessage id="topup.amount" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
            ]}
          >
            <InputNumber
              min={1}
              placeholder="VNĐ"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              style={{ minWidth: 200 }}
              onChange={handleChangeAmount}
            />
          </Form.Item>
          {suggestions.length > 0 && (
            <Form.Item>
              <Space wrap>
                {suggestions.map((item) => {
                  return (
                    <Button
                      type="default"
                      onClick={() => handleSetAmount(item)}
                    >
                      {new Intl.NumberFormat('vi-VN').format(item)}
                    </Button>
                  );
                })}
              </Space>
            </Form.Item>
          )}
          <Form.Item name="note" label={<IntlMessage id="topup.note" />}>
            <Input.TextArea placeholder="Nhập ghi chú..." />
          </Form.Item>
        </>
      ),
    },
    {
      key: 'deduction',
      label: <IntlMessage id="topup.deduction" />,
      children: (
        <>
          <Form.Item
            name="driver_id"
            label={<IntlMessage id="topup.driver" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
            ]}
          >
            <Select
              options={drivers}
              placeholder="Chọn tài xế..."
              allowClear
              showSearch
              filterOption={false}
              onSearch={onSearchDriver}
            />
          </Form.Item>
          <Form.Item
            name="amount"
            label={<IntlMessage id="topup.amount" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
            ]}
          >
            <InputNumber
              min={1}
              placeholder="VNĐ"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              style={{ minWidth: 200 }}
              onChange={handleChangeAmount}
            />
          </Form.Item>
          {suggestions.length > 0 && (
            <Form.Item>
              <Space wrap>
                {suggestions.map((item) => {
                  return (
                    <Button
                      type="default"
                      onClick={() => handleSetAmount(item)}
                    >
                      {new Intl.NumberFormat('vi-VN').format(item)}
                    </Button>
                  );
                })}
              </Space>
            </Form.Item>
          )}
          <Form.Item name="note" label={<IntlMessage id="topup.note" />}>
            <Input.TextArea placeholder="Nhập ghi chú..." />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      {id ? (
        <PageTitle title={<IntlMessage id="topup.edit_title" />}></PageTitle>
      ) : (
        <PageTitle title={<IntlMessage id="topup.create_title" />}></PageTitle>
      )}
      <Form
        {...layout}
        form={form}
        labelAlign="left"
        layout="vertical"
        onFinishFailed={onFailed}
        onFinish={handleSubmit}
      >
        <Tabs
          items={tabs}
          destroyInactiveTabPane={true}
          onChange={onChangeTabs}
        />
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          style={{ width: '100%' }}
          // onClick={handleSubmit}
        >
          <IntlMessage id="global.save" />
        </Button>
      </Form>
    </>
  );
};

export default TopUpForm;
