import api from '../utils/api';
class TopupApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/topup', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllForDriver = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/topup/driver/' + id, { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/topup/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  create(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post('/topup', data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/topup/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getTotalTopupOfMonth(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/topup/total/month', { params: data });

        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  remove(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.delete('/topup/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

const topUpApi = new TopupApi();
export default topUpApi;
