import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import reportApi from '../../api/report';
import Currency from '../../components/Currency';
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/vi_VN';
import dayjs from 'dayjs';
import IntlMessage from '../../components/IntlMessage';
import { OrderType, fee } from '../../utils/constant';
import { formatAsISOString } from '../../helpers/time';

const { Text } = Typography;

const Revenue = () => {
  const [form] = Form.useForm();
  const [filter, setFilter] = useState({
    type: 'month',
    date_from: formatAsISOString(dayjs().subtract(4, 'month')),
    date_to: formatAsISOString(dayjs().endOf('month')),
  });
  const [orderAmount, setOrderAmount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [ratio, setRatio] = useState(null);
  const [type, setType] = useState('month');

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await reportApi
        .getOrder(filter)
        .then((res) => {
          if (res) {
            setOrderAmount(res.order);
          }
          if (res?.order?.length > 1 && type === 'month') {
            const data = res.order;
            // const end = data.length - 1;
            const ratio =
              ((data[0].count - data[1].count) / data[1].count) * 100;

            setRatio({
              message: `Tỷ lệ tăng/giảm so tháng ${
                data[1].month
              }: ${ratio.toFixed(2)}%`,
              color:
                ratio > 0 ? '#0dad0b' : ratio === 100 ? '#2db7f5' : '#ff4d4f',
            });
          } else {
            setRatio(null);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    fetchData();
  }, [filter, reload]);

  const onFilter = () => {
    form.validateFields().then((value) => {
      const data = {
        type,
        date_from: value?.date_from?.startOf(type)
          ? formatAsISOString(value?.date_from?.startOf(type))
          : null,
        date_to: value?.date_to?.endOf(type)
          ? formatAsISOString(value?.date_to?.endOf(type))
          : null,
        order_type: value?.order_type,
      };
      setFilter(data);
    });
  };

  const handleChangeType = (value) => {
    if (value?.target?.value) {
      setType(value?.target?.value);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const onReload = () => {
    setReload((e) => !e);
  };

  return (
    <Space direction="vertical" size={4} style={{ width: '100%' }}>
      <Row justify={'end'}>
        <Col>
          <Button type="primary" onClick={onReload}>
            Làm mới
          </Button>
        </Col>
      </Row>
      <Form form={form} onFinish={onFilter}>
        <Row gutter={8} justify={'start'}>
          <Col span={24}>
            <Form.Item name={'type'} initialValue={type}>
              <Radio.Group onChange={handleChangeType}>
                <Radio.Button value={'date'}>Ngày</Radio.Button>
                <Radio.Button value={'month'}>Tháng</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          {type === 'month' && (
            <Col>
              <Form.Item
                name="date_from"
                initialValue={dayjs().subtract(4, 'month')}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  locale={locale}
                  picker={type}
                  placeholder={'Từ tháng'}
                />
              </Form.Item>
            </Col>
          )}
          <Col>
            <Form.Item
              name="date_to"
              rules={[
                {
                  required: true,
                  message: <IntlMessage id="global.required" />,
                },
              ]}
              initialValue={dayjs()}
            >
              <DatePicker
                // disabledDate={disabledDate}
                locale={locale}
                picker={type}
                placeholder={type === 'month' ? 'đến tháng' : 'đến ngày'}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="order_type"
              rules={[
                {
                  required: false,
                  message: <IntlMessage id="global.required" />,
                },
              ]}
            >
              <Select
                allowClear
                options={[
                  {
                    value: OrderType.normal.status,
                    label: '1 chiều',
                  },
                  {
                    value: OrderType.block.status,
                    label: '2 chiều',
                  },
                ]}
                placeholder="Chiều đi"
              ></Select>
            </Form.Item>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary">
              <SearchOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
      {loading ? (
        <Spin style={{ width: '100%' }} />
      ) : orderAmount?.length > 0 ? (
        <>
          <Card>
            <Space direction="vertical">
              <Text style={{ fontSize: '20px', color: '#3e4095' }}>
                Tổng tiền:
              </Text>

              {orderAmount.map((item, index) => {
                return (
                  <Card key={index}>
                    <Row gutter={[16, 16]}>
                      <Col span={10}>
                        <Text strong>
                          {type === 'date' ? 'Ngày' : 'Tháng'} {item?.month}:
                        </Text>
                      </Col>
                      <Col span={14}>
                        <Text strong>
                          <Currency
                            style={{ color: 'green' }}
                            value={item?.total}
                          />
                        </Text>
                      </Col>
                      <Col span={10}>
                        <Text strong>Tổng cuốc:</Text>
                      </Col>
                      <Col span={14}>
                        <Text style={{ color: 'rgb(55, 101, 152)' }} strong>
                          {item?.count}
                        </Text>
                      </Col>

                      {type === 'month' && (
                        <>
                          <Col span={10}>
                            <Text strong>Tổng thu:</Text>
                          </Col>
                          <Col span={10}>
                            <Text strong>
                              <Currency
                                style={{ color: 'green' }}
                                value={item?.total * fee.percent}
                              />
                            </Text>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card>
                );
              })}
              {ratio && type === 'month' && (
                <Text style={{ color: ratio?.color }} strong>
                  {ratio?.message}
                </Text>
              )}
            </Space>
          </Card>
          <Card>
            <Space direction="vertical">
              <Text style={{ fontSize: '20px', color: '#3e4095' }}>
                Thông tin quỹ 10k:
              </Text>
              {orderAmount.map((item, index) => {
                return (
                  <Card bordered key={index}>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Text>
                          {type === 'date' ? 'Ngày' : 'Tháng'} {item?.month}:
                        </Text>
                      </Col>
                      <Col span={12}>
                        <Text strong>
                          <Currency value={item?.total_extra} />
                        </Text>
                      </Col>
                      {type === 'month' && (
                        <>
                          <Col span={12}>
                            <Text>Phí nền tảng:</Text>
                          </Col>
                          <Col span={12}>
                            <Text strong>
                              <Currency value={item?.count * fee.Platform} />
                            </Text>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card>
                );
              })}
            </Space>
          </Card>
        </>
      ) : (
        <Empty description="Không có dữ liệu" />
      )}
    </Space>
  );
};

export default Revenue;
