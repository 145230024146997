import api from '../utils/api';
class DriverApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/drivers', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  create = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post('/drivers', data);

        resolve(res.data);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };
  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch(`/drivers/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.delete(`/drivers/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/drivers/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  findNearestDrivers = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/drivers/nearest', {
          params,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  updateStatus = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/drivers/update-status/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  workingStatus = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/drivers/working/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  createInvite = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post('/orders/send-invite/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  findByUserId(id) {
    return new Promise((resolve, reject) => {
      return api
        .get('/drivers/info/' + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          // NotificationManager.error("Đã có lỗi xảy ra, vui lòng thử lại!");
          reject(err);
        });
    });
  }

  findAllDrivesEligible = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/drivers/eligible/list', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  findAllPaid = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/payment/driver/' + id, {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllDriversIsActive = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/drivers/is-active', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  driverBan = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/drivers/ban/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  driverUnban = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/drivers/unban/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const driverApi = new DriverApi();
export default driverApi;
