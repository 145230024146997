import { Button, Form, Modal, Radio, Space, Typography, message } from 'antd';
import React from 'react';
import IntlMessage from '../../components/IntlMessage';
import { surchargePrice } from '../../utils/constant';
import Currency from '../../components/Currency';
import orderApi from '../../api/order';

const ModalNight = (props) => {
  const { open, onClose, currentData, onReload } = props;
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const data = { ...values };
        await orderApi
          .nightSurcharge(currentData?.id, data)
          .then(async (values) => {
            onClose();
            message.success('Thành công!!');
            onReload();
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFailed = (error) => {
    console.error(error);
  };

  return (
    <Modal
      open={open}
      title={<IntlMessage id="follow.night_title" />}
      onCancel={onClose}
      onOk={handleSubmit}
      footer={[
        <Button onClick={onClose}>
          <IntlMessage id="global.cancel" />
        </Button>,
        <Button onClick={handleSubmit} type="primary" danger>
          <IntlMessage id="global.confirm" />
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} onFinishFailed={onFailed}>
        <Form.Item
          name="amount"
          label={<IntlMessage id="follow.select_region" />}
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio
                disabled={currentData?.distance >= 55}
                value={surchargePrice.night.innerCity}
              >
                Hà Nội: {surchargePrice.night.innerCity / 1000}k{' '}
                {`(Đón sau 22h15 cự ly < 50km)`}
              </Radio>
              {/* <Radio value={surchargePrice.night.suburb}>
                Ngoại thành - <Currency value={surchargePrice.night.suburb} />
              </Radio> */}
              <Radio disabled={true} value={surchargePrice.night.province}>
                Đi tỉnh: {surchargePrice.night.province / 1000}k (LX tự thu sau
                20h)
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalNight;
