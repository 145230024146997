import { OrderWorkingStatus } from '../../utils/constant';
import {
  CLEAR_ORDER_INFO,
  UPDATE_ORDER_INFO,
  UPDATE_STATUS_ORDER_WORKING,
} from '../types';

const INIT_STATE = {
  order: null,
  status: OrderWorkingStatus.waitConfirm,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_ORDER_INFO: {
      return { ...state, order: action.payload };
    }

    case CLEAR_ORDER_INFO: {
      return {
        ...state,
        order: action.payload,
        status: OrderWorkingStatus.waitConfirm,
      };
    }

    case UPDATE_STATUS_ORDER_WORKING: {
      return { ...state, status: action.payload };
    }

    default:
      return { ...state };
  }
};
