import api from '../utils/api';

class PostApi {
  getAll(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/posts', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getAllForAdmin(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/posts/admin', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getDetail(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/posts/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getAllForDriver(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/posts/driver', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  create(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post('/posts', data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  send(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/posts/send/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  remove(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.delete('/posts/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getCountMessage() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/posts/count');
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  readAll() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/posts/read-all');
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  read(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/posts/read/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  readAllAdmin() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/posts/read-all/admin');
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/posts/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

const postApi = new PostApi();
export default postApi;
