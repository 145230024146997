import React from 'react';
import { CarOutlined, UserOutlined } from '@ant-design/icons';
import GoogleMapReact from 'google-map-react';
import { Modal } from 'antd';
import moment from 'moment';

const CustomerMark = ({ text, date }) => (
  <div style={markerStyle}>
    <CarOutlined style={{ fontSize: '18px', color: 'red' }} />
    <div style={{ fontWeight: '600' }}>{text}</div>
    {date && <div>{date}</div>}
  </div>
);
const markerStyle = {
  height: '100px',
  width: '100px',
  // marginTop: '-50px',
};
const ModalMap = (props) => {
  const { openMap = false, handleClose, currentData } = props;
  if (!currentData) return null;
  const defaultProps = {
    center: {
      lat: Number(currentData.lat),
      lng: Number(currentData.long),
    },
    zoom: 17,
  };

  return (
    <Modal
      title="Map Address"
      open={openMap}
      onOk={handleClose}
      onClose={handleClose}
      onCancel={handleClose}
    >
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: '' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <CustomerMark
            text={currentData.lastname}
            date={
              currentData.geo_update_at
                ? moment(currentData.geo_update_at).format('HH:mm - DD/MM/YYYY')
                : undefined
            }
            lat={Number(currentData.lat)}
            lng={Number(currentData.long)}
          />
        </GoogleMapReact>
      </div>
    </Modal>
  );
};

export default ModalMap;
