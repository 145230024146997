import React, { useEffect, useState } from 'react';
import { Spin, Tag } from 'antd';
import {
  OrderStatus,
  PayStatus,
  OrderType,
  BillStatus,
  VoucherStatus,
  TransactionTypes,
  InviteStatus,
} from '../../utils/constant';
import IntlMessages from '../../components/IntlMessage';

const Status = (props) => {
  const { status, type } = props;
  const [value, setValue] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    if (status) {
      setValue(status);
    }
    if (type === 'order') {
      setColor(OrderStatus[status]?.color);
    } else if (type === 'type') {
      setColor(OrderType[status]?.color);
    } else if (type === 'bill') {
      setColor(BillStatus[status]?.color);
    } else if (type === 'voucher') {
      setColor(VoucherStatus[status]?.color);
    } else if (type === 'transaction') {
      setColor(TransactionTypes[status]?.color);
    } else if (type === 'invite') {
      setColor(InviteStatus[status]?.color);
    } else {
      setColor(OrderType[status]?.color);
    }
  }, [
    OrderStatus,
    PayStatus,
    OrderType,
    BillStatus,
    status,
    type,
    TransactionTypes,
  ]);

  return (
    <Spin spinning={value === '' || color === ''}>
      {value !== '' && color !== '' && (
        <Tag color={color}>
          <IntlMessages id={`global.${value}`} />
        </Tag>
      )}
    </Spin>
  );
};

export default Status;
