import { DatePicker, Form, Input, Modal } from 'antd';
import React from 'react';
import IntlMessage from '../../components/IntlMessage';
import driverApi from '../../api/driver';
import { formatAsISOString } from '../../helpers/time';

const ModalBan = (props) => {
  const { open, onClose, item, onReload } = props;
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const data = {
          ...values,
          ban_date: values?.ban_date
            ? formatAsISOString(values?.ban_date)
            : null,
        };
        if (item?.status) {
          await driverApi.driverBan(item.id, data).then(() => {
            onReload();
            onClose();
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinishFailed = (error) => {
    console.error(error);
  };

  return (
    <Modal
      title="Lý do kỷ luật"
      open={open}
      onCancel={onClose}
      onOk={handleSubmit}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          name="ban_date"
          label="Ngày gỡ"
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <DatePicker showTime placeholder="Chọn ngày giờ..." />
        </Form.Item>
        <Form.Item
          name="note"
          label="Lý do"
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Nhập lý do kỷ luật..." />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalBan;
