import React, { useEffect, useState } from 'react';
import reportApi from '../../api/report';
import { Card, Col, Row, Space, Tabs } from 'antd';
import PageTitle from '../../components/PageTitle';
import IntlMessage from '../../components/IntlMessage';
import Revenue from './Revenue';
import DriverRank from './DriverRank';
import DriverNew from './DriverNew';
import Fund from './Fund/Fund';
// import { Column } from '@ant-design/charts';

const Reports = () => {
  const tabs = [
    {
      key: '1',
      label: <IntlMessage id="report.title_order" />,
      children: <Revenue />,
    },
    {
      key: '2',
      label: <IntlMessage id="report.title_driver" />,
      children: <DriverRank />,
    },
    {
      key: '3',
      label: <IntlMessage id="report.title_driver_new" />,
      children: <DriverNew />,
    },
    {
      key: '4',
      label: <IntlMessage id="report.title_fund" />,
      children: <Fund />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <PageTitle title={<IntlMessage id="report.title" />} />
        </Col>
        <Col span={24}>
          <Tabs items={tabs} />
        </Col>
      </Row>
    </Space>
  );
};

export default Reports;
