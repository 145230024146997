import {
  UPDATE_CABINS_DEPARTURE_CREATE_ORDER,
  UPDATE_ADDONS_DEPARTURE_CREATE_ORDER,
  UPDATE_CABINS_RETURN_CREATE_ORDER,
  UPDATE_ADDONS_RETURN_CREATE_ORDER,
  UPDATE_SEARCH_ORDER_VALUES,
  CLEAR_CREATE_ORDER_VALUES,
} from '../types';
import _ from 'lodash';

const INIT_STATE = {
  departure: {
    cabins: [],
    addons: [],
  },
  return: {
    cabins: [],
    addons: [],
  },
  searchValues: {
    date: '',
    roundtrip: true,
    return_date: '',
    // pax: 1,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_CABINS_DEPARTURE_CREATE_ORDER: {
      let stateClone = _.cloneDeep(state);
      const { value, checked } = action.payload;
      const index = _.findIndex(stateClone.departure.cabins, {
        hop_id: value?.hop_id,
        cabin_id: value?.cabin_id,
      });
      if (!checked) {
        // console.log('unchecked');
        if (index !== -1) {
          // console.log('removed');
          _.remove(stateClone.departure.cabins, {
            hop_id: value?.hop_id,
            cabin_id: value?.cabin_id,
          });
        }
      } else {
        // console.log('checked');
        if (index !== -1) {
          _.assign(stateClone.departure.cabins[index], value);
        } else {
          // console.log('insert');
          stateClone.departure.cabins.push(value);
        }
      }
      return stateClone;
    }

    case UPDATE_ADDONS_DEPARTURE_CREATE_ORDER: {
      let stateClone = _.cloneDeep(state);
      const { value, checked } = action.payload;
      const index = _.findIndex(stateClone.departure.addons, {
        hop_id: value?.hop_id,
        addon_id: value?.addon_id,
      });
      if (!checked) {
        // console.log('unchecked');
        if (index !== -1) {
          // console.log('removed');
          _.remove(stateClone.departure.addons, {
            hop_id: value?.hop_id,
            addon_id: value?.addon_id,
          });
        }
      } else {
        // console.log('checked');
        if (index !== -1) {
          _.assign(stateClone.departure.addons[index], value);
        } else {
          // console.log('insert');
          stateClone.departure.addons.push(value);
        }
      }
      return stateClone;
    }

    case UPDATE_CABINS_RETURN_CREATE_ORDER: {
      let stateClone = _.cloneDeep(state);
      const { value, checked } = action.payload;
      const index = _.findIndex(stateClone.return.cabins, {
        hop_id: value?.hop_id,
        cabin_id: value?.cabin_id,
      });
      if (!checked) {
        // console.log('unchecked');
        if (index !== -1) {
          // console.log('removed');
          _.remove(stateClone.return.cabins, {
            hop_id: value?.hop_id,
            cabin_id: value?.cabin_id,
          });
        }
      } else {
        // console.log('checked');
        if (index !== -1) {
          _.assign(stateClone.return.cabins[index], value);
        } else {
          // console.log('insert');
          stateClone.return.cabins.push(value);
        }
      }
      return stateClone;
    }

    case UPDATE_ADDONS_RETURN_CREATE_ORDER: {
      let stateClone = _.cloneDeep(state);
      const { value, checked } = action.payload;
      const index = _.findIndex(stateClone.return.addons, {
        hop_id: value?.hop_id,
        addon_id: value?.addon_id,
      });
      if (!checked) {
        // console.log('unchecked');
        if (index !== -1) {
          // console.log('removed');
          _.remove(stateClone.return.addons, {
            hop_id: value?.hop_id,
            addon_id: value?.addon_id,
          });
        }
      } else {
        // console.log('checked');
        if (index !== -1) {
          _.assign(stateClone.return.addons[index], value);
        } else {
          // console.log('insert');
          stateClone.return.addons.push(value);
        }
      }
      return stateClone;
    }

    case UPDATE_SEARCH_ORDER_VALUES: {
      let stateClone = _.cloneDeep(state);
      _.assign(stateClone.searchValues, action.payload);
      return stateClone;
    }

    case CLEAR_CREATE_ORDER_VALUES: {
      return {
        departure: {
          cabins: [],
          addons: [],
        },
        return: {
          cabins: [],
          addons: [],
        },
        searchValues: {
          date: '',
          roundtrip: true,
          return_date: '',
          // pax: 1,
        },
      };
    }

    default: {
      return state;
    }
  }
};
