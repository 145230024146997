import { Col, Descriptions, Row, Space, Tag, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import orderApi from '../../api/order';
import Currency from '../../components/Currency';
import IntlMessage from '../../components/IntlMessage';
import {
  OrderStatus,
  OrderType,
  eventTime,
  surchargePrice,
} from '../../utils/constant';
import MenuAction from './MenuAction';
import ModalPoints from './ModalPoints';
import ModalNight from './ModalNight';
import { stringPad } from '../../helpers/stringPad';
import { convertToAnyTimezone, formatAsISOString } from '../../helpers/time';
import dayjs from 'dayjs';

const { Text, Paragraph } = Typography;

const OrderFollow = (props) => {
  const { currentData, onRefresh } = props;
  const [openNightModal, setOpenNightModal] = useState(false);
  const [openPoints, setOpenPoints] = useState(false);
  const [isBlock, setIsBlock] = useState(false);

  useEffect(() => {
    setIsBlock(currentData?.type === OrderType.block.status);
  }, [currentData?.order_status]);

  const onCloseNightModal = () => {
    setOpenNightModal(false);
  };

  const onOpenNightModal = () => {
    setOpenNightModal(true);
  };

  const onClosePoints = () => {
    setOpenPoints(false);
  };

  const onOpenPoints = () => {
    setOpenPoints(true);
  };

  const handleUpdateOrderStatus = async (value) => {
    const data = {
      order_status: value,
      date: formatAsISOString(dayjs()),
    };
    await orderApi.updateStatus(currentData.id, data);
    message.success('Thành công!!');
    onRefresh();
  };

  return (
    <>
      <Space direction="vertical">
        <Row justify="center">
          <Text className="title-receive">
            <IntlMessage id={`title.${currentData?.order_status}`} />
          </Text>
        </Row>
        <Row justify="center" gutter={[0, 15]}>
          <Descriptions bordered>
            <Descriptions.Item span={3} label="Mã lệnh">
              {currentData?.type === OrderType.normal.status
                ? `#1c${stringPad(currentData?.id)}`
                : `#2c${stringPad(currentData?.id)}`}
              

              <a href={`https://www.google.com/maps/dir/?api=1&origin=${currentData.from_add_lat},${currentData.from_add_lon}&destination=${currentData.to_add_lat},${currentData.to_add_lon}`} target='_blank'>Chỉ đường</a>
            </Descriptions.Item>
            {(currentData?.customer_new || currentData.is_proxy_call) && (
              <Descriptions.Item span={3} label="Loại">
                {currentData.is_proxy_call && (
                  <Tag color={'#ed4f7b'}>Gọi hộ</Tag>
                )}
                {currentData.customer_new && (
                  <Tag color={'#f50'}>Khách hàng mới</Tag>
                )}
              </Descriptions.Item>
            )}


            
            <Descriptions.Item label="Tên khách:" span={3}>
              {currentData?.customer?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Số khách:" span={3}>
              <Text>
                <a href={`tel:${currentData?.customer?.mobile}`}>
                  {currentData?.customer?.mobile}
                </a>
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={<IntlMessage id="global.customer_car_number" />}
              span={3}
            >
              <Space>
                {currentData?.customer?.car_number ? (
                  currentData?.customer?.car_number.includes('car_number') ? (
                    JSON.parse(currentData?.customer?.car_number).map(
                      (car, index) => {
                        return <Text key={index}>{car?.car_number}</Text>;
                      }
                    )
                  ) : (
                    <Text>{currentData?.customer?.car_number}</Text>
                  )
                ) : null}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item
              key={16}
              label={<IntlMessage id="global.from_date" />}
              span={2}
            >
              <Text>
                {convertToAnyTimezone(currentData?.date).format('DD-MM-YYYY')}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="giờ đi" span={2}>
              <Text>
                {convertToAnyTimezone(currentData?.date).format('HH:mm')}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={<IntlMessage id="global.from" />}
              span={3}
              style={{ fontWeight: 'bold', color: '#f44242' }}
            >
              {currentData?.from_add_text}
            </Descriptions.Item>
            {currentData?.route &&
              currentData?.route?.includes('route') &&
              JSON.parse(currentData?.route)?.map((item, index) => {
                return (
                  <Descriptions.Item
                    key={9}
                    label={`Điểm trả A${index + 1}`}
                    span={3}
                  >
                    {item?.route}
                  </Descriptions.Item>
                );
              })}
            <Descriptions.Item
              style={{ color: '#0987f3', fontWeight: 'bold' }}
              label={<IntlMessage id="global.to" />}
              span={3}
            >
              {currentData?.to_add_text}
            </Descriptions.Item>
            <Descriptions.Item label="Cước phí:" span={3}>
              <Currency value={currentData?.total} />
            </Descriptions.Item>
            {currentData?.order_status === OrderStatus.assigned.status ||
            currentData?.order_status === OrderStatus.confirmed.status ? (
              <>
                <Descriptions.Item label="Phụ phí:" span={3}>
                  <Space direction="vertical">
                    {!isBlock ? (
                      <>
                        <Text>
                          <IntlMessage id="surcharge.wait" />
                          <Currency value={surchargePrice.waiting} />
                          {'/h'}
                        </Text>
                        <Text>
                          <IntlMessage id="surcharge.points" />
                          <Currency value={surchargePrice.point} />
                          {'/điểm'}
                        </Text>
                        <Text>
                          <IntlMessage id="surcharge.night" />
                        </Text>
                        {Object.keys(surchargePrice.night).map((key) => (
                          <Text key={key}>
                            <IntlMessage id={'surcharge.' + key} />
                            <Currency value={surchargePrice.night[key]} />
                            {'/xe'}
                          </Text>
                        ))}
                      </>
                    ) : (
                      <Text>
                        <IntlMessage id="surcharge.overtime" />
                        <Currency value={surchargePrice.block.overTime} />
                        {'/h'}
                      </Text>
                    )}
                    {dayjs().isAfter(dayjs(eventTime.start)) &&
                      dayjs().isBefore(dayjs(eventTime.end)) && (
                        <Text>
                          <IntlMessage id="surcharge.lunar_new_year" />
                          <Currency value={surchargePrice.lunar_new_year} />
                          {'/chuyến'}
                        </Text>
                      )}
                  </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Ghi chú" span={3}>
                  <Paragraph>
                    {currentData?.note?.split('\n')?.map((note, i) => (
                      <React.Fragment key={i}>
                        {note}
                        <br />
                      </React.Fragment>
                    ))}
                  </Paragraph>
                </Descriptions.Item>
              </>
            ) : (
              <>
                <Descriptions.Item label="Phụ phí:" span={3}>
                  <Row gutter={15}>
                    {!isBlock ? (
                      <>
                        <Col span={24}>
                          <Space>
                            <Text>
                              <IntlMessage id="surcharge.wait" />
                            </Text>
                            <Text>
                              {Math.floor(
                                currentData?.waiting_duration / 60 / 60
                              )}
                              :
                              {Math.floor(currentData?.waiting_duration / 60) %
                                60}
                              :{currentData?.waiting_duration % 60}
                            </Text>
                            <Text>
                              <Currency
                                value={currentData?.waiting_surcharge}
                              />
                            </Text>
                          </Space>
                        </Col>
                        {dayjs().isAfter(dayjs(eventTime.start)) &&
                        dayjs().isBefore(dayjs(eventTime.end)) ? (
                          <Col span={24}>
                            <Space>
                              <Text>
                                <IntlMessage id="surcharge.lunar_new_year" />
                              </Text>
                              <Text>
                                <Currency
                                  value={currentData?.event_surcharge}
                                />
                              </Text>
                            </Space>
                          </Col>
                        ) : (
                          <Col span={24}>
                            <Space>
                              <Text>
                                <IntlMessage id="surcharge.points" />
                              </Text>
                              <Text>{currentData?.point} điểm</Text>
                              <Text>
                                <Currency value={currentData?.extra} />
                              </Text>
                            </Space>
                          </Col>
                        )}
                        <Col span={24}>
                          <Space>
                            <Text>
                              <IntlMessage id="surcharge.night" />
                            </Text>
                            <Text>
                              <Currency value={currentData?.night_surcharge} />
                            </Text>
                          </Space>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={24}>
                          <Space>
                            <Text>
                              <IntlMessage id="surcharge.overtime" />
                            </Text>
                            <Text>
                              {Math.floor(
                                currentData?.waiting_duration / 60 / 60
                              )}
                              :
                              {Math.floor(currentData?.waiting_duration / 60) %
                                60}
                              :{currentData?.waiting_duration % 60}
                            </Text>
                            <Text>
                              <Currency
                                value={currentData?.waiting_surcharge}
                              />
                            </Text>
                          </Space>
                        </Col>
                        {dayjs().isAfter(dayjs(eventTime.start)) &&
                          dayjs().isBefore(dayjs(eventTime.end)) && (
                            <Col span={24}>
                              <Space>
                                <Text>
                                  <IntlMessage id="surcharge.lunar_new_year" />
                                </Text>
                                <Text>
                                  <Currency
                                    value={currentData?.event_surcharge}
                                  />
                                </Text>
                              </Space>
                            </Col>
                          )}
                        <Col span={24}>
                          <Space>
                            <Text>
                              <IntlMessage id="surcharge.overnight" />
                            </Text>
                            <Text>
                              <Currency value={currentData?.night_surcharge} />
                            </Text>
                          </Space>
                        </Col>
                      </>
                    )}
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label="Ghi chú" span={3}>
                  <Paragraph>
                    {currentData?.note?.split('\n')?.map((note, i) => (
                      <React.Fragment key={i}>
                        {note}
                        <br />
                      </React.Fragment>
                    ))}
                  </Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label="Ghi chú Tài xế" span={3}>
                  <Paragraph>
                    {currentData?.driver_note?.split('\n')?.map((note, i) => (
                      <React.Fragment key={i}>
                        {note}
                        <br />
                      </React.Fragment>
                    ))}
                  </Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label="Tổng thu:" span={3}>
                  <Currency
                    style={{
                      color: '#11b112',
                      fontWeight: 'bold',
                    }}
                    value={
                      Number(currentData?.total) +
                      Number(currentData?.extra) +
                      Number(currentData?.night_surcharge) +
                      Number(currentData?.waiting_surcharge) +
                      Number(currentData?.event_surcharge)
                    }
                  />
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </Row>
        <MenuAction
          currentData={currentData}
          onReload={onRefresh}
          onOpenPoints={onOpenPoints}
          onOpenNightModal={onOpenNightModal}
          handleUpdateOrderStatus={handleUpdateOrderStatus}
        />
      </Space>

      <ModalPoints
        open={openPoints}
        onClose={onClosePoints}
        currentData={currentData}
        onReload={onRefresh}
      />

      <ModalNight
        open={openNightModal}
        onClose={onCloseNightModal}
        currentData={currentData}
        onReload={onRefresh}
      />
    </>
  );
};

export default OrderFollow;
