import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import locale from 'antd/es/date-picker/locale/vi_VN';
import dayjs from 'dayjs';
import reportApi from '../../api/report';
import { formatAsISOString } from '../../helpers/time';

const { Text } = Typography;

const DriverRank = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [filter, setFilter] = useState({
    page: 1,
    limit: null,
    date: dayjs()?.toISOString(),
  });
  const [driver, setDriver] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await reportApi
        .getDriverRank(filter)
        .then((res) => {
          if (res) {
            setDriver(res.data);
            if (Array.isArray(res.data)) {
              setTotalOrder(
                res.data.reduce((total, value) => {
                  return total + value?.total_order;
                }, 0)
              );
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    fetchData();
  }, [filter, reload]);

  const onFilter = () => {
    form.validateFields().then((value) => {
      const data = {
        ...filter,
        date: value?.date_from ? formatAsISOString(value?.date_from) : null,
      };
      setFilter(data);
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleChangeSize = (pageSize) => {
    setFilter((item) => ({
      ...item,
      limit: pageSize,
    }));
  };

  return (
    <Space direction="vertical" size={4} style={{ width: '100%' }}>
      <Row justify={'end'}>
        <Col>
          <Button type="primary" onClick={onReload}>
            Làm mới
          </Button>
        </Col>
      </Row>
      <Form form={form} onFinish={onFilter}>
        <Row gutter={8} justify={'start'}>
          <Col>
            <Form.Item name="date_from" initialValue={dayjs()}>
              <DatePicker
                disabledDate={disabledDate}
                locale={locale}
                picker="month"
              />
            </Form.Item>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary">
              <SearchOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <Text style={{ fontSize: '20px', color: '#3e4095' }}>
            Tổng số cuốc: {totalOrder}
          </Text>
        </Col>
      </Row>
      <Row justify={'end'}>
        <Col>
          <Select
            allowClear
            style={{ minWidth: '50px' }}
            onChange={handleChangeSize}
            placeholder="Chọn top..."
            options={[
              { value: 5, label: 'Top 5' },
              { value: 10, label: 'Top 10' },
              { value: 20, label: 'Top 20' },
              { value: 50, label: 'Top 50' },
            ]}
          />
        </Col>
      </Row>
      {loading ? (
        <Spin style={{ width: '100%' }} />
      ) : driver?.length > 0 ? (
        driver.map((item, index) => {
          return (
            <Card key={index}>
              <Row>
                <Col span={6}>
                  <Tag
                    color={
                      index === 0
                        ? '#f3d551'
                        : index === 1
                        ? '#a6a6a6'
                        : index === 2
                        ? '#b27941'
                        : null
                    }
                  >{`#${index + 1}`}</Tag>
                </Col>
                <Col span={14}>
                  <Text strong>{item?.lastname}</Text>
                </Col>
                <Col span={4}>
                  <Text strong>{item?.total_order}</Text>
                </Col>
              </Row>
            </Card>
          );
        })
      ) : (
        <Empty description="Không có dữ liệu" />
      )}
    </Space>
  );
};

export default DriverRank;
