export const OrderStatus = {
  pending: {
    status: 'pending',
    color: '#2db7f5',
  },
  completed: {
    status: 'completed',
    color: '#87d068',
  },
  confirmed: {
    status: 'confirmed',
    color: '#3da54c',
  },
  cancelled: {
    status: 'cancelled',
    color: 'error',
  },
  assigned: {
    status: 'assigned',
    color: '#f50',
  },
  pickup_point: {
    status: 'pickup_point',
    color: '#066',
  },
  picked: {
    status: 'picked',
    color: '#066',
  },
  delivered: {
    status: 'delivered',
    color: '#066',
  },
  // refund: {
  //   status: 'refund',
  //   color: 'error',
  // },
};

export const OrderType = {
  block: {
    status: 'block',
    color: '#066',
  },
  normal: {
    status: 'normal',
    color: '#2db7f5',
  },
};

export const OrderWorkingStatus = {
  waitConfirm: 'wait_confirm',
  confirm: 'confirm',
  arrived: 'arrived',
  completed: 'completed',
  picked: 'picked',
};

export const PayStatus = {
  pending: {
    status: 'pending',
    color: 'red',
  },
  paid: {
    status: 'paid',
    color: 'cyan',
  },
  deposited: {
    status: 'deposited',
    color: 'geekblue',
  },
  // refund: {
  //   status: 'refund',
  //   color: 'error',
  // },
  // excess: {
  //   status: 'excess',
  //   color: 'gold',
  // },
};

export const Roles = {
  super: 'super',
  admin: 'admin',
  staff: 'staff',
  driver: 'driver',
  operator: 'operator',
  public: 'public',
};

export const MenuPermission = {
  super: 'super',
  public: 'public',
  admin: 'admin',
  driver: 'driver',
};

export const DriverStatus = {
  active: true,
  inactive: false,
};

export const socials = {
  facebook: 'facebook',
  twitter: 'twitter',
  tiktok: 'tiktok',
  instagram: 'instagram',
};
export const site_params = [
  'meta_title',
  'meta_description',
  'meta_keywords',
  'before_head',
  'before_body',
];

export const post_params = ['meta_description', 'meta_keywords'];

export const menu_params = ['meta_description', 'meta_keywords'];

export const BillTypes = {
  income: {
    status: 'income',
    color: 'geekblue',
  },
  expense: {
    status: 'expense',
    color: 'cyan',
  },
};

export const TransactionTypes = {
  debit: {
    status: 'debit',
    color: 'geekblue',
  },
  pay: {
    status: 'pay',
    color: 'cyan',
  },
};

export const BillStatus = {
  pending: {
    status: 'pending',
    color: '#2db7f5',
  },
  approved: {
    status: 'approved',
    color: '#87d068',
  },
};

export const InvoiceStatus = {
  pending: {
    status: 'pending',
    color: '#2db7f5',
  },
  approved: {
    status: 'approved',
    color: '#87d068',
  },
};

export const CabinPriceTypes = {
  cabin: 'cabin',
  seat: 'seat',
};

export const LocalStorageKeys = {
  order_list: 'order_list',
  driver_list: 'driver_list',
  customer_list: 'customer_list',
  notification_manager: 'notification_manager',
};

export const dataSchema_type = [
  'TouristDestination',
  'TouristAttraction',
  'LandmarksOrHistoricalBuildings',
  'TouristTrip',
  'Review',
  'FAQPage',
  'Hotel',
  'HotelRoom',
];

export const VoucherStatus = {
  not_send: {
    status: 'not_send',
    color: '#2db7f5',
  },
  sent: {
    status: 'sent',
    color: '#87d068',
  },
};

export const AccountType = {
  debt: '131',
  revenue: '511',
  cash: '111',
};

export const ActionTypes = {
  create_booking: 'Create booking',
  amend_booking: 'Amend booking',
  payment_income: 'Payment',
  payment_expense: 'Refund',
  penalty: 'Penalty',
  cancel_return_trip: 'Cancel return trip',
  cancel_booking: 'Cancel booking',
  approve_payment_income: 'Approve payment',
  approve_payment_expense: 'Approve refund',
  refund_recording: 'Refund recording',
};

export const BlockType = {
  BLOCK: 'block',
  NORMAL: 'normal',
};

export const Channels = {
  order: {
    channel: 'order',
    event: {
      send_invite: 'send-invite',
      completed: 'completed',
      change_driver: 'change_driver',
    },
  },
  dinvite: {
    channel: 'dinvite',
    event: {
      accept_invite: 'accept-invite',
      reject_invite: 'reject-invite',
    },
  },
  notification: {
    channel: 'notification',
    event: {
      send_notification: 'send-notification',
    },
  },
};

export const DistancePrice = {
  block_1: 100000,
  block_2: 200000,
};

export const DistanceCoefficient = 1.4;

export const surchargeType = {
  waiting: 'waiting',
  night: 'night',
  point: 'points',
};

export const surchargePrice = {
  waiting: 50000, // Applies to Lunar New Year
  // waiting: 50000,
  point: 20000,
  night: {
    innerCity: 50000,
    // suburb: 50000,
    province: 100000,
  },
  lunar_new_year: 100000,
  block: {
    night: 300000,
    overTime: 70000,
  },
};

export const blockPrice = {
  8: 800000,
  4: 500000,
  24: 1200000,
};

export const InviteStatus = {
  pending: {
    status: 'pending',
    color: '#2db7f5',
  },
  accepted: {
    status: 'accepted',
    color: '#87d068',
  },
  rejected: {
    status: 'rejected',
    color: 'error',
  },
};

export const balance = {
  minimum: 150000,
  Recommendations: 300000,
};

export const paymentType = {
  order: 'order',
  topup: 'topup',
};

export const fee = {
  percent: 25 / 100,
  extra: 10000,
  Platform: 5000,
};

export const eventTime = {
  start: '2024-02-06',
  end: '2024-02-15',
};

export const orderCustomerNewNote =
  '- Gửi card trên xe cho khách.\n- Gửi card cho nhà hàng (nếu được)\n- Chú ý tương tác, hỗ trợ khách hàng.\n- Tư vấn tập chung vào đi block';
