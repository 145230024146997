import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import IntlMessage from '../../../components/IntlMessage';
import { Button, Form, Input, InputNumber, Modal, Radio, Tabs } from 'antd';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useIntl } from 'react-intl';
import billApi from '../../../api/bill';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const FormFund = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const res = await billApi.getDetail(id);
        console.log(res);
        if (res) {
          form.setFieldsValue({
            transaction_type: res && res.transaction_type ? 1 : 0,
            amount: res && res.amount ? res.amount : 0,
            note: res && res.note ? res.note : null,
          });
        }
      }
    }

    fetchData();
  }, [id]);

  const handleSubmit = () => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        let data = {
          ...values,
          amount: values?.transaction_type
            ? values?.amount
            : 0 - values?.amount,
        };

        if (id) {
          await billApi
            .update(id, data)
            .then(() => {
              setLoading(false);
              history.goBack();
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: 'global.close' }),
              });
              setLoading(false);
            });
          // console.log(data);
        } else {
          await billApi
            .create(data)
            .then(() => {
              setLoading(false);
              history.push('/app/reports');
            })
            .catch((error) => {
              console.error(error);
              Modal.error({
                title: error.data.message,
                okText: intl.formatMessage({ id: 'global.close' }),
              });
              setLoading(false);
            });
        }
        // console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    setLoading(false);
  };

  const onFailed = (error) => {
    Modal.error({
      title: error.errorFields[0].errors[0],
    });
  };

  const tabs = [
    {
      key: 1,
      label: <IntlMessage id="driver.info" />,
      forceRender: true,
      children: (
        <>
          <Form.Item
            name="transaction_type"
            label={<IntlMessage id="topup.driver" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
            ]}
            initialValue={0}
          >
            <Radio.Group>
              <Radio value={0}>Chi</Radio>
              <Radio value={1}>Nạp</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="amount"
            label={<IntlMessage id="topup.amount" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
            ]}
            initialValue={0}
          >
            <InputNumber
              min={1}
              placeholder="VNĐ"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              style={{ minWidth: 200 }}
            />
          </Form.Item>
          <Form.Item name="note" label={<IntlMessage id="topup.note" />}>
            <Input.TextArea placeholder="Nhập ghi chú..." />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      {id ? (
        <PageTitle title={<IntlMessage id="topup.edit_title" />}></PageTitle>
      ) : (
        <PageTitle title={<IntlMessage id="topup.create_title" />}></PageTitle>
      )}
      <Form
        {...layout}
        form={form}
        labelAlign="left"
        onFinishFailed={onFailed}
        onFinish={handleSubmit}
      >
        <Tabs items={tabs} />
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          style={{ width: '100%' }}
          // onClick={handleSubmit}
        >
          <IntlMessage id="global.save" />
        </Button>
      </Form>
    </>
  );
};

export default FormFund;
