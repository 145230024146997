import React, { useEffect } from 'react';
import { Layout, message } from 'antd';
// import { isMobile, MobileView } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
// import { UserOutlined } from '@ant-design/icons';
// import { useSelector, useDispatch } from 'react-redux';
// import { flow } from 'lodash-es';
// actions
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import Pusher from 'pusher-js';
import config from '../config';
import { Channels, Roles } from '../utils/constant';
import { useSelector } from 'react-redux';
// import AppFooter from './AppFooter';

const { Content, Footer, Sider } = Layout;
const pusher_app_key = config.PUSHER_APP_KEY;
const pusher = new Pusher(pusher_app_key, {
  cluster: 'ap1',
});

const AppLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.authUser);

  useEffect(() => {
    if (user?.roles !== Roles.driver) {
      const channel = pusher.subscribe(Channels.order.channel);
      channel.bind(Channels.order.event.completed, (data) => {
        // console.log(data);
        if (data && data.message) {
          message.info('Thông báo mới!!', 2);
        }
      });
    }
    return () => {
      pusher.unsubscribe(Channels.order.channel);
      pusher.unbind();
    };
  }, []);
  useEffect(() => {
    if (user?.roles !== Roles.driver) {
      const channel = pusher.subscribe(Channels.dinvite.channel);
      channel.bind(Channels.dinvite.event.accept_invite, (data) => {
        // console.log(data);
        if (data) {
          message.info('Thông báo mới!!', 2);
        }
      });
      channel.bind(Channels.dinvite.event.reject_invite, (data) => {
        // console.log(data);
        if (data) {
          message.info('Thông báo mới!!', 2);
        }
      });
    }
    return () => {
      pusher.unsubscribe(Channels.dinvite.channel);
      pusher.unbind();
    };
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', border: '1px solid #ccc' }}>
      <AppHeader></AppHeader>
      <Content
        style={{
          // margin: '16px 0',
          backgroundColor: 'white',
          overflow: 'auto',
        }}
      >
        <div
          className="site-layout-background"
          style={{ padding: '0 12px 16px', minHeight: 480 }}
        >
          {children}
        </div>
      </Content>
      <AppFooter></AppFooter>
    </Layout>
  );
};

export default withRouter(AppLayout);
