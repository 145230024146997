import { Button, Form, InputNumber, Modal, Typography, message } from 'antd';
import React from 'react';
import IntlMessage from '../../components/IntlMessage';
import { surchargePrice } from '../../utils/constant';
import orderApi from '../../api/order';

const { Text } = Typography;

const ModalPoints = (props) => {
  const { open, onClose, currentData, onReload } = props;
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const data = { ...values };
        data.extra = data.point * surchargePrice.point;
        await orderApi
          .extraSurcharge(currentData?.id, data)
          .then(async (values) => {
            onClose();
            message.success('Thành công!!');
            onReload();
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFailed = (error) => {
    console.error(error);
  };

  return (
    <Modal
      open={open}
      title={<IntlMessage id="follow.points_title" />}
      onCancel={onClose}
      onOk={handleSubmit}
      footer={[
        <Button key="cancel" onClick={onClose}>
          hủy
        </Button>,
        <Button key="ok" onClick={handleSubmit} type="primary" danger>
          xác nhận
        </Button>,
      ]}
    >
      <Text>Hệ thông yêu cầu không nhập quá 3 điểm dừng!!</Text>
      <Form form={form} onFinish={handleSubmit} onFinishFailed={onFailed}>
        <Form.Item
          name="point"
          label={<IntlMessage id="follow.points" />}
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
          initialValue={currentData?.point}
        >
          <InputNumber min={1} max={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalPoints;
