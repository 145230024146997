import React, { useEffect, useState } from 'react';
import IntlMessage from '../../components/IntlMessage';
import { Badge, Col, Row, Space, Tabs } from 'antd';
import PageTitle from '../../components/PageTitle';
import ListMessage from './ListMessage';
import OrderStatusMessage from './OrderStatusMessage';
import messageApi from '../../api/message';
import orderApi from '../../api/order';
import { getWithExpiry, setWithExpiry } from '../../utils/localStorage';
import { LocalStorageKeys } from '../../utils/constant';
import Post from '../post/Post';

const NotificationsManager = () => {
  const storage = getWithExpiry(LocalStorageKeys.notification_manager);

  const [totalMessage, setTotalMessage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const res = await orderApi.getQuantityDoing();
      if (res) {
        setTotalMessage(res?.total_message);
      }
    }
    fetchData();
  }, []);

  const handleResetTotal = () => {
    setTotalMessage(0);
  };

  const onChangeTab = (key) => {
    setWithExpiry(LocalStorageKeys.notification_manager, {
      ...storage,
      activeKey: key,
    });
  };

  const tabs = [
    {
      key: '1',
      label: (
        <Badge count={totalMessage}>
          <IntlMessage id="notification.order_status" />
        </Badge>
      ),
      children: <OrderStatusMessage onResetTotal={handleResetTotal} />,
    },
    {
      key: '2',
      label: <IntlMessage id="notification.manager" />,
      children: <ListMessage />,
    },
    {
      key: '3',
      label: <IntlMessage id="notification.post" />,
      children: <Post />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Col>
          <PageTitle title={<IntlMessage id="notification.title" />} />
        </Col>
      </Row>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={storage?.activeKey}
        items={tabs}
        onChange={onChangeTab}
      />
    </Space>
  );
};

export default NotificationsManager;
