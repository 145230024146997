import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Flex,
  Switch,
  Tag,
  Button,
  Tabs,
  Space,
  Badge,
  message,
  Modal,
  Spin,
} from 'antd';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-day-picker/lib/style.css';
import { useSelector } from 'react-redux';
import driverApi from '../../api/driver';
import Currency from '../../components/Currency';
import { useHistory } from 'react-router-dom';
import IntlMessage from '../../components/IntlMessage';
import { Channels, Roles, balance } from '../../utils/constant';
import Pusher from 'pusher-js';
import config from '../../config';
import dinvitesApi from '../../api/dinvites';

import _ from 'lodash';
import OrderFollow from '../order-follow';
import OrderHistory from '../orders/history';
import ReceiveOrder from '../receive-order/ReceiveOrder';
import messageApi from '../../api/message';
import postApi from '../../api/posts';
import Fluctuations from '../balance-fluctuations';
import { NotificationOutlined } from '@ant-design/icons';
import DriverReport from '../reports/driver';
import { convertToAnyTimezone } from '../../helpers/time';
import dayjs from 'dayjs';
import orderPriceApi from '../../api/order-price';

const { Text } = Typography;
const fontWeight = { fontWeight: 700 };
const pusher_app_key = config.PUSHER_APP_KEY;
const pusher = new Pusher(pusher_app_key, {
  cluster: 'ap1',
});

export default function HomeDriver() {
  const history = useHistory();
  const [driver, setDriver] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalMessage, setTotalMessage] = useState(0);
  const [totalPost, setTotalPost] = useState(0);
  const [reload, setReload] = useState(false);
  const [reloadNotification, setReloadNotification] = useState(false);
  const [order, setOrder] = useState(null);
  const [Dinvite, setDinvite] = useState(null);
  const user = useSelector((state) => state.auth.authUser);
  const [isBalance, setIsBalance] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await driverApi.findByUserId(user.id);
      if (res) {
        setDriver(res);
        setOrder(res.order);
        setIsBalance(res.balance < 80000);
        if (res?.ban_date && !res?.status) {
          const ban_date = convertToAnyTimezone(res?.ban_date);
          if (dayjs().isAfter(ban_date)) {
            await driverApi.driverUnban(res?.id).then((data) => {
              if (data?.code === 1) {
                setDriver({
                  ...res,
                  status: true,
                  ban_date: null,
                });
              }
            });
          }
        }
      }
      setLoading(false);
    }

    fetchData();
  }, [user, reload]);

  useEffect(() => {
    const load = (event) => {
      if (document.visibilityState !== 'hidden') {
        if (isBalance) {
          Modal.warning({
            title: (
              <Space direction="vertical">
                <Text strong>
                  Số dư hiện tại nhỏ hơn{' '}
                  <Text style={{ color: 'red' }}>80.000đ</Text>{' '}
                </Text>
                <Text strong>
                  Hãy nạp thêm tiền để có thể nhận nhiều cuốc hơn!!
                </Text>
              </Space>
            ),
          });
        }
      }
    };
    if (isBalance) {
      window.addEventListener('visibilitychange', load);
    } else {
      window.removeEventListener('visibilitychange', load);
    }
    return () => {
      window.removeEventListener('visibilitychange', load);
    };
  }, [isBalance]);

  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await orderApi.findByDriverID(user?.driver?.id);
  //     setOrder(res);
  //   }

  //   fetchData();
  // }, [reload]);

  useEffect(async () => {
    const channel = pusher.subscribe(Channels.order.channel);
    channel.bind(Channels.order.event.send_invite, async (data) => {
      // console.log(data);
      if (
        data &&
        data.drivers &&
        user &&
        user.driver &&
        user.roles === Roles.driver
      ) {
        const drivers = data.drivers;
        const isExist = _.find(
          drivers,
          (id) => Number(id) === user?.driver?.id
        );
        if (isExist) {
          const dinvite = await dinvitesApi.getDinviteById(user?.driver?.id);

          const prices = await orderPriceApi.getPrice();
          let order = dinvite[0].order;
          const price_setting = prices.find(segment => Number(segment.price) === Number(order.total));
           order =
             { ...order, price_setting: price_setting }
          
          setDinvite({ ...dinvite[0], order: order });
        }
      }
    });

    channel.bind(Channels.order.event.change_driver, (data) => {
      if (data) {
        onReload();
      }
    });

    return () => {
      pusher.unsubscribe(Channels.order.channel);
      pusher.unbind();
    };
  }, [pusher_app_key, user]);

  useEffect(() => {
    const channel = pusher.subscribe(Channels.notification.channel);
    channel.bind(Channels.notification.event.send_notification, (data) => {
      // console.log(data);
      if (data) {
        message.info('Có thông báo mới!!');
        setTotalMessage((item) => item + 1);
        setReloadNotification((e) => !e);
      }
    });

    return () => {
      pusher.unsubscribe(Channels.notification.channel);
      pusher.unbind();
    };
  }, [pusher_app_key, user]);

  useEffect(() => {
    async function fetchData() {
      // console.log('user: ', user);

      if (
        user &&
        user.roles === Roles.driver &&
        driver &&
        user?.driver?.is_active
      ) {
        const dinvite = await dinvitesApi.getDinviteById(user?.driver?.id);
        if (Array.isArray(dinvite) && dinvite.length > 0) {
          // console.log('dinvite', dinvite);



          const prices = await orderPriceApi.getPrice();
          let order = dinvite[0].order;
          const price_setting = prices.find(segment => Number(segment.price) === Number(order.total));
          order =
            { ...order, price_setting: price_setting }

          setDinvite({ ...dinvite[0], order: order });

         // setDinvite(dinvite[0]);
          // setOpenDinvite(true);
        } else {
          setDinvite(null);
        }
      } else {
        setDinvite(null);
      }
    }

    fetchData();
  }, [reload]);

  useEffect(() => {
    const channel = pusher.subscribe(Channels.dinvite.channel);
    channel.bind(Channels.dinvite.event.accept_invite, (data) => {
      if (data) {
        onReload();
        onCloseReceive();
      }
    });
    channel.bind(Channels.dinvite.event.reject_invite, (data) => {
      if (data) {
        onReload();
      }
    });
    return () => {
      pusher.unsubscribe(Channels.dinvite.channel);
      pusher.unbind();
    };
  }, []);

  useEffect(() => {
    async function getCountMessage() {
      const res = await messageApi.getCountMessage();
      const data = await postApi.getCountMessage();

      setTotalMessage(res || 0);
      setTotalPost(data || 0);
    }
    getCountMessage();
  }, [user, reloadNotification]);

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleWorkingStatus = async (status) => {
    const payload = {
      status,
    };
    await driverApi.workingStatus(user?.driver?.id, payload);
    onReload();
  };

  const getStatusText = () => {
    if (!driver?.user?.status) {
      return {
        backgroundColor: 'red',
        title: 'Bạn đã bị khóa',
        messages: (
          <Space direction="vertical">
            <Text>Hãy liên hệ D-car để mở khóa!!</Text>
            <Text>
              Zalo: <a href="https://zalo.me/0342448438">0342448438</a>
            </Text>
          </Space>
        ),
      };
    }
    if (!driver?.status) {
      return {
        backgroundColor: 'red',
        title: 'Bạn đang bị kỷ luật',
        messages: (
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Text strong>Hết hiệu lực:</Text>
            </Col>
            <Col span={16}>
              <Text>
                {driver?.ban_date
                  ? convertToAnyTimezone(driver?.ban_date).format(
                      'DD-MM-YYYY HH:mm'
                    )
                  : null}
              </Text>
            </Col>
            <Col span={8}>
              <Text strong>Lý do:</Text>
            </Col>
            <Col span={16}>
              <Text strong>
                {driver?.note?.split('\n')?.map((note, i) => (
                  <React.Fragment key={i}>
                    {note}
                    <br />
                  </React.Fragment>
                ))}
              </Text>
            </Col>
          </Row>
        ),
      };
    }
    if (!driver?.is_active) {
      return {
        backgroundColor: 'red',
        title: 'Không hoạt động',
        messages: (
          <>
            <Text>
              Bạn đang báo nghỉ, vì vậy sẽ không nhận được cuốc xe nào.
            </Text>
            <Text>
              Hãy chuyển trạng thái khi sẵn sàng để được nhận cuốc xe.
            </Text>
          </>
        ),
      };
    }
    if (driver?.is_active && driver.balance >= balance.minimum) {
      return {
        backgroundColor: '#87d068',
        title: 'Sẵn sàng nhận cuốc xe',
        messages: (
          <>
            <Text>Bạn đang online và có đủ số dư trong tài khoản.</Text>
            <Text>Hãy sẵn sàng nhận cuốc xe khi có khách hàng ở gần.</Text>
          </>
        ),
      };
    } else if (driver.balance < balance.minimum) {
      return {
        backgroundColor: '#eea02b',
        title: 'Số dư không đủ',
        messages: (
          <>
            <Text style={{ color: 'red', fontWeight: 'bold' }}>
              Ví của bạn không đủ số dư.
            </Text>{' '}
            <Text style={{ fontWeight: 'bold' }}>
              Bạn sẽ không nhận được cuốc xe. Vui lòng bấm NẠP TIỀN để bắt đầu
              nhận cuốc xe.
            </Text>
            <Text>
              Số dư tối thiểu: <Currency value={balance.minimum} />
            </Text>
            <Text>
              Số dư khuyến nghị: <Currency value={balance.Recommendations} />
              (có thể nhận 2 cuốc/ngày)
            </Text>
          </>
        ),
      };
    }
  };

  const onCloseReceive = () => {
    onReload();
  };

  const { backgroundColor, title, messages } = getStatusText();

  const handleChangeTab = (key) => {
    if (tabs[0].key === key) {
      onReload();
    }
  };

  const tabs = [
    {
      key: '1',
      label: (
        <Text style={fontWeight}>
          <IntlMessage id="global.home" />
        </Text>
      ),
      children: (
        <>
          <Row gutter={[15, 15]} justify="space-between">
            <Col>
              <Text style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {user?.lastname}
              </Text>
            </Col>
            <Col>
              <Flex justify="space-between">
                <Switch
                  value={driver?.is_active}
                  style={{ margin: 'auto' }}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  onChange={handleWorkingStatus}
                />
                <Badge count={totalPost}>
                  <Button
                    type="primary"
                    size="small"
                    shape="round"
                    onClick={() => {
                      history.push('/app/driver/post');
                    }}
                  >
                    <IntlMessage id="post.title" />
                  </Button>
                </Badge>
              </Flex>
            </Col>
            <Col>
              <Flex justify="space-around">
                <Badge count={totalMessage}>
                  <Button
                    type="primary"
                    size="small"
                    shape="round"
                    onClick={() => {
                      history.push('/app/driver/notification');
                    }}
                    style={{
                      backgroundColor: '#f44242',
                      boxShadow: '0px 0px 10px #f44242',
                    }}
                  >
                    Quy định
                  </Button>
                </Badge>
              </Flex>
            </Col>
          </Row>
          <br />
          <Row gutter={[15, 15]} justify={'space-between'}>
            <Col span={24}>
              <Tag
                color={driver.blance < balance.minimum ? '#eea02b' : '#87d068'}
                onClick={() => {
                  history.push('/app/balance-statistic');
                }}
                style={{
                  fontSize: '20px',
                }}
              >
                Số dư:{' '}
                <Currency
                  style={{
                    color: 'inherit',
                    fontSize: '20px',
                    fontWeight: 600,
                  }}
                  value={driver?.balance || 0}
                />
              </Tag>
            </Col>
            <Col>
              <Flex justify="center">
                <Button
                  type="primary"
                  // size="small"
                  onClick={() => {
                    history.push('/app/qr-topup');
                  }}
                >
                  Nạp tiền
                </Button>
              </Flex>
            </Col>
            <Col>
              <Flex justify="center">
                <Button
                  onClick={onReload}
                  type="primary"
                  // size="small"
                >
                  Làm mới
                </Button>
              </Flex>
            </Col>
          </Row>
          {loading ? (
            <Spin />
          ) : order ? (
            <Row justify="center">
              <OrderFollow currentData={order} onRefresh={onReload} />
            </Row>
          ) : Dinvite ? (
            <Row justify="center">
              <ReceiveOrder item={Dinvite} onRefresh={onReload} />
            </Row>
          ) : (
            <>
              <Row justify="center">
                <Text
                  style={{ backgroundColor, fontWeight: 'bold' }}
                  className="title-receive"
                >
                  {title}
                </Text>
              </Row>
              <br />
              <Space direction="vertical" size={1} style={{ fontWeight: 500 }}>
                {messages}
              </Space>
            </>
          )}

          {/* {openDinvite && (
            <ModalReceiveOrder
              open={openDinvite}
              item={Dinvite}
              onCloseReceive={onCloseReceive}
              onRefresh={onReload}
            />
          )} */}
        </>
      ),
    },
    {
      key: '2',
      label: (
        <Text style={fontWeight}>
          <IntlMessage id="sidebar.order_history" />
        </Text>
      ),
      children: <OrderHistory />,
    },
    // {
    //   key: '3',
    //   label: (
    //     <Badge count={totalMessage}>
    //       <Text style={fontWeight}>
    //         <IntlMessage id="sidebar.notification" />
    //       </Text>
    //     </Badge>
    //   ),
    //   children: (
    //     <Notification
    //       total={totalMessage}
    //       reload={reloadNotification}
    //       onReload={() => {
    //         setReloadNotification((e) => !e);
    //       }}
    //     />
    //   ),
    // },
    {
      key: '4',
      label: (
        <Text style={fontWeight}>
          <IntlMessage id="sidebar.fluctuations" />
        </Text>
      ),
      children: <Fluctuations driver={driver} />,
    },
    {
      key: '5',
      label: (
        <Text style={fontWeight}>
          <IntlMessage id="sidebar.driver_report" />
        </Text>
      ),
      children: <DriverReport />,
    },
  ];
  return (
    <>
      <Tabs
        items={tabs}
        destroyInactiveTabPane={true}
        onChange={handleChangeTab}
      />
      <a
        style={{ position: 'fixed', right: 5, bottom: 50 }}
        href="https://zalo.me/0342448438"
      >
        <img
          style={{ height: '50px' }}
          src={require('../../assets/img/zalo-icon.png')}
          alt="logo-zalo"
        />
      </a>
    </>
  );
}
