import api from '../utils/api';

class CustomersApi {
  getAll = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/customers', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  //   getGroup = () => {
  //     return new Promise(async (resolve, reject) => {
  //       try {
  //         const res = await api
  //           .get("/operators/group");
  //         resolve(res.data);
  //       } catch (error) {
  //         reject(error);
  //       }
  //     });
  //   };

  create = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .post('/customers', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  update = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch(`/customers/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  delete = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.delete(`/customers/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getDetail = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get(`/customers/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  updateCarNumber = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch(`/customers/update/car-number/${id}`, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  remove = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.delete(`/customers/${id}`);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
const customersApi = new CustomersApi();
export default customersApi;
