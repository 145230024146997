import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Typography,
  message,
  Radio,
  Checkbox,
  Select,
  Space,
  Tabs,
  Row,
  Col,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import IntlMessage from '../../../components/IntlMessage';
import CustomerPlacesAutoComplete from './CustomerPlacesAutoComplete';
import dayjs from 'dayjs';
import {
  MinusCircleOutlined,
  PhoneTwoTone,
  PlusOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import orderApi from '../../../api/order';
import {
  BlockType,
  OrderType,
  balance,
  blockPrice,
  orderCustomerNewNote,
} from '../../../utils/constant';
import {
  useParams,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min';
import PageTitle from '../../../components/PageTitle';
import { convertToAnyTimezone, formatAsISOString } from '../../../helpers/time';
import customersApi from '../../../api/customers';
import { debounce } from 'lodash';
import Search from 'antd/es/input/Search';
import driverApi from '../../../api/driver';
import Currency from '../../../components/Currency';
import orderPriceApi from '../../../api/order-price';

const { Text } = Typography;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { TextArea } = Input;

export default function CreateOrder() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();

  const isMount = useRef(false);
  const [loading, setLoading] = useState(false);
  const [isFromRequired, setIsFromRequired] = useState(true);
  const [isToRequired, setIsToRequired] = useState(true);
  const [isBlock, setIsBlock] = useState(false);
  const [fromPosition, setFromPosition] = useState({});
  const [toPosition, setToPosition] = useState({});
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [item, setItem] = useState({});
  const [distance, setDistance] = useState(null);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [reload, setReload] = useState(false);
  const [tabKey, setTabKey] = useState('1');
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await orderPriceApi.getPrice();
      if (Array.isArray(res)) {
        setPrices(res);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    isMount.current = true;
    if (isMount) {
      const payload = {
        page: 1,
        limit: 10000,
        keyword: '',
        order_by: 'id',
        order_dir: 'DESC',
      };
      Promise.all([customersApi.getAll(payload)])
        .then((res) => {
          const customersData = res[0]?.data;
          if (Array.isArray(customersData)) {
            setCustomerOptions(
              customersData.map((customer) => ({
                label: `${customer.name} - ${customer.mobile}`,
                value: customer.id,
              }))
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return () => {
      isMount.current = false;
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const res = await orderApi.getDetail(id);
        if (res) {
          setItem(res);
          res.type === OrderType.block.status && setIsBlock(true);
          setIsToRequired(false);
          setToAddress(res.to_add_text);
          setToPosition({
            lat: res.to_add_lat,
            lng: res.to_add_lon,
          });
          setIsFromRequired(false);
          setFromAddress(res.from_add_text);
          setFromPosition({
            lat: res.from_add_lat,
            lng: res.from_add_lon,
          });
          form.setFieldsValue({
            ...res,
            customer_id: res && res.customer ? res.customer.id : null,
            note: res && res.note ? res.note : '',
            to_add_text: res && res.to_add_text ? res.to_add_text : '',
            from_add_text: res && res.from_add_text ? res.from_add_text : '',
            block: res && res.block ? res.block : null,
            type: res && res.type ? res.type : OrderType.normal.status,
            total: res && res.total ? res.total : 0,
            distance: res && res.distance ? Number(res.distance) : 0,
            driver_note: res && res.driver_note ? res.driver_note : '',
            date: res && res.date ? convertToAnyTimezone(res.date) : dayjs(),
            route: res && res.route ? JSON.parse(res.route) : [],
          });
        }
      }
    }

    fetchData();
  }, [id]);

  useEffect(async () => {
    if (
      fromPosition &&
      fromPosition.lat &&
      fromPosition.lng &&
      toPosition &&
      toPosition.lat &&
      toPosition.lng
    ) {
      const value = await handleDistance();
      // console.log(value);
      if (value) {
        setDistance((prev) => {
          return value;
        });
        let hour = dayjs(form.getFieldValue('date')).hour();
        let block = form.getFieldValue('block');
        let type = form.getFieldValue('type');
        let total = getDistance(value, hour, type, block);
        form.setFieldsValue({
          total: total,
          distance: value,
        });
      }
    }
  }, [fromPosition, toPosition]);

  useEffect(async () => {
    try {
      const filter = {
        limit: 5,
        page: 1,
        order_by: 'id',
        order_dir: 'DESC',
      };

      const drivers = await driverApi.findAllDrivesEligible(filter);
      if (drivers && Array.isArray(drivers.data)) {
        setItems(drivers.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [reload]);

  const getDistance = (value, hour, type, block) => {
    // console.log(prices);
    let total;
    for (const price of prices) {
      if (value > price.from && value <= price.to) {
        total = price.price;
        break;
      }
    }

    if (type === BlockType.BLOCK && block === 4) {
      total = blockPrice[4];
    } else if (type === BlockType.BLOCK && block === 8) {
      total = blockPrice[8];
    } else if (type === BlockType.BLOCK && block === 24) {
      total = blockPrice[24];
    }
    // console.log(typeof total);
    return total;
  };

  const onFinishFailed = (error) => {
    console.error(error);
  };

  const isValidPhone = (phone) =>
    /^(0?)(3[2-9]|5[2|5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/.test(
      phone
    );

  const onFinish = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        if (isNewCustomer) {
          const new_customer = {
            name: values.customer_name ? values.customer_name.trim() : '',
            mobile: values.customer_mobile ? values.customer_mobile.trim() : '',
            car_number: values.customer_car_number
              ? JSON.stringify([
                  { car_number: values.customer_car_number.trim() },
                ])
              : '',
          };
          delete values['customer_id'];
          delete values['customer_name'];
          delete values['customer_mobile'];
          delete values['customer_car_number'];
          values['new_customer'] = new_customer;
        }

        const data = {
          ...values,
          from_add_text: fromAddress,
          to_add_text: toAddress,
          from_add_lat: fromPosition.lat,
          from_add_lon: fromPosition.lng,
          to_add_lat: toPosition.lat,
          to_add_lon: toPosition.lng,
          date: formatAsISOString(values.date),
          customer_new: isNewCustomer,
          route:
            values?.route && values.route.length > 0
              ? JSON.stringify(values.route)
              : null,
        };
        if (!Array.isArray(data.driver_ids)) {
          data.driver_ids = [data.driver_ids];
        }
        // console.log('data submit', data);

        if (id) {
          await orderApi
            .updateOrder(id, data)
            .then((value) => {
              message.success({
                content: `Thành công!!`,
                className: 'ant-message',
                style: {
                  marginTop: '5vh',
                },
              });
              setLoading(false);
              history.push('/app/orders');
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
            });
        } else {
          await orderApi
            .createOrder(data)
            .then((value) => {
              message.success({
                content: `Thành công!!`,
                className: 'ant-message',
                style: {
                  marginTop: '5vh',
                },
              });
              setLoading(false);
              history.push('/app/orders');
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onChangeAddressFrom = (position, address) => {
    if (position) {
      setIsFromRequired(false);
      setFromAddress(address);
      setFromPosition(position);
      // console.log('from: ', position, address);
    } else {
      setIsFromRequired(true);
      setFromAddress('');
      setFromPosition({});
    }
  };

  const onChangeAddressTo = (position, address) => {
    if (position) {
      setIsToRequired(false);
      setToAddress(address);
      setToPosition(position);
      // console.log('to: ', position, address);
    } else {
      setIsToRequired(true);
      setToAddress('');
      setToPosition({});
    }
  };

  const onChangeBlock = (e) => {
    let hour = dayjs(form.getFieldValue('date')).hour();
    let block = form.getFieldValue('block');
    let total = getDistance(distance, hour, e.target.value, block);
    if (e.target.value === BlockType.BLOCK) {
      setIsBlock(true);

      form.setFieldsValue({
        total: total,
      });
    } else {
      setIsBlock(false);
      form.setFieldsValue({
        total: total,
      });
    }
  };

  const onChangeBlockHour = (e) => {
    let hour = dayjs(form.getFieldValue('date')).hour();
    let type = form.getFieldValue('type');
    let block = e.target.value;
    let total = getDistance(distance, hour, type, block);
    form.setFieldsValue({
      total: total,
    });
  };

  const onCheckNewCustomer = (e) => {
    if (e.target.checked) {
      setIsNewCustomer(true);
      form.setFieldValue('note', orderCustomerNewNote);
    } else {
      setIsNewCustomer(false);
      form.setFieldValue('note', null);
    }
  };

  const handleSearchCustomer = debounce(async (value) => {
    const payload = {
      page: 1,
      limit: value === '' ? 10 : 9999,
      keyword: value || '',
      order_by: 'id',
      order_dir: 'DESC',
    };
    try {
      const res = await customersApi.getAll(payload);
      const customersData = res?.data;

      if (Array.isArray(customersData)) {
        setCustomerOptions(
          customersData.map((customer) => ({
            label: `${customer.name} - ${customer.mobile}`,
            value: customer.id,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, 300);

  const onChangeDistance = (value) => {
    let hour = dayjs(form.getFieldValue('date')).hour();
    let type = form.getFieldValue('type');
    let block = form.getFieldValue('block');
    let total = getDistance(value, hour, type, block);
    form.setFieldsValue({
      total: total,
    });
  };

  const handleSelectCustomer = (value) => {
    form.setFieldsValue({
      customer_id: value,
    });
  };
  const onReload = () => {
    setReload((e) => !e);
  };
  const onChangeTab = (key) => {
    if (key) {
      setTabKey(key);
    }
    if (key === tabs[0].key) {
      onSearchDriver('');
      return;
    }
    onFindNearestDriver();
  };

  const onFindNearestDriver = async () => {
    if (
      fromPosition &&
      fromPosition.lat &&
      fromPosition.lng &&
      toPosition &&
      toPosition.lat &&
      toPosition.lng
    ) {
      try {
        const filter = {
          count: 3,
          lat: item?.from_add_lat ? item?.from_add_lat : fromPosition.lat,
          lon: item?.from_add_lon ? item?.from_add_lon : fromPosition.lng,
        };

        const drivers = await driverApi.findNearestDrivers(filter);
        if (drivers && Array.isArray(drivers.data)) {
          setItems(drivers.data);
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }

    setItems([]);
  };

  const onSearchDriver = debounce(async (keyword) => {
    const filter = {
      limit: 5,
      page: 1,
      keyword,
      order_by: 'id',
      order_dir: 'DESC',
    };

    const res = await driverApi.findAllDrivesEligible(filter);
    if (Array.isArray(res.data)) {
      setItems(res.data);
    }
  }, 300);

  const reverseOriginAndDestination = () => {
    const intermediate = fromPosition;
    const intermediateAddress = fromAddress;
    setFromPosition(toPosition);
    setFromAddress(toAddress);
    setToPosition(intermediate);
    setToAddress(intermediateAddress);
  };

  const handleDistance = async () => {
    const maps = window.google.maps;

    const origin = new maps.LatLng(fromPosition.lat, fromPosition.lng);
    const destination = new maps.LatLng(toPosition.lat, toPosition.lng);
    let distance;

    const service = new maps.DistanceMatrixService();
    await service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
        if (status === window.google.maps.DistanceMatrixStatus.OK) {
          // console.log(response);
          const origins = response.originAddresses;
          // const destinations = response.destinationAddresses;

          for (let i = 0; i < origins.length; i++) {
            const results = response.rows[i].elements;
            for (let j = 0; j < results.length; j++) {
              const element = results[j];
              distance = element.distance.value;
            }
          }
        }
      }
    );

    // console.log(distance);
    return (distance / 1000).toFixed(2);
  };

  const tabs = [
    {
      key: '1',
      label: <IntlMessage id="sentDriver.tab_designate" />,
      children: (
        <Space direction="vertical">
          <Search
            placeholder="Nhập tên tài xế hoặc sdt...."
            onChange={(e) => onSearchDriver(e.target.value)}
          />
          <Form.Item
            name="driver_ids"
            // rules={[
            //   {
            //     required: true,
            //     message: <IntlMessage id="global.required" />,
            //   },
            // ]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {items?.map((item) => {
                  return (
                    <Radio key={`${item.id}-${item.mobile}`} value={item.id}>
                      {`${item.lastname} ~ ${item.mobile} (`}
                      <Currency
                        style={{
                          color:
                            item?.balance > balance.minimum ? 'green' : 'red',
                        }}
                        value={item?.balance}
                      />
                      {')'}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Space>
      ),
    },
    {
      key: '2',
      label: <IntlMessage id="sentDriver.tab_drivers" />,
      children: (
        <Form.Item name="driver_ids">
          <Checkbox.Group>
            <Space direction="vertical">
              {items?.map((item, index) => {
                return (
                  <Checkbox value={item.id} key={`${item.mobile}-${item.id}`}>
                    {`${item.lastname} ~ ${item.mobile}`}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title={
          id ? (
            <IntlMessage id="order.update_order" />
          ) : (
            <IntlMessage id="order.create_order" />
          )
        }
      />

      <Form
        {...layout}
        form={form}
        labelAlign="left"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        initialValues={{
          total: 0,
          new_customer_checked: false,
          customer_id: null,
        }}
      >
        <Form.Item>
          <Text strong>
            <IntlMessage id="order.info_address" />
          </Text>
        </Form.Item>

        <Form.Item
          name="from_add_text"
          label={<IntlMessage id="global.from" />}
          rules={[
            {
              required: isFromRequired,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <CustomerPlacesAutoComplete
            address={fromAddress}
            onChange={onChangeAddressFrom}
          />
        </Form.Item>

        <Form.Item>
          <Row justify={'end'}>
            <Col>
              <Button
                type="text"
                shape="round"
                onClick={reverseOriginAndDestination}
              >
                {' '}
                <SwapOutlined />
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="to_add_text"
          label={<IntlMessage id="global.to" />}
          rules={[
            {
              required: isToRequired,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <CustomerPlacesAutoComplete
            address={toAddress}
            onChange={onChangeAddressTo}
          />
        </Form.Item>

        <Form.Item label="Điểm trả(A1)">
          <Form.List name="route">
            {(fields, { add, remove }) => (
              <Form.Item>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key} style={{ display: 'flex', gap: '0 10px' }}>
                    <Form.Item
                      {...restField}
                      name={[name, 'route']}
                      // label={index === 0 ? 'Biển số' : ''}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessage id="global.requiredfield" />,
                        },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input
                        placeholder="Nhập điểm trả..."
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Thêm điểm
                  </Button>
                </Form.Item>
              </Form.Item>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item name="distance" label={<IntlMessage id="order.distance" />}>
          <InputNumber
            min={0}
            // placeholder="Đơn giá..."
            style={{ width: '100%' }}
            // formatter={(value) => `${value} km`}
            onChange={onChangeDistance}
          />
        </Form.Item>

        <Form.Item
          name={'type'}
          label={<IntlMessage id="order.type" />}
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
          initialValue={BlockType.NORMAL}
        >
          <Radio.Group onChange={onChangeBlock}>
            <Radio.Button value={BlockType.NORMAL}>1 chiều</Radio.Button>
            <Radio.Button value={BlockType.BLOCK}>2 chiều</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {isBlock ? (
          <Form.Item
            name={'block'}
            label={<IntlMessage id="order.hour" />}
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.required" />,
              },
            ]}
          >
            <Radio.Group onChange={onChangeBlockHour}>
              <Radio.Button value={4}>4 giờ</Radio.Button>
              <Radio.Button value={8}>8 giờ</Radio.Button>
              <Radio.Button value={24}>24 giờ</Radio.Button>
            </Radio.Group>
          </Form.Item>
        ) : null}

        <Form.Item
          name={'date'}
          label={<IntlMessage id="global.return_date" />}
          initialValue={dayjs()}
        >
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="DD/MM/YYYY HH:mm"
          />
        </Form.Item>

        <Form.Item>
          <Text strong>
            <IntlMessage id="order.info_customer" />
          </Text>
        </Form.Item>

        <Form.Item label={<IntlMessage id="order.is_new_customer" />}>
          <Space>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox onChange={onCheckNewCustomer}>Khách mới</Checkbox>
            </Form.Item>
            <Form.Item
              name="is_proxy_call"
              rules={[
                {
                  required: false,
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>Gọi hộ</Checkbox>
            </Form.Item>
          </Space>
        </Form.Item>

        {isNewCustomer ? (
          <>
            <Form.Item
              name="customer_name"
              label={<IntlMessage id="global.customer_name" />}
            >
              <Input placeholder="Nhập tên khách hàng..." />
            </Form.Item>

            <Form.Item
              name="customer_mobile"
              label={<IntlMessage id="global.customer_mobile" />}
              rules={[
                {
                  required: true,
                  message: <IntlMessage id="global.requiredfield" />,
                },
                {
                  min: 9,
                  max: 10,
                  message: 'Không hợp lệ!!!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (isValidPhone(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Số điện thoại không hợp lệ!!');
                    }
                  },
                }),
              ]}
            >
              <Input
                prefix={<PhoneTwoTone className="site-form-item-icon" />}
                placeholder="Nhập số điện thoại"
              />
            </Form.Item>

            <Form.Item
              name="customer_car_number"
              label={<IntlMessage id="order.customer_car_number" />}
            >
              <Input placeholder="Nhập biển số xe" />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label={<IntlMessage id="order.customer" />}
            name="customer_id"
            rules={[
              {
                required: true,
                message: <IntlMessage id="global.requiredfield" />,
              },
            ]}
          >
            <Select
              showSearch
              options={customerOptions}
              filterOption={false}
              onSearch={handleSearchCustomer}
              onChange={handleSelectCustomer}
              placeholder="Chọn khách hàng"
            />
          </Form.Item>
        )}

        <Form.Item
          name="total"
          label={<IntlMessage id="follow.sub_total" />}
          rules={[
            {
              required: true,
              message: <IntlMessage id="global.required" />,
            },
          ]}
        >
          <InputNumber
            min={0}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="Đơn giá..."
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item name="note" label={<IntlMessage id="global.note" />}>
          <TextArea rows={3} placeholder="Ghi chú..." />
        </Form.Item>

        {(items && !id) || (id && !item?.driver) ? (
          <Tabs
            activeKey={tabKey}
            items={tabs}
            destroyInactiveTabPane={true}
            onChange={onChangeTab}
          />
        ) : null}
        <Button
          loading={loading}
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
        >
          {id ? (
            <IntlMessage id="order.update_order" />
          ) : (
            <IntlMessage id="order.book" />
          )}
        </Button>
      </Form>
    </>
  );
}
