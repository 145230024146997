import Home from './home';
import { Roles } from '../utils/constant.js';
import CreateOrder from './orders/create';
import ListOrder from './orders/index.js';
import Drivers from './driver/index.jsx';
import DriverForm from './driver/DriverForm.jsx';
import OrderFollow from './order-follow/index.jsx';
import CustomerManager from './customers/index.js';
import CustomerForm from './customers/CustomerForm.jsx';
import DriversMap from './driver-map/index.jsx';
import TopUpManager from './top-up/index.js';
import TopUpForm from './top-up/TopUpForm.jsx';
import QRTopup from './qr-topup/index.js';
import NotificationsManager from './notification/Notifications-manager.jsx';
import NotificationForm from './notification/NotificationForm.jsx';
import OrderDetail from './orders/detail/index.jsx';
import Reports from './reports/index.js';
import Notification from './notification/index.jsx';
import FormFund from './reports/Fund/FormFund.jsx';
import ManagerPrice from './manager-price/index.jsx';
import BalanceStatistic from './balance-statistic/index.js';
import PostForm from './post/PostForm.jsx';
import ListPostDriver from './post/ListPostDriver.jsx';

export const routes = [
  {
    path: 'home',
    component: Home,
    permission: Roles.public,
  },
  {
    path: 'create-order',
    component: CreateOrder,
    permission: Roles.admin,
  },
  {
    path: 'edit-order/:id',
    component: CreateOrder,
    permission: Roles.admin,
  },
  {
    path: 'orders',
    component: ListOrder,
    permission: Roles.admin,
  },
  {
    path: 'orders/:id',
    component: OrderDetail,
    permission: Roles.public,
  },
  {
    path: 'drivers-manager',
    component: Drivers,
    permission: Roles.admin,
  },
  {
    path: 'create-driver',
    component: DriverForm,
    permission: Roles.admin,
  },
  {
    path: 'order-follow',
    component: OrderFollow,
    permission: Roles.driver,
  },
  {
    path: 'edit-driver/:driver_id',
    component: DriverForm,
    permission: Roles.admin,
  },
  {
    path: 'customers-manager',
    component: CustomerManager,
    permission: Roles.admin,
  },
  {
    path: 'customers-create',
    component: CustomerForm,
    permission: Roles.admin,
  },
  {
    path: 'customers-edit/:id',
    component: CustomerForm,
    permission: Roles.admin,
  },
  {
    path: 'driver-map',
    component: DriversMap,
    permission: Roles.admin,
  },
  {
    path: 'topup-manager',
    component: TopUpManager,
    permission: Roles.admin,
  },
  {
    path: 'topup-create',
    component: TopUpForm,
    permission: Roles.admin,
  },
  {
    path: 'topup-edit/:id',
    component: TopUpForm,
    permission: Roles.admin,
  },
  {
    path: 'qr-topup',
    component: QRTopup,
    permission: Roles.driver,
  },
  {
    path: 'notifications-manager',
    component: NotificationsManager,
    permission: Roles.admin,
  },
  {
    path: 'notification-create',
    component: NotificationForm,
    permission: Roles.admin,
  },
  {
    path: 'notification-edit/:id',
    component: NotificationForm,
    permission: Roles.admin,
  },
  {
    path: 'post-create',
    component: PostForm,
    permission: Roles.admin,
  },
  {
    path: 'post-edit/:id',
    component: PostForm,
    permission: Roles.admin,
  },
  {
    path: 'reports',
    component: Reports,
    permission: Roles.admin,
  },
  {
    path: 'driver/notification',
    component: Notification,
    permission: Roles.driver,
  },
  {
    path: 'driver/post',
    component: ListPostDriver,
    permission: Roles.driver,
  },

  {
    path: 'fund-create',
    component: FormFund,
    permission: Roles.admin,
  },
  {
    path: 'fund-edit/:id',
    component: FormFund,
    permission: Roles.admin,
  },
  {
    path: 'change-price',
    component: ManagerPrice,
    permission: Roles.admin,
  },
  {
    path: 'Balance-statistic',
    component: BalanceStatistic,
    permission: Roles.driver,
  },
];
