import {
  Card,
  Col,
  Empty,
  Pagination,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import IntlMessage from '../../../components/IntlMessage';
import orderApi from '../../../api/order';
import Status from '../../../components/Status';
import { OrderType } from '../../../utils/constant';
import Currency from '../../../components/Currency';
import { useSelector } from 'react-redux';
import { stringPad } from '../../../helpers/stringPad';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { convertToAnyTimezone } from '../../../helpers/time';

const { Text } = Typography;

const OrderHistory = () => {
  const history = useHistory();
  const user = useSelector((state) => state.auth.authUser);
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    keyword: '',
    order_by: 'date',
    order_dir: 'DESC',
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await orderApi.orderHistoryForDriver(
        user?.driver?.id,
        filter
      );
      const data = res.data;
      if (res) {
        setTotal(res.total);
      }
      if (data && Array.isArray(data) && data.length > 0) {
        setItems(data);
      }
      setLoading(false);
    }

    fetchData();
  }, [filter]);

  const handleChangePage = (page) => {
    setFilter((item) => ({
      ...item,
      page: page ? page : 1,
    }));
  };

  const handleDetail = (id) => {
    history.push('/app/orders/' + id);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Col>
          <Text style={{ fontSize: '20px' }} strong>
            <IntlMessage id="order.title" />
          </Text>
        </Col>
      </Row>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin style={{ textAlign: 'center' }} />
        </div>
      ) : items?.length > 0 ? (
        <Space
          style={{ marginTop: '15px', width: '100%' }}
          direction="vertical"
        >
          {items?.map((item) => (
            <Card key={item?.id} style={{ width: '100%' }}>
              <Space direction="vertical" size={4}>
                <Space size={4}>
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.order_no" />:
                    </Text>
                  </div>

                  <Text
                    style={{ color: '#3e4095', cursor: 'pointer' }}
                    onClick={() => handleDetail(item?.id)}
                  >
                    {item?.type === OrderType.normal.status
                      ? `#1c${stringPad(item?.id)}`
                      : `#2c${stringPad(item?.id)}`}
                  </Text>
                </Space>
                <Space size={2}>
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.status" />:
                    </Text>
                  </div>
                  <Status type="order" status={item.order_status} />
                  <Status type="type" status={item.type} />
                  {item?.waiting && <Tag color="#f3d60b">chờ</Tag>}
                </Space>
                {item.customer_new ? (
                  <Tag
                    color={'#f50'}
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      transform: 'translateY(-50%)',
                    }}
                  >
                    Khách hàng mới
                  </Tag>
                ) : null}
                <Space size={2}>
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.from_date" />:
                    </Text>
                  </div>
                  <Text>
                    {convertToAnyTimezone(item?.date).format(
                      'DD-MM-YYYY HH:mm'
                    )}
                  </Text>
                </Space>
                {item.type === OrderType.block.status && (
                  <Space size={4}>
                    <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                      <Text strong>
                        <IntlMessage id="global.hour_group" />:
                      </Text>
                    </div>
                    <Text>{`${item.block}h`}</Text>
                  </Space>
                )}
                <Space size={4}>
                  <div style={{ flex: '0 0 auto', minWidth: '80px' }}>
                    <Text strong>
                      <IntlMessage id="global.total" />:
                    </Text>
                  </div>
                  <Text>
                    <Currency
                      value={
                        Number(item?.total) +
                        Number(item?.extra) +
                        Number(item?.night_surcharge) +
                        Number(item?.waiting_surcharge) +
                        Number(item?.event_surcharge)
                      }
                    />
                  </Text>
                 
                </Space>
              </Space>
            </Card>
          ))}
          <Pagination
            style={{ display: 'flex', justifyContent: 'center' }}
            current={filter.page}
            pageSize={filter.limit}
            onChange={handleChangePage}
            total={total}
          />
        </Space>
      ) : (
        <Empty description="Rất tiếc tài xế chưa thực hiện cuốc xe nào!" />
      )}
    </Space>
  );
};

export default OrderHistory;
