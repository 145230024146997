import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Pagination,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import locale from 'antd/es/date-picker/locale/vi_VN';
import dayjs from 'dayjs';
import reportApi from '../../api/report';
import { formatAsISOString } from '../../helpers/time';

const { Text } = Typography;

const DriverNew = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [filter, setFilter] = useState({
    date: dayjs()?.toISOString(),
    page: 1,
    limit: 10,
  });
  const [driver, setDriver] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await reportApi
        .getDriverNew(filter)
        .then((res) => {
          if (res) {
            setDriver(res.data);
            setTotal(res.total);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    fetchData();
  }, [filter, reload]);

  const onFilter = () => {
    form.validateFields().then((value) => {
      const data = {
        ...filter,
        date: value?.date_from ? formatAsISOString(value?.date_from) : null,
      };
      setFilter(data);
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleChangePage = (page, pageSize) => {
    setFilter((item) => ({
      ...item,
      page,
      limit: pageSize,
    }));
  };

  return (
    <Space direction="vertical" size={4} style={{ width: '100%' }}>
      <Row justify={'end'}>
        <Col>
          <Button type="primary" onClick={onReload}>
            Làm mới
          </Button>
        </Col>
      </Row>
      <Form form={form} onFinish={onFilter}>
        <Row gutter={8} justify={'start'}>
          <Col>
            <Form.Item name="date_from" initialValue={dayjs()}>
              <DatePicker
                disabledDate={disabledDate}
                locale={locale}
                picker="month"
              />
            </Form.Item>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary">
              <SearchOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
      {loading ? (
        <Spin style={{ width: '100%' }} />
      ) : driver?.length > 0 ? (
        driver.map((item, index) => {
          return (
            <Card key={index}>
              <Row>
                <Col span={20}>
                  <Text strong>{item?.lastname}</Text>
                </Col>
                <Col span={4}>
                  <Text strong>{item?.total_order}</Text>
                </Col>
              </Row>
            </Card>
          );
        })
      ) : (
        <Empty description="Không có dữ liệu" />
      )}
      <Row justify={'center'}>
        <Col>
          <Pagination
            current={filter.page}
            showSizeChanger
            pageSize={filter.limit}
            total={total}
            onChange={handleChangePage}
          />
        </Col>
      </Row>
    </Space>
  );
};

export default DriverNew;
