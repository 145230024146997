import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import MainApp from './container/MainApp';
import { NotificationContainer } from 'react-notifications';

import './assets/css/custom.css';
import 'react-notifications/lib/notifications.css';
import moment from 'moment';
import Wrapper from './lang';
import en_US from './lang/locales/en_US';
import dayjs from 'dayjs';
//moment.locale('vi');
moment.locale('vi');
dayjs.locale('vi');

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConfigProvider
          locale={en_US}
          theme={{
            token: {
              colorPrimary: '#3e4095',
            },
          }}
        >
          <BrowserRouter>
            <Wrapper>
              <MainApp />
            </Wrapper>
            <NotificationContainer />
          </BrowserRouter>
        </ConfigProvider>
      </Provider>
    );
  }
}
