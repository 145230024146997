import api from '../utils/api';

class ReportApi {
  getOrder(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/report/order', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getDriverRank(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/report/driver-rank', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getDriver(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/report/driver', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getDriverNew(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/report/driver-new', { params: data });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

const reportApi = new ReportApi();
export default reportApi;
