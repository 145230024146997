import {
  Button,
  Descriptions,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from 'antd';
import React, { memo } from 'react';
import IntlMessage from '../../components/IntlMessage';
import dinvitesApi from '../../api/dinvites';
import Currency from '../../components/Currency';
import dayjs from 'dayjs';
import { OrderType } from '../../utils/constant';
import { stringPad } from '../../helpers/stringPad';
import useTime from '../../hooks/useTime';


const { Text, Paragraph } = Typography;
const DesItem = Descriptions.Item;

const ReceiveOrder = (props) => {
  const { item, onRefresh } = props;
  const { convertToAnyTimezone } = useTime();

  console.log('item', item);
  const handleAccept = async () => {
    try {
      const res = await dinvitesApi.AcceptInvite(item?.id, {
        accept_date: dayjs().toISOString(),
      });
      if (res) {
        onRefresh();
      } else {
        message.error({
          content: 'Cuốc xe đã được tài xế khác nhận!',
        });
        onRefresh();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReject = async () => {
    try {
      Modal.confirm({
        title: 'Bạn có chắc chắn muốn từ chối??',
        onOk: async () => {
          await dinvitesApi.RejectInvite(item.id);
          onRefresh();
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Row justify="center">
        <Text className="title-receive">
          <IntlMessage id="receive.order" />
        </Text>
      </Row>
      <Space direction="vertical">
        <Row justify="center">
          <Descriptions bordered>
            <Descriptions.Item key={6} label="Mã lệnh" span={3}>
              <Text style={{ color: '#3e4095' }}>
                {item?.order?.type === OrderType.normal.status
                  ? `#1c${stringPad(item?.order?.id)}`
                  : `#2c${stringPad(item?.order?.id)}`}
              </Text>

              &nbsp;<a href={`https://www.google.com/maps/dir/?api=1&origin=${item?.order?.from_add_lat},${item?.order?.from_add_lon}&destination=${item?.order?.to_add_lat},${item?.order?.to_add_lon}`} target='_blank'> <strong>Chỉ đường</strong></a>
            </Descriptions.Item>
            {(item?.order?.is_proxy_call || item?.order?.customer_new) && (
              <Descriptions.Item span={3} label="Loại">
                {item?.order?.is_proxy_call && (
                  <Tag color={'#ed4f7b'}>Gọi hộ</Tag>
                )}
                {item?.order?.customer_new && (
                  <Tag color={'#f50'}>Khách hàng mới</Tag>
                )}
              </Descriptions.Item>
            )}
            <DesItem
              key={1}
              style={{ color: 'red', fontWeight: 'bold' }}
              label={<IntlMessage id="global.from" />}
              span={3}
            >
              {item?.order?.from_add_text}
            </DesItem>
            {item?.order?.route &&
              item?.order?.route?.includes('route') &&
              JSON.parse(item?.order?.route)?.map((item, index) => {
                return (
                  <Descriptions.Item
                    key={9}
                    label={`Điểm trả A${index + 1}`}
                    span={3}
                  >
                    <Text>{item?.route}</Text>
                  </Descriptions.Item>
                );
              })}
            <DesItem
              key={2}
              label={<IntlMessage id="global.to" />}
              span={3}
              style={{ color: '#0987f3', fontWeight: 'bold' }}
            >
              {item?.order?.to_add_text}
            </DesItem>
            <Descriptions.Item
              key={16}
              label={<IntlMessage id="global.from_date" />}
              span={2}
            >
              <Text>
                {convertToAnyTimezone(item?.order?.date).format('DD-MM-YYYY')}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item key={6} label="giờ đi" span={2}>
              <Text>
                {convertToAnyTimezone(item?.order?.date).format('HH:mm')}
              </Text>
            </Descriptions.Item>
            <DesItem key={3} label="Cước phí:" span={3}>
              <Currency
                style={{
                  color: '#11b112',
                  fontWeight: 'bold',
                }}
                value={item?.order?.total}
              />
             
              <Text style={{ textAlign: "end" }}>&nbsp;&nbsp; {item?.order.price_setting ? <Text>Cự ly: {item?.order.price_setting.from}-{item?.order.price_setting.to}km</Text> : null}</Text>
            </DesItem>
            <DesItem key={4} label="Trạng thái:" span={3}>
              <IntlMessage id={`order.${item?.order?.type}`} />
            </DesItem>
            <Descriptions.Item key={5} label="Ghi chú" span={3}>
              <Paragraph>
                {item?.note?.split('\n')?.map((note, i) => (
                  <React.Fragment key={i}>
                    {note}
                    <br />
                  </React.Fragment>
                ))}
              </Paragraph>
            </Descriptions.Item>
          </Descriptions>
        </Row>

        <Row justify="space-around">
          <Button onClick={handleAccept} type="primary">
            <IntlMessage id="receive.approve" />
          </Button>
          <Button onClick={handleReject} danger>
            <IntlMessage id="receive.reject" />
          </Button>
        </Row>
      </Space>
    </>
  );
};

export default memo(ReceiveOrder);
