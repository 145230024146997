import api from '../utils/api';

class MessageApi {
  getAll(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/messages', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getAllForAdmin(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/messages/admin', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getDetail(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/messages/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getAllForDriver(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/messages/driver', {
          params: data,
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  create(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post('/messages', data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  send(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/messages/send/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  remove(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.delete('/messages/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  getCountMessage() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('/messages/count');
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  readAll() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/messages/read-all');
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  read(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/messages/read/' + id);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  readAllAdmin() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/messages/read-all/admin');
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.patch('/messages/' + id, data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

const messageApi = new MessageApi();
export default messageApi;
