import React from 'react';
import IntlMessages from '../components/IntlMessage';
import {
  CloudDownloadOutlined,
  DashboardOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  ToolOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { MenuPermission } from '../utils/constant';

const menu = [
  {
    key: '/app/home',
    label: <IntlMessages id='global.home' />,
    icon: <HomeOutlined />,
    permission: MenuPermission.public,
    children: null,
  },
  {
    key: 'booking',
    label: <IntlMessages id='sidebar.orders' />,
    icon: <ShoppingOutlined />,
    permission: MenuPermission.admin,
    children: [
      {
        key: '/app/create-order',
        label: <IntlMessages id='global.orders' />,
        icon: <PlusCircleOutlined />,
        permission: MenuPermission.admin,
        children: null,
      },
      {
        key: '/app/orders',
        label: <IntlMessages id='sidebar.list_order' />,
        icon: <ShoppingCartOutlined />,
        permission: MenuPermission.admin,
        children: null,
      },
    ],
  },
  {
    key: 'drivers-manager',
    label: <IntlMessages id='sidebar.drivers-manager' />,
    icon: <DashboardOutlined />,
    permission: MenuPermission.admin,
    children: [
      {
        key: '/app/drivers-manager',
        label: <IntlMessages id='sidebar.drivers-manager' />,
        icon: <DashboardOutlined />,
        permission: MenuPermission.admin,
        children: null,
      },
      {
        key: '/app/create-driver',
        label: <IntlMessages id='sidebar.create_driver' />,
        icon: <UserAddOutlined />,
        permission: MenuPermission.admin,
        children: null,
      },
    ],
  },
  {
    key: 'customers-manager',
    label: <IntlMessages id='sidebar.customers-manager' />,
    icon: <DashboardOutlined />,
    permission: MenuPermission.admin,
    children: [
      {
        key: '/app/customers-manager',
        label: <IntlMessages id='sidebar.customers-manager' />,
        icon: <DashboardOutlined />,
        permission: MenuPermission.admin,
        children: null,
      },
      {
        key: '/app/create-driver',
        label: <IntlMessages id='sidebar.create_driver' />,
        icon: <UserAddOutlined />,
        permission: MenuPermission.admin,
        children: null,
      },
    ],
  },
  {
    key: '/app/order-follow',
    label: <IntlMessages id='sidebar.order_follow' />,
    icon: <CloudDownloadOutlined />,
    permission: MenuPermission.driver,
    children: null,
  },
  // {
  //   key: 'systems',
  //   label: <IntlMessages id='global.systems' />,
  //   icon: <ToolOutlined />,
  //   permission: MenuPermission.admin,
  //   children: [
  //     {
  //       key: '/app/users',
  //       label: <IntlMessages id='global.users' />,
  //       icon: <UsergroupAddOutlined />,
  //       permission: MenuPermission.admin,
  //       children: null,
  //     },
  //   ],
  // },
];

export default menu;
