import React, { useEffect, useState } from 'react';
import driverApi from '../../api/driver';
import {
  Button,
  Card,
  Col,
  Empty,
  Pagination,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import PageTitle from '../../components/PageTitle';
import IntlMessage from '../../components/IntlMessage';
import { paymentType } from '../../utils/constant';
import Currency from '../../components/Currency';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { convertToAnyTimezone } from '../../helpers/time';

const { Text } = Typography;

const Fluctuations = (props) => {
  const { driver } = props;
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState({
    limit: 20,
    page: 1,
  });
  const [total, setTotal] = useState(0);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await driverApi.findAllPaid(driver.id, filter);
      if (res && Array.isArray(res.data)) {
        setItems(res.data);
        setTotal(res.total);
        setBalance(res.total_topup - res.total_fee - res.total_extra);
      }
      setLoading(false);
    }
    fetchData();
  }, [filter]);

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  const handleDetail = (id) => {
    const newId = id.slice(4);
    history.push('/app/orders/' + newId);
  };

  return (
    <Space direction="vertical" style={{ display: 'block' }}>
      <Row justify="space-between">
        <Col>
          <Text style={{ fontSize: '20px' }} strong>
            <IntlMessage id="fluctuations.title" />
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Space>
            <Text style={{ fontSize: '20px' }} Strong>
              Số dư:
            </Text>
            <Text Strong>
              <Currency
                style={{
                  fontSize: '20px',
                  color: balance > 0 ? 'green' : 'red',
                }}
                value={balance}
              />
            </Text>
          </Space>
        </Col>
      </Row>
      <Row justify="center">
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin style={{ textAlign: 'center' }} />
          </div>
        ) : items?.length > 0 ? (
          <Space
            style={{ marginTop: '15px', width: '100%' }}
            direction="vertical"
          >
            {items?.map((item) => (
              <Card key={item?.obj_id} style={{ width: '100%' }}>
                <Space direction="vertical" size={4}>
                  {item?.type === paymentType.order ? (
                    <>
                      <Tag color="red">Trừ</Tag>
                      <Text onClick={() => handleDetail(item?.obj_id)}>
                        Mã lệnh:{' '}
                        <span style={{ color: '#3e4095' }}>{item?.obj_id}</span>
                      </Text>
                      <Text>
                        Tổng thanh toán: <Currency value={item?.total} />
                      </Text>
                      <Text strong>
                        Số tiền:{' '}
                        <span style={{ color: 'red' }}>
                          -{' '}
                          <Currency
                            style={{ color: 'red' }}
                            value={item?.amount}
                          />
                        </span>
                      </Text>
                      {item?.driver_balance && (
                        <Text strong>
                          Số dư còn:{' '}
                          <Currency
                            style={{
                              color:
                                Number(item?.driver_balance) > 0
                                  ? 'green'
                                  : 'red',
                            }}
                            value={Number(item?.driver_balance)}
                          />
                        </Text>
                      )}
                      <Text>
                        Ngày:{' '}
                        {convertToAnyTimezone(item?.time).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </Text>
                    </>
                  ) : (
                    <>
                      {Number(item.amount) > 0 ? (
                        <>
                          <Tag color="green">Cộng</Tag>
                          <Text strong>
                            Số tiền:{' '}
                            <span style={{ color: 'green' }}>
                              +{' '}
                              <Currency
                                style={{ color: 'green' }}
                                value={item?.amount}
                              />
                            </span>
                          </Text>
                        </>
                      ) : (
                        <>
                          <Tag color="red">Trừ</Tag>
                          <Text strong>
                            Số tiền:{' '}
                            <span style={{ color: 'red' }}>
                              <Currency
                                style={{ color: 'red' }}
                                value={item?.amount}
                              />
                            </span>
                          </Text>
                        </>
                      )}

                      {item?.driver_balance && (
                        <Text strong>
                          Số dư còn:{' '}
                          <Currency
                            style={{
                              color:
                                Number(item?.driver_balance) > 0
                                  ? 'green'
                                  : 'red',
                            }}
                            value={Number(item?.driver_balance)}
                          />
                        </Text>
                      )}
                      <Text>Note: {item?.message}</Text>
                      <Text>
                        Ngày: {dayjs(item?.time).format('DD/MM/YYYY HH:mm')}
                      </Text>
                    </>
                  )}
                </Space>
              </Card>
            ))}
            <Pagination
              style={{ display: 'flex', justifyContent: 'center' }}
              current={filter.page}
              pageSize={filter.limit}
              onChange={handleChangePage}
              total={total}
            />
          </Space>
        ) : (
          <Empty description="Chưa có giao dịch" />
        )}
      </Row>
    </Space>
  );
};

export default Fluctuations;
