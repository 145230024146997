import React, { useEffect, useState } from 'react';
import billApi from '../../../api/bill';
import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Modal,
  Pagination,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import Currency from '../../../components/Currency';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { Text } = Typography;

const Fund = () => {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    keyword: '',
    order_by: 'id',
    order_dir: 'DESC',
  });
  const [reload, setReload] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await billApi
        .getAll(filter)
        .then((res) => {
          if (res && Array.isArray(res.data)) {
            setTotal(res.total);
            setItems(res.data);
            setTotalAmount(res.total_amount);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    fetchData();
  }, [reload]);

  const onReload = () => {
    setReload((e) => !e);
  };

  const handleAdd = () => {
    history.push('/app/fund-create');
  };

  const handleEdit = (id) => {
    history.push('/app/fund-edit/' + id);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa?',
      onOk: async () => {
        await billApi
          .delete(id)
          .then(() => {
            onReload();
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  };

  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify={'end'} gutter={18}>
        <Col>
          <Button type="primary" onClick={handleAdd}>
            Thêm
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onReload}>
            Làm mới
          </Button>
        </Col>
      </Row>
      <Row>
        <Text style={{ fontSize: '20px' }} strong>
          Tổng tiền:{' '}
          <Currency
            style={
              totalAmount > 0
                ? { fontSize: '20px', color: 'green' }
                : { fontSize: '20px', color: 'red' }
            }
            value={totalAmount}
          />
        </Text>
      </Row>
      <Row>
        {loading ? (
          <Spin style={{ width: '100%' }} />
        ) : items?.length > 0 ? (
          <>
            {items.map((item, index) => {
              return (
                <Card key={index}>
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      <Text>Số tiền:</Text>
                    </Col>
                    <Col span={16}>
                      <Text strong>
                        {item?.transaction_type ? (
                          <Currency
                            style={{ color: 'green' }}
                            value={item?.amount}
                          />
                        ) : (
                          <Currency
                            style={{ color: 'red' }}
                            value={item?.amount}
                          />
                        )}
                      </Text>
                    </Col>
                    <Col span={8}>
                      <Text>Note:</Text>
                    </Col>
                    <Col span={16}>
                      <Text strong>{item?.note}</Text>
                    </Col>
                    <Col span={8}>
                      <Text>Ngày:</Text>
                    </Col>
                    <Col span={16}>
                      <Text strong>
                        {dayjs(item?.created_at).format('HH:mm DD-MM-YYYY')}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Space>
                        <Button
                          type="primary"
                          onClick={() => handleEdit(item?.id)}
                          size="small"
                        >
                          Sửa
                        </Button>
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            handleDelete(item?.id);
                          }}
                          size="small"
                        >
                          Xóa
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              );
            })}
            <Pagination
              style={{ display: 'flex', justifyContent: 'center' }}
              current={filter.page}
              pageSize={filter.limit}
              onChange={handleChangePage}
              total={total}
            />
          </>
        ) : (
          <Empty description="Không có dữ liệu" />
        )}
      </Row>
    </Space>
  );
};

export default Fund;
