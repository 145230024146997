import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Space, Table, Tag, Typography } from 'antd';
import PageTitle from '../../components/PageTitle';
import IntlMessage from '../../components/IntlMessage';
import { useSelector } from 'react-redux';
import driverApi from '../../api/driver';
import { fee, paymentType } from '../../utils/constant';
import dayjs from 'dayjs';
import { convertToAnyTimezone } from '../../helpers/time';
import Currency from '../../components/Currency';

const { Text } = Typography;

const BalanceStatistic = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);
  const [balance, setBalance] = useState(null);
  const [totalTopup, setTotalTopup] = useState(null);
  const [totalExtra, setTotalExtra] = useState(null);
  const [totalFee, setTotalFee] = useState(null);
  const user = useSelector((state) => state.auth.authUser);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 999,
  });

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const res = await driverApi.findAllPaid(user?.driver?.id, filter);
      if (res) {
        setItems(res.data);
        setTotal(res.total);
        setBalance(res.total_topup - res.total_fee - res.total_extra);
        setTotalExtra(res.total_extra);
        setTotalFee(res.total_fee);
        setTotalTopup(res.total_topup);
      }
      setLoading(false);
    }
    fetchData();
  }, [filter]);

  const columns = [
    {
      title: 'Mã lệnh',
      key: 'obj_id',
      render: (text, record, index) => {
        return record?.type === paymentType.order ? (
          record?.obj_id
        ) : Number(record.amount) > 0 ? (
          <Tag style={{ color: 'green' }}>Nạp</Tag>
        ) : (
          <Tag style={{ color: 'red' }}>Trừ</Tag>
        );
      },
    },
    // {
    //   title: 'Loại',
    //   key: 'type',
    //   render: (text, record, index) => {
    //     return record?.type === paymentType.order ? (
    //       <Tag color="red">-</Tag>
    //     ) : (
    //       <Tag color="green">+</Tag>
    //     );
    //   },
    // },
    {
      title: 'Ngày',
      key: 'time',
      render: (text, record, index) => {
        return record?.type === paymentType.order
          ? convertToAnyTimezone(record?.time).format('HH:mm DD/MM')
          : dayjs(record?.time).format('HH:mm DD/MM');
      },
    },
    {
      title: 'Tổng thu',
      key: 'total',
      render: (text, record, index) => {
        return (
          record?.type === paymentType.order && (
            <Space direction="vertical" size={1}>
              <Text>
                {new Intl.NumberFormat('vi-VN').format(record?.total)}
              </Text>
            </Space>
          )
        );
      },
    },
    // {
    //   title: 'Hoa hồng',
    //   key: 'commission',
    //   render: (text, record, index) => {
    //     return (
    //       record?.type === paymentType.order && (
    //         <Text style={{ color: 'green' }}>{record?.commission}</Text>
    //       )
    //     );
    //   },
    // },
    {
      title: `Phí trả`,
      key: 'fee',
      render: (text, record, index) => {
        return (
          record?.type === paymentType.order && (
            // <Text style={{ color: 'red' }}>{record?.fee}</Text>
            <Text style={{ color: 'red' }}>
              -{new Intl.NumberFormat('vi-VN').format(record?.fee)}
            </Text>
          )
        );
      },
    },
    {
      title: 'Tiền quỹ',
      key: 'extra',
      render: (text, record, index) => {
        return (
          record?.type === paymentType.order && (
            // <Text style={{ color: 'red' }}>{record?.extra}</Text>
            <Text style={{ color: 'red' }}>
              -{new Intl.NumberFormat('vi-VN').format(record?.extra)}
            </Text>
          )
        );
      },
    },
    {
      title: 'Trừ/nạp',
      key: 'amount',
      render: (text, record, index) => {
        return record?.type === paymentType.order ? (
          <Text style={{ color: 'red' }}>
            -{/* {record?.amount} */}
            {new Intl.NumberFormat('vi-VN').format(record?.amount)}
          </Text>
        ) : // <Text style={{ color: 'green' }}>+{record?.amount}</Text>
        Number(record.amount) > 0 ? (
          <Text style={{ color: 'green' }}>
            +{new Intl.NumberFormat('vi-VN').format(record?.amount)}
          </Text>
        ) : (
          <Text style={{ color: 'red' }}>
            {new Intl.NumberFormat('vi-VN').format(record?.amount)}
          </Text>
        );
      },
    },
  ];
  return (
    <Space direction="vertical">
      <Row>
        <Col>
          <PageTitle title={<IntlMessage id="global.balance_statistic" />} />
        </Col>
      </Row>
      <Row>
        <Card>
          <Space direction="vertical">
            <Space>
              <Text strong>Số dư:</Text>
              <Text>
                <Currency
                  style={{ color: balance > 0 ? 'green' : 'red' }}
                  value={balance}
                />{' '}
                = Tổng nạp - tổng(tiền cuốc *25% +10k)
              </Text>
            </Space>
            <Space>
              <Text strong>Tổng nạp:</Text>
              <Text>
                <Currency style={{ color: 'green' }} value={totalTopup} />
              </Text>
            </Space>
            <Space>
              <Text strong>Tổng phí trả về({fee.percent * 100}%):</Text>
              <Text>
                <Currency style={{ color: 'red' }} value={totalFee} />
              </Text>
            </Space>
            <Space>
              <Text strong>Tổng nộp quỹ({fee.extra / 1000} k):</Text>
              <Text>
                <Currency style={{ color: 'red' }} value={totalExtra} />
              </Text>
            </Space>
            <Space>
              <Text style={{ color: 'red' }}>
                (Tính từ khi tham gia hệ thống)
              </Text>
            </Space>
          </Space>
        </Card>
      </Row>
      <Row>
        <Table
          columns={columns}
          bordered={true}
          loading={loading}
          dataSource={items}
          scroll={{
            x: 'max-content',
          }}
          tableLayout="auto"
          size="small"
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50'],
            total: total,
            current: filter.page,
            pageSize: filter.limit,
          }}
        />
      </Row>
    </Space>
  );
};

export default BalanceStatistic;
