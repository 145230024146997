import React, { useEffect, useRef, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  InputNumber,
  Modal,
  Pagination,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import topupApi from '../../api/topup';
import * as dayjs from 'dayjs';
import Currency from '../../components/Currency';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useIntl } from 'react-intl';
import IntlMessage from '../../components/IntlMessage';

const { Text, Paragraph } = Typography;
const ModalTopup = (props) => {
  const { open = false, handleClose, currentData } = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const isMount = useRef(false);
  const [items, setItems] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [total, setTotal] = useState(0);
  const intl = useIntl();
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    keyword: '',
    order_by: 'id',
    order_dir: 'DESC',
  });
  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    isMount.current = true;
    async function fetchData() {
      const data = await topupApi.getAllForDriver(currentData?.id, filter);
      if (data) {
        setTotal(data.total);
      }
      if (Array.isArray(data.data)) {
        setItems(data.data);
        setDataDisplay(
          data?.data.map((item) => ({
            key: item.id,
            className: 'list_item',
            showArrow: false,
            label: (
              <Space direction="vertical">
                <Row>
                  <Text strong>
                    Số tiền:{' '}
                    <Currency
                      style={{ fontSize: '16px' }}
                      value={item?.amount}
                    />
                  </Text>
                </Row>

                <Row>
                  <Space>
                    <Text strong>Tên tài xế: {item?.driver?.lastname}</Text>

                    <Text strong> ~ sdt: {item?.driver?.mobile}</Text>
                  </Space>
                </Row>
                <Row>
                  <Space direction="vertical">
                    <Text style={{ display: 'block' }} strong>
                      Ngày :{dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
                    </Text>
                  </Space>
                </Row>
                <Row>
                  <Space>
                    <Text>Note:</Text>
                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: <IntlMessage id="global.more" />,
                      }}
                      style={{ margin: '0px' }}
                    >
                      {item?.note?.split('\n')?.map((note, i) => (
                        <React.Fragment key={i}>
                          {note}
                          <br />
                        </React.Fragment>
                      ))}
                    </Paragraph>
                  </Space>
                </Row>
              </Space>
            ),
            children: (
              <Row>
                <Space>
                  <Button
                    type="primary"
                    danger
                    size="small"
                    onClick={() => onEdit(item?.id)}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    type="primary"
                    danger
                    size="small"
                    onClick={() => onRemove(item?.id)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Space>
              </Row>
            ),
          }))
        );
      }
    }
    fetchData();
    return () => {
      isMount.current = false;
    };
  }, [filter, reload]);
  const handleChangePage = (page) => {
    setFilter((filter) => ({
      ...filter,
      page,
    }));
  };
  const onReload = () => {
    setReload((reload) => !reload);
  };
  const onRemove = async (id) => {
    Modal.confirm({
      title: 'Bạn có chắc chắn muốn xóa?',
      onOk: async () => {
        await topupApi
          .remove(id)
          .then(() => {
            message.success('Thành công!!');
            onReload();
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  };

  const onEdit = async (id) => {
    history.push('/app/topup-edit/' + id);
  };

  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (value) => {
        const data = { ...value };
        data.driver_id = currentData.id;
        setLoading(false);
        topupApi
          .create(data)
          .then(() => {
            setLoading(false);
            onReload();
            props.onReload();
            message.success({
              content: 'Khởi tạo giao dịch thành công!',
            });
            form.resetFields();
          })
          .catch((error) => {
            console.error(error);
            Modal.error({
              title: error.data.message,
              okText: intl.formatMessage({ id: 'global.close' }),
            });
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleChangeAmount = (value = 1) => {
    if (value && value > 0 && value < 10000) {
      const item = value.toString();
      setSuggestions([
        Number(item.padEnd(5, '0')),
        Number(item.padEnd(6, '0')),
        Number(item.padEnd(7, '0')),
      ]);
      return;
    }

    if (value && value > 10000) {
      setSuggestions([value * 10, value * 100, value * 1000]);
      return;
    }
  };

  const handleSetAmount = (item) => {
    if (item) {
      form.setFieldValue('amount', item);
      setSuggestions([]);
    }
  };

  if (!currentData) return null;
  return (
    <Modal
      title={`Lịch sử giao dịch của tài xế: ${currentData?.lastname}`}
      open={open}
      onOk={handleClose}
      onClose={handleClose}
      onCancel={handleClose}
    >
      <Form form={form} layout="inline">
        <Form.Item
          name="amount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            min={1}
            placeholder="VNĐ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            style={{ minWidth: 200 }}
            onChange={handleChangeAmount}
          />
        </Form.Item>
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          Nạp tiền
        </Button>
        {suggestions.length > 0 && (
          <Form.Item>
            <Space wrap>
              {suggestions.map((item) => {
                return (
                  <Button type="default" onClick={() => handleSetAmount(item)}>
                    {new Intl.NumberFormat('vi-VN').format(item)}
                  </Button>
                );
              })}
            </Space>
          </Form.Item>
        )}
      </Form>
      {dataDisplay.length > 0 ? (
        <div>
          <Collapse
            items={dataDisplay}
            accordion={true}
            // onChange={onChange}

            expandIconPosition="end"
            destroyInactivePanel={true}
            ghost={true}
          />
          <Pagination
            style={{ display: 'flex', justifyContent: 'center' }}
            current={filter.page}
            pageSize={filter.limit}
            onChange={handleChangePage}
            total={total}
          />
        </div>
      ) : (
        <>Chưa có giao dịch nào được thực hiện!</>
      )}
    </Modal>
  );
};

export default ModalTopup;
